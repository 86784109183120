// src/axiosInstance.js
import axios from "axios";
import Cookies from "js-cookie";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://91servercode-production.up.railway.app/", // Base URL for all requests
  timeout: 20000, // Timeout for requests
  withCredentials: true,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add common headers here, e.g., authentication tokens
    const token = Cookies.get("auth");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.error("Unauthorized! Please log in again.");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
