import { Box, Typography } from "@mui/material";
import FooterNav from "../FooterNav";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Grid } from "@mui/material";
import axiosInstance from "../../AxiosBaseApi";
import Models from "./Component/Models";

const commonStyles = {
  bgcolor: "background.paper",
  // borderColor: 'text.primary',
  m: 1,
  border: 5,
  width: "5rem",
  height: "5rem",
};

const Wallet = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    user: {},
    totalRecharge: 0,
    totalWithdraw: 0,
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleOpen();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .get("api/webapi/GetUserInfo")

        .then((res) => {
          if (res.status === 200) {
            setUserInfo({
              user: res.data.data,
              totalRecharge: res.data.totalRecharge,
              totalWithdraw: res.data.totalWithdraw,
            });
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#f2f2f1" }}>
        <Models handleClose={handleClose} open={open} />
        <Box
          sx={{
            display: "flex",
            padding: 2,
            height: "30vh",
            justifyContent: "center",
            alignItems: "center",

            flexDirection: "column",
            background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
          }}
        >
          <Typography
            color="#fff"
            style={{
              marginTop: "20px",
              fontSize: "22px",
              fontWeight: 600,
              lineHeight: "60px",
            }}
          >
            Wallet
          </Typography>

          <img
            width={40}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAMAAAC8EZcfAAAAaVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8G612AAAAInRSTlMAZkAmxmDucBAMv7J5Lh8Z1p9ZUUs5jn+qM8UG45b137yA+ifzJQAAAt1JREFUeNrt2oluozAQgOEZJ9iY+wgJ5Gzn/R9yl2alqNqEs/FMJX9P8MuDLYMAz/M8z/M818K0rlUKQoUm0tS7lRnIo/aaHqQlhgdN3+kaxAiDiJ4wIEM/2ucU8AsPBb2kQ+AVqogGfQKjx2hFLmF4+G/xJO0TFdE0MTB4jHbcDVwLTURzgFsq0kRiA9NDXyc18D5asYGq1ERiA7Ov0UoNvI9WbODXkSc28H4HFRtY96OVG2g0keDALCKSHBhoEh14IBIdeCDZgTXJDsxuwgNLkh2YkfDAUnhgRsIDA+mBkfRALTwwJeGBSnpgTc/oc7FdbPNEntsf28RFuzf4DrsmXx+oY4NvVCm7KlCX+HbKLg68xuhEtzBQJ+hIZZcEHg06E6TzA2N0qpsbGKNj3bzAIzq3mROoDToXhDMCE2RQnSYHxsiimxqokYmdGFgik920QI1s7KTAEtk0kwITZBNMCSyQUT4hsEVGzYTAPTLaTQhETgGM2iErC2MaZJWvCTSxppnOLc6TwZjN674zzbTg5rZZEXikRbauAhNayDgKvNBCFx94Z2ihxG+Sf4ymBc5ujpm79kwz6digm8Dlfnfgvt1+1+6DUExg8nGlJ25GSGB8pRcOEgKTgl6r+QMTTQMK/sAzDUq5A2MaFjAHJiQ88Cg8MCHhgRfpgR/SAwsf6AP9JhlWSg80V+GB+CE9MLkKD8SWhtXcgXgUfmEdKbzxX/mHL9WBhEDca3rhU8JrZ68s6Akt5cW9l1zi7TdtKejTx2/4eOQDfaAP9IGuZTAmR1Y5jLHIKoQxJ2QF4ypkVMM4hYwUgOxtvAGQ/RBaANG/9uzgL8knYQYgegkrANlLuAEQvYQ7mMoGyCCwMFmKDFJ4kPiTXgNfxD6GO5jnVKNT9Ql6YqesTjBfh86ksIit0Ikqh6U6B4lBByvYzZsTg+4EK+XqbY1Vk8OPsFnaqB/WpJkFz/M8z/O8tf4AbNgTpaPK6qwAAAAASUVORK5CYII="
            alt=""
          ></img>
          <Typography variant="h4" color="#fff" style={{ lineHeight: "25px" }}>
            {userInfo.user.money_user}.00
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: "12px" }} color="#fff">
              Total balance
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
              justifyContent: "space-around",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" color="#fff">
                {userInfo.totalWithdraw}.00
              </Typography>
              <Typography style={{ fontSize: "12px" }} color="#fff">
                Total withdrawal amount
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" color="#fff">
                {userInfo.totalRecharge}.00
              </Typography>
              <Typography style={{ fontSize: "12px" }} color="#fff">
                Total deposit amount
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            // position: "relative",
            // top: "-2.18667rem",
            display: "flex",
            flexWrap: "wrap",
            minHeight: 320,
            margin: 1.5,
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              padding: "10px",
              backgroundColor: "#fff",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                paddingBottom: "11px",
                justifyContent: "space-around",
              }}
            >
              <Box>
                <Box
                  sx={{
                    ...commonStyles,
                    borderRadius: "50%",
                    borderColor: "#ff9a8e",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  100%
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography style={{ fontSize: "14px" }} color="#000">
                    9999.00
                  </Typography>
                  <Typography style={{ fontSize: "14px" }} color="#000">
                    Main wallet
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    ...commonStyles,
                    borderColor: "#c6c6c0",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  10%
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography style={{ fontSize: "14px" }} color="#000">
                    0.00
                  </Typography>
                  <Typography style={{ fontSize: "14px" }} color="#000">
                    3rd party wallet
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Grid
              container
              fullWidth
              sx={{
                display: "flex",
                paddingTop: "15px",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Grid
                item
                xs={3}
                onClick={() => handleNavigation("/wallet/recharge")}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      height: "3.30667rem",
                      width: "3.30667rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      borderRadius: "1.4rem",
                      boxShadow: "0 .05333rem .21333rem #d0d0ed5c",
                    }}
                  >
                    <img
                      data-v-7d799898=""
                      width={35}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAjVBMVEUAAAD3eAD3dwD4dgD5dwD2dgD4dgD0bwD4eQD4dwD4dwD4dwD7dwD5dwD2dgD5dgD4dwDtcQD5dgD2eAD5dwD4dwD4dwD4dwD4dwD4eAD3eAD4dgD/dAD3dQDqbgD5dwD3dgD4dwD5eQD5dwD5dwD4dwD5dwD6dgD4dwD4dgD6eQD7dgD4eAD2dAD4dwAMGSgcAAAALnRSTlMAZsyZxU2mEkxrQN9yn3OMcg19OfPqv6+UWTImHxoI0YZULfiE2HlfuI9fQnM4FNyjywAAAtFJREFUaN7s1u9ymkAUBfDdPYBDKAERkdZ/WFF0THn/x2sTSy5JENiF64eMvxc4c+7smVnx8PDw8O1N7chJlEqcQP6airuZykTV4Oda3IVdxRLsJoKdVA32sf9b8HpWDbzyn20hGEnVJC7feP5KMLFVk235zn8SHNaJajIva7yCoXbQUpjE/or30PS0vhj5jS9Uo33ZwF/dvTBFMxeOyxu8grXwobztPGEsPC9beCuGwrQl3mRH52mR7cBrb/4ojS3VnYW5pR3ipFmYPAlDsxCArzS3RBLD2AyvFi1b4qi8DnFlaW+JqEj7gV1Q0d8SmQO23ksOUUn0C5M9AFej9CRDxTfZEnEA5BODXNMtUTAla+XCMtsSBVOyRu6wLVEw8o3oFoIYPi2C/0LRyUaNY7glmlPl0nlo1J0GFvZQ6fwYRCCmWyIW0PPYM9QtzLZEFiBL0SZDnTWwcIyacHDhQ9nXAehZ+QfG2xJdurvyBF+elnXynU+sHraHuLo02fS89Mlyghdp7JimKT649Lt0cHTlUMeg361BqOswL7XWZ3HDEpU0kmS81uuu787ZlSNy30vPRLMdrnJXjivC1U40c/GGzjx2ctH6qHPJIMer57bgQLIIuoJTV7Io0o7gSDIJ24MzySZqDXYlGzdtCc4ko+PfdukYx2EQCsLwmwI50ESsKyAgmWJX8v0PuE2aFOPYjqeJ+C7wa6TZCHsIeR6+Q+pOw7+Q+qFhDynPwh5inoT/ILaS8AKxhYQdXn1P+DbCI/w0wiM8wiO8OxxAqMMzxAIJd4jNJFwg1kl4gtiDhBvEJiMStIwJkLoZ0yE1GxMhVY1yEErGZQgF4xqEJiPIr8Wf1t+r2qYMkWDbWoJEivbGBIlib3UIZNshi7tcFne5mnChtdtuMeAyLtoR0eESqdhRMayfr612yiMsOG11vdl5rZYc3HJkfEouzCXaMAzDt/kHbN7wdhAVLO0AAAAASUVORK5CYII="
                      alt=""
                    />
                  </Box>
                  <Typography
                    mt={1}
                    variant="subtitle1"
                    style={{ color: "#9195a3", lineHeight: 1 }}
                  >
                    Deposit
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                onClick={() => handleNavigation("/wallet/withdrawal")}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      height: "3.30667rem",
                      width: "3.30667rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      borderRadius: "1.4rem",
                      boxShadow: "0 .05333rem .21333rem #d0d0ed5c",
                    }}
                  >
                    <img
                      data-v-7d799898=""
                      width={35}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAeFBMVEUAAAD/oKD/oKD/oaH/n5//n5//oKD/oaH/n5//n5//oKD/n5//oKD/n5/+i4v/n5/+iIj/n5//oaH/n5//n5//oaH/n5/5WVn9hYX6a2v7c3P////8i4v7eXn6YmL5XV3+lpb+j4/+9fX+6ur+m5v8trb7f3/6Zmaz6OagAAAAFnRSTlMA359fIBBvP++vjzC/f9/Pv49/YFBPchRNZwAAANhJREFUSMft1bkSgjAUheEEw467XoQQdvX931CLFBdDSFJYMMNfn2+YYQlkyz7/mnjaLqmyv0WwmMem+wBMUR/vWQTGzhgkYBG+RGYDdgjQtYCxEiXq5AVsCYyiySc9AGiqB20tdxgAxUDdqwBCHSjzebDTgCp3AfgCtSWQu6rnTzsgNwPn/OUC+i+orIB8ZsD5kFuBQo6EqP90W+HtCrrG7dXAAoPI1wJoixkQLH5AnShwhywOydoOgZ/urkclQyAEczHB7cEUZcRJUOUfxLwj1RYHPtly6gOS6nMolZZu1QAAAABJRU5ErkJggg=="
                      alt=""
                    />
                  </Box>
                  <Typography
                    mt={1}
                    variant="subtitle1"
                    style={{ color: "#9195a3", lineHeight: 1 }}
                  >
                    Withdraw
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                onClick={() => handleNavigation("/wallet/rechargerecord")}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      height: "3.30667rem",
                      width: "3.30667rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      borderRadius: "1.4rem",
                      boxShadow: "0 .05333rem .21333rem #d0d0ed5c",
                    }}
                  >
                    <img
                      data-v-7d799898=""
                      width={35}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
                      alt=""
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "min-content",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      mt={1}
                      variant="subtitle1"
                      style={{ color: "#9195a3", lineHeight: 1 }}
                    >
                      Deposit history
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                onClick={() => handleNavigation("/wallet/withdrawalrecord")}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      height: "3.30667rem",
                      width: "3.30667rem",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      alignItems: "center",
                      borderRadius: "1.4rem",
                      boxShadow: "0 .05333rem .21333rem #d0d0ed5c",
                    }}
                  >
                    <img
                      data-v-7d799898=""
                      width={35}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
                      alt=""
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "min-content",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      mt={1}
                      variant="subtitle1"
                      style={{ color: "#9195a3", lineHeight: 1 }}
                    >
                      Withdrawal history
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box>
      </div>
    </>
  );
};
export default Wallet;
