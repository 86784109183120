import { Box, Typography } from "@mui/material";
import FooterNav from "../FooterNav";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axiosInstance from "../../AxiosBaseApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const commonStyles = {
  bgcolor: "background.paper",
  // borderColor: 'text.primary',
  m: 1,
  border: 5,
  width: "5rem",
  height: "5rem",
};

const About = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    user: {},
    totalRecharge: 0,
    totalWithdraw: 0,
  });
  const [loginStatus, setLoginStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState("lottery");
  const [open, setOpen] = useState(false);

  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .get("api/webapi/GetUserInfo")

        .then((res) => {
          if (res.status === 200) {
            setUserInfo({
              user: res.data.data,
              totalRecharge: res.data.totalRecharge,
              totalWithdraw: res.data.totalWithdraw,
            });
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#f2f2f1" }}>
        {/* <Box  sx={{ fontFamily: `'Roboto', 'Inter', sans-serif`, background:"rgb(255, 142, 137)",  }}> */}
        <Box
          sx={{
            display: "flex",
            padding: 1.5,
            justifyContent: "space-between",
            alignItems: "center",
            background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
          }}
        >
          <Link to="/mian" style={{ textDecoration: "none" }}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                // width: "60px",
                color: "#fff",
                border: "1px solid #f2f3f5",
                borderRadius: 50,
              }}
            />
          </Link>
          <Typography variant="subtitle2" color="#fff">
            About Us
          </Typography>
          <Box> </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            // padding: 2,
            height: "20vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src="./images/about.png" width="100%" alt=""></img>
        </Box>

        <Box
          sx={{
            minHeight: 60,
            width: "100%",
            marginTop: "15px",
            borderRadius: "5px",
          }}
        >
          {/* Gifts Section */}
          <Box
            onClick={() => handleNavigation("/redenvelopes")}
            style={{
              display: "flex",
              borderBottom: "1px solid #ddd",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: "25px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAYFBMVEUAAAD6Wlr4WFj/YWH6WVn7Wlr5WFj5Wlr5WFj/WFj8Wlr5WVn6WVn5WFj4V1f/V1f5WVn5WFj7WVn7WVn+XFz7Xl75WVn/VVX5WFj4WFj7W1v+W1v5WVn7Wlr5Wlr5WVmJKV1iAAAAH3RSTlMAZsIMWT+2fX8RX29NH40m359ROSwmGQatmUYyz17vxXPQ3wAAAcRJREFUaN7t2m1vgjAQwPEe0AkUUB4ERF2//7ecJG6XLdJKrheMu//LhuTnRRFenJIkSXrXYn2FZq88dQVcdRzS3V9hTitnGubyLKAL97THRTmUi7LLRTmYi7LXRZnu+mV0Uaa7fhldlOmuS0Y3sNwDuGR0A8txAi4Z3UfyQICP8Di97GIdAdbgkL3XcMCg/Ze0BLgEh+xxoVSECofscYu1VpaOBxu0Q5SXfrayLKUetzxYpi6ZE0Y3eJHLzS1j2gFfLCHCyKVlLVuEG8tasQgby5pZhFPLWiqwwPf+A7zZfdxY1vTr/VeryDIWbfU8zpXaZOT6Nd+5VFlbjj5TdBdpE4V+rx4Nsq5iWGgXufqAhRKllMACCyywwAILLLDAAgss8NxpdzuoI2zkg80Hjndj5yo8qvhgqH7GNXDP1N8fxTDCcPo1Lg6NLhMM5jTW1d+zqq4rAzywP4EFFlhggQNUPAsPW8EqMDw9DScEhbSJMhEU0tJPGxbuCUtNpAa1zf3UEFZ8mH9b2DkkHK+Ah5wAkfbJOu6B+b/ls1rXkPC4/uKJyfV3JA9d9OtEpAuCmre9ItR3umiSdWLSTO0xU5IkSe/WF7W+N+28iEaIAAAAAElFTkSuQmCC"
                alt="Gifts"
              />
              <Typography>Confidentiality Agreement</Typography>
            </Box>
            <Box>
              <IconButton>
                <ArrowForwardIosIcon
                  style={{ color: "var(--Lottery4dTextColor-1)" }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            onClick={() => handleNavigation("/redenvelopes")}
            style={{
              display: "flex",
              borderBottom: "1px solid #ddd",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                width={25}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
                alt=""
              />
              <Typography>Risk Disclosure Agreement</Typography>
            </Box>
            <Box>
              <IconButton>
                <ArrowForwardIosIcon
                  style={{ color: "var(--Lottery4dTextColor-1)" }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box>
      </div>
    </>
  );
};

export default About;
