import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import axiosInstance from "../../../AxiosBaseApi";
import ResultModel from "../../Wallet/Component/ResultModel";
import { useDispatch, useSelector } from "react-redux";
import { toggleOpen } from "../../../redux/slices/resultOpen";

const HistoryCard = ({ data, loading, typeid, pageno, pageto, period }) => {
  const openresultPopup = useSelector((state) => state.resultOpen);
  const openalert = useSelector((state) => state.runMyBet);
  const [historyPage, setHistoryPage] = useState(false);

  const [myBetData, setMyBetData] = useState([]);
  const [myBetPage, setMyBetPage] = useState(false);

  // const [trendPage, setTrendPage] = useState(false);
  const [loading1, setLoading1] = useState(true);

  const dispatch = useDispatch();
  const handleClose = () => dispatch(toggleOpen());

  useEffect(() => {
    if (
      period - 1 == (myBetData.length > 1 && myBetData[0].stage) &&
      myBetData.length > 1 &&
      myBetData[0].status != 0
    ) {
      console.log("rrerere");
      handleClose();
    }
  }, [myBetData]);

  const handleClick = async () => {
    // const token = Cookies.get("token");
    setMyBetPage(true);
    setHistoryPage(false);
    // setTrendPage(false);
    try {
      const response = await axiosInstance
        .post("api/webapi/5d/GetMyEmerdList", {
          gameJoin: typeid,
          pageno: pageno,
          pageto: pageto,
        })

        .then((res) => {
          if (res.status === 200) {
            setMyBetData(res.data.data.gameslist);
            setLoading1(false);
          } else {
            alert(res.data.message);
            setLoading1(false);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    console.log("rergergergergreg", openalert.open);
    handleClick();
  }, [openalert.open]);

  const handleClickHistory = () => {
    setMyBetPage(false);
    setHistoryPage(true);
    // setTrendPage(false);
  };
  // const handleClickTrend = () => {
  //   setMyBetPage(false);
  //   setHistoryPage(false);
  //   setTrendPage(true);
  // };

  const sumOfResult = data.reduce((sum, game) => {
    return sum + Number(game.result);
  }, 0);

  return (
    <>
      <ResultModel
        handleClose={handleClose}
        open={openresultPopup}
        period={period}
        myBetData={myBetData}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          top: "-4rem",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
          justifyContent="center"
        >
          <Grid
            style={{
              width: "95%",
              background: "#fff",
              gap: 10,
              //   borderRadius: 15,
              minHeight: "300px",
              display: "flex",
              paddingTop: 15,
              flexDirection: "column",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={handleClickHistory}
                style={{
                  padding: "5px",
                  width: "120px",
                  textTransform: "capitalize",
                  border: "1px solid #3c5ef6",
                  color: "#000",
                  borderRadius: "10px",
                  boxShadow: ".02667rem .02667rem .13333rem .13333rem #eaeefc",
                }}
              >
                Game History
              </Button>
              <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={handleClick}
                style={{
                  padding: "5px",
                  width: "120px",
                  textTransform: "capitalize",
                  border: "1px solid #3c5ef6",
                  color: "#000",
                  borderRadius: "10px",
                  boxShadow: ".02667rem .02667rem .13333rem .13333rem #eaeefc",
                }}
              >
                My Bets
              </Button>
              {/* <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={handleClickTrend}
                style={{
                  padding: "5px",
                  width: "120px",
                  textTransform: "capitalize",
                  border: "1px solid #3c5ef6",
                  color: "#000",
                  borderRadius: "10px",
                  boxShadow: ".02667rem .02667rem .13333rem .13333rem #eaeefc",
                }}
              >
                Trend
              </Button> */}
            </Box>
            {/* {trendPage ? (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Trend
              </p>
            ) : ( */}
            {/* <> */}
            {myBetPage ? (
              <>
                {loading1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {myBetData.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell
                            align="center"
                            style={{ fontSize: "16px", display: "flex" }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {row.stage}
                                {period === row.stage ? (
                                  ""
                                ) : (
                                  <>
                                    {row.status === 2 ? (
                                      <>
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            color: "red",
                                            backgroundColor: "#f3f1f1",
                                            padding: ".10667rem 1.26667rem",
                                            marginLeft: ".26667rem",
                                            borderRadius: "1.53333rem",
                                          }}
                                        >
                                          Fail
                                        </Typography>{" "}
                                      </>
                                    ) : (
                                      <>
                                        {row.status === 1 ? (
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: "green",
                                              backgroundColor: "#f3f1f1",
                                              padding: ".10667rem 1.26667rem",
                                              marginLeft: ".26667rem",
                                              borderRadius: "1.53333rem",
                                            }}
                                          >
                                            Success
                                          </Typography>
                                        ) : null}{" "}
                                      </>
                                    )}{" "}
                                  </>
                                )}
                              </Box>
                              <Typography fontSize={10}>{row.today}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "14px", marginRight: "20px" }}
                          >
                            {row.status === 2 ? (
                              <>
                                <Typography
                                  variant="body2"
                                  style={{ color: "red" }}
                                >
                                  <span>-</span>
                                  {row.money}
                                </Typography>{" "}
                              </>
                            ) : (
                              <>
                                {row.status === 1 ? (
                                  <Typography style={{ color: "green" }}>
                                    <span
                                      style={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                      }}
                                    >
                                      +
                                    </span>
                                    {row.money}
                                  </Typography>
                                ) : (
                                  <>
                                    {" "}
                                    <Typography style={{ color: "#000" }}>
                                      {row.money}
                                    </Typography>
                                  </>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </>
            ) : (
              <>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Table sx={{ width: "100%" }}>
                    <TableHead sx={{ maxWidth: "100%" }}>
                      <TableRow sx={{ background: "#fbedf3" }}>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                          Draw
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                          Result
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: "14px" }}>
                          Sum
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            align="center"
                            style={{ fontSize: "14px" }}
                          >
                            {row.period}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: "10px" }}
                          >
                            {/* {row.result */}
                            <div style={{ display: "flex", gap: 1 }}>
                              {row.result.split("").map((digit, index) => (
                                <div
                                  key={index}
                                  style={{
                                    fontSize: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "1px solid #000",
                                    width: "11px",
                                    background: "#f4f4f4",
                                    color: "#000",
                                    height: "11px",
                                    lineHeight: 1,
                                    borderRadius: "25px",
                                  }}
                                >
                                  {digit}
                                </div>
                              ))}
                            </div>
                            {/* } */}
                          </TableCell>

                          <TableCell
                          // alignItems='center'
                          >
                            <Box
                              style={{
                                fontSize: "10px",
                                marginLeft: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid red",
                                width: "11px",
                                background: "#f64841",
                                color: "#fff",
                                textTransform: "capitalize",
                                height: "11px",
                                lineHeight: 1,
                                borderRadius: "25px",
                              }}
                            >
                              {row.result.split("").reduce((sum, digit) => {
                                return sum + Number(digit);
                              }, 0)}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </>
            )}
            {/* </>
            )} */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default HistoryCard;
