import HomePage from "./component/HomePage";
import LoginView from "./component/Login";
import { HashRouter, Route, Routes } from "react-router-dom";
import Wallet from "./component/Wallet/Wallet";
import My from "./component/Account/My";
import Promotion from "./component/Promotion/Promotion";
import Withdrawal from "./component/Wallet/withdrawal";
import Recharge from "./component/Wallet/Recharge";
import Rechargerecord from "./component/Wallet/Rechargerecord";
import Withdrawalrecord from "./component/Wallet/Withdrawalrecord";
import Myprofile from "./component/Wallet/Myprofile";
import Newtutorial from "./component/Wallet/Newtutorial";
import About from "./component/Wallet/About";
import KeFuMenu from "./component/Wallet/KeFuMenu";
import Redenvelopes from "./component/Wallet/Redenvelopes";
import ManualUpi from "./component/Wallet/Manualupi";
import { useEffect, useState } from "react";
import Loader from "./component/Loader";
import AddBankCard from "./component/Wallet/AddBankCard";
import Activity from "./component/Activity/Activity";
import DailyTasks from "./component/Activity/Component/DailyTasks";
import InvitationBonus from "./component/Activity/Component/InvitationBonus";
import Laundry from "./component/Activity/Component/Laundry";
import SuperJackpot from "./component/Activity/Component/SuperJackpot";
import DailySignIn from "./component/Activity/Component/DailySignIn";
import Win from "./component/Bet/Win/Win";
import Win3 from "./component/Bet/Win3/Win3";
import Win5 from "./component/Bet/Win5/Win5";
import Win10 from "./component/Bet/Win10/Win10";
import FiveD from "./component/Bet/FiveD/FiveD";
import K3 from "./component/Bet/K3/K3";
import FiveD10 from "./component/Bet/FiveD/FiveD10";
import FiveD5 from "./component/Bet/FiveD/FiveD5";
import FiveD3 from "./component/Bet/FiveD/FiveD3";
import Register from "./component/Register";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#f2f2f1" }}>
        {/* <Box  sx={{ fontFamily: `'Roboto', 'Inter', sans-serif`, background:"rgb(255, 142, 137)",  }}> */}

        {loading ? (
          <Loader /> // Show the loader when loading is true
        ) : (
          <HashRouter>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/home" element={<HomePage />} />
              <Route exact path="/login" element={<LoginView />} />
              <Route exact path="/register" element={<Register />} />
              
              <Route exact path="/mian" element={<My />} />
              <Route exact path="/win" element={<Win />} />
              <Route exact path="/win/3" element={<Win3 />} />
              <Route exact path="/win/5" element={<Win5 />} />
              <Route exact path="/win/10" element={<Win10 />} />
              <Route exact path="/5d" element={<FiveD />} />
              <Route exact path="/5d/3" element={<FiveD3 />} />
              <Route exact path="/5d/5" element={<FiveD5 />} />
              <Route exact path="/5d/10" element={<FiveD10 />} />
              <Route exact path="/k3" element={<K3 />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/activity" element={<Activity />} />
              <Route
                exact
                path="/activity/DailyTasks"
                element={<DailyTasks />}
              />
              <Route
                exact
                path="/activity/InvitationBonus"
                element={<InvitationBonus />}
              />
              <Route
                exact
                path="/activity/SuperJackpot"
                element={<SuperJackpot />}
              />
              <Route
                exact
                path="/activity/DailySignIn"
                element={<DailySignIn />}
              />
              <Route exact path="/main/Laundry" element={<Laundry />} />
              <Route exact path="/keFuMenu" element={<KeFuMenu />} />
              <Route exact path="/wallet" element={<Wallet />} />
              <Route exact path="/myprofile" element={<Myprofile />} />
              <Route
                exact
                path="/wallet/paynow/manual_upi"
                element={<ManualUpi />}
              />
              <Route exact path="/newtutorial" element={<Newtutorial />} />
              <Route exact path="/Promotion" element={<Promotion />} />
              <Route exact path="/Redenvelopes" element={<Redenvelopes />} />
              <Route exact path="/wallet/withdrawal" element={<Withdrawal />} />
              <Route
                exact
                path="/wallet/withdraw/addbankcard"
                element={<AddBankCard />}
              />

              <Route exact path="/wallet/recharge" element={<Recharge />} />
              <Route
                exact
                path="/wallet/rechargerecord"
                element={<Rechargerecord />}
              />
              <Route
                exact
                path="/wallet/withdrawalrecord"
                element={<Withdrawalrecord />}
              />
            </Routes>
          </HashRouter>
        )}
      </div>
    </>
  );
}

export default App;
