import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../AxiosBaseApi";
import Cookies from "js-cookie";

const Recordlist = (props) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
  
    const getUserData = async () => {
      try {
        const response = await axiosInstance.get(props.path)
  
          .then((res) => {
            if (res.status === 200) {
                setData(res.data.datas)
            } else {
              alert(res.data.message);
            }
          });
      } catch (error) {
        console.log("Error", error);
      }
    };
  
    useEffect(() => {
      getUserData();
    }, []);

  return (
    <>
      <Box style={{ backgroundColor: "#f7f7f7", padding: 13 }}>
        {(data.length > 0) && data.map((alldata, ind)=>{
            return(
       <Box
       key={ind}
          style={{
            padding: 15,
            backgroundColor: "#fff",
            borderRadius: ".5rem",
            marginBottom:"3px"
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 5,
              paddingBottom: 25,
              borderBottom: "1px solid #f2f3f5",
            }}
          >
            <Button
              size="small"
              style={{ background: props.bgcolor, color: "#fff", padding: 0, fontSize: '11px' }}
            >
              {props.text}
            </Button>
            {alldata.status === 0? <Typography variant="subtitle1" color="red">
              Pending
            </Typography> : <Typography variant="subtitle1" color="#34be8a">
              Success
            </Typography>}
            
          </Box>
          <Box style={{ margin: 5, marginTop: 15 }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" color="#888">
                Balance
              </Typography>{" "}
              <Typography variant="subtitle1" color="#888">
                {alldata.money}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" color="#888">
                Type
              </Typography>{" "}
              <Typography variant="subtitle1" color="#888">
              {alldata.type}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" color="#888">
                Time
              </Typography>{" "}
              <Typography variant="subtitle1" color="#888">
              {alldata.time}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" color="#888">
                Order number
              </Typography>{" "}
              <Typography variant="subtitle1" color="orange">
              {alldata.id_order}
              <img width={15} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAALVBMVEUAAACvsMetr8Wqr8KusMavsMeusMarr8OvsMavscelr8WusMausMasr8ausMb/yMBRAAAADnRSTlMAn2Ag77+QQK9/EN/PUG+JKIoAAABzSURBVCjPYyAOrBSEAjEDiMC5dzDgDOazv1aCAo1nYAGmh0ACwcIUKFJ8ClavawARYIWb+BIioOcItVPi3QKwgJwCWDuYNYwEAkUeoQq8exeAKiCkzoBmBgNWgbwmWCTsKwALmMDD9A1EIXMiLCJnERfxAKjSRuHlWJyVAAAAAElFTkSuQmCC" alt=""></img>
              </Typography>
            </Box>
          </Box>
        </Box>
            )
        })}
        
      </Box>
    </>
  );
};
export default Recordlist;
