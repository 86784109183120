import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "../../../style.css";
import axiosInstance from "../../../AxiosBaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { toggleOpen } from "../../../redux/slices/runmybetapi";
import { useDispatch } from "react-redux";

const MainBox = ({ seconds1, seconds2, typeid }) => {
  const [open, setOpen] = useState(false);
  const [drawerBackground, setDrawerBackground] = useState("red"); // Default background color
  const [text, setText] = useState("");
  const [activeButton, setActiveButton] = useState(0);
  const [buttonValue, setButtonValue] = useState(10);
  const [activeButton2, setActiveButton2] = useState(0);
  const [buttonValue2, setButtonValue2] = useState(1);
  const dispatch =useDispatch()
  const buttons2 = [
    { label: "X1", value: 1 },
    { label: "X5", value: 5 },
    { label: "X10", value: 10 },
    { label: "X20", value: 20 },
    { label: "X50", value: 50 },
    { label: "X100", value: 100 },
  ];

  const handleButtonClick22 = (index, value) => {
    setActiveButton2(index);
    setButtonValue2(value);
  };

  const buttons = [
    { label: "10", value: 10 },
    { label: "100", value: 100 },
    { label: "1000", value: 1000 },
    { label: "10000", value: 10000 },
  ];

  const handleButtonClick2 = (index, value) => {
    setActiveButton(index);
    setButtonValue(value);
  };

  const toggleDrawer = (state) => (event) => {
    setOpen(state);
  };

  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .post(
          "api/webapi/action/join",
          {
            typeid: typeid,
            join: text,
            x: buttonValue2,
            money: buttonValue,
          },
  
        )

        .then((res) => {
          if (res.status === 200) {
            dispatch(toggleOpen());
            toast(res.data.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: 0,
              theme: "colored",
              // transition: Bounce,
            });
            setTimeout(() => {
              setOpen(false);
              setButtonValue(10);
              setActiveButton(0);
            }, 2000);
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleButtonClick = (color, text) => {
    setDrawerBackground(color);
    setText(text);
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}> */}

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          top: "-4rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Drawer
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                height: "57%", // Set height to 50%
                width: { xs: "100%", sm: "400px", md: "400px" },
                left: { sm: "-9px", md: "-14px" },
                margin: "0 auto", // Center horizontally
                borderRadius: "25px 25px 0 0", // Optional: add some border radius to make it look better
              },
            }}
          >
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "30px",
                  paddingTop: ".8rem",
                  background: drawerBackground,
                }}
              >
                <Typography variant="body1" style={{ color: "#fff" }}>
                  1 Minute
                </Typography>
                <Box
                  style={{
                    background: "#fff",
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px ",
                    borderRadius: "10px",
                  }}
                >
                  {" "}
                  <Typography>Choose {text}</Typography>{" "}
                </Box>
                <Box
                  style={{
                    minHeight: "6vh",
                    display: "flex",
                    minWidth: "100%",
                  }}
                >
                  <Box
                    style={{
                      minHeight: "6vh",
                      minWidth: "50%",
                      background:
                        "linear-gradient(10deg, #fff 50%, transparent 0)",
                    }}
                  ></Box>
                  <Box
                    style={{
                      minHeight: "6vh",
                      minWidth: "50%",
                      background:
                        "linear-gradient(-10deg, #fff 50%, transparent 0)",
                    }}
                  ></Box>
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  margin: "10px",
                  justifyContent: "space-between",
                }}
              >
                <Typography align="center">Amount</Typography>

                <Box sx={{ display: "flex", gap: 1 }}>
                  {buttons.map((button, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      size="small"
                      onClick={() => handleButtonClick2(index, button.value)}
                      sx={{
                        padding: "1px 9px",
                        minWidth: 0,
                        borderRadius: "0px",
                        fontWeight: 500,
                        color: activeButton === index ? "#fff" : "#373535",
                        backgroundColor:
                          activeButton === index
                            ? drawerBackground
                            : "rgb(240, 240, 240)",
                        "&:hover": {
                          backgroundColor:
                            activeButton === index
                              ? drawerBackground
                              : "rgb(240, 240, 240)",
                        },
                      }}
                    >
                      {button.label}
                    </Button>
                  ))}
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  margin: "10px",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  display="flex"
                  align="center"
                  justifyContent="center"
                >
                  Quantity
                </Typography>
                <Box style={{ display: "flex", gap: 8 }}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "rgb(240, 240, 240)",
                      padding: "1px 12px",
                      minWidth: 0,
                      borderRadius: "0px",
                    }}
                  >
                    -
                  </Button>
                  <input type="number" style={{ width: "40px" }} />
                  <Button
                    variant="contained"
                    style={{
                      background: "rgb(240, 240, 240)",
                      padding: "1px 12px",
                      minWidth: 0,
                      borderRadius: "0px",
                    }}
                  >
                    +
                  </Button>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end", margin: "10px", }}>
                {buttons2.map((button, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    size="small"
                    onClick={() => handleButtonClick22(index, button.value)}
                    sx={{
                      padding: "1px 10px",
                      minWidth: 0,
                      borderRadius: "0px",
                      fontWeight: 500,
                      // color: "#373535",
                      color: activeButton2 === index ? "#fff" : "#373535",

                      backgroundColor:
                        activeButton2 === index
                          ? drawerBackground
                          : "rgb(240, 240, 240)",
                      "&:hover": {
                        backgroundColor:
                          activeButton2 === index
                            ? drawerBackground
                            : "rgb(240, 240, 240)",
                      },
                    }}
                  >
                    {button.label}
                  </Button>
                ))}
              </Box>
              <Box style={{ display: "flex", gap: 10, alignItems: "center", marginTop: "20px", }}>
                {" "}
                <Checkbox defaultChecked disabled />{" "}
                <Typography>Rule</Typography>
                <Typography style={{ color: "#f4453e" }}>
                  Pre-Selling
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  padding: "0 10px",
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  type="button"
                  onClick={handleCancel}
                  style={{
                    background: "#25253c",
                    color: "#fff",
                    width: "30%",
                    height: "50px",
                    padding: "0",
                    fontSize: "12px",
                    minWidth: "0",
                    // overflow: "hidden",
                    borderRadius: 0,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={getUserData}
                  style={{
                    background: drawerBackground,
                    color: "#fff",
                    width: "70%",
                    height: "50px",
                    padding: "0",
                    fontSize: "12px",
                    minWidth: "0",
                    // overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Total Amount {buttonValue2 * buttonValue}
                </Button>
                <ToastContainer />
              </Box>
            </Box>
          </Drawer>
        </div>

        <Grid
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
          justifyContent="center"
        >
          <Grid
            style={{
              width: "95%",
              background: "#fff",
              gap: 10,
              borderRadius: 15,
              minHeight: "290px",
              display: "flex",
              padding: 15,
              flexDirection: "column",
            }}
          >
            <Box style={{ display: "flex", gap: 5 }}>
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#5cba47",
                  padding: "8px 40px",
                  textTransform: "capitalize",
                  color: "#fff",
                  borderRadius: "0 1rem 0 1rem",
                  boxShadow: "0 0 .21333rem .02667rem rgba(92, 186, 71, .72)",
                }}
                type="button"
                onClick={() => handleButtonClick("#5cba47", "x")}
              >
                Green
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "violet",
                  padding: "8px 40px",
                  textTransform: "capitalize",
                  color: "#fff",
                  borderRadius: "10px",
                  boxShadow: "0 0 .21333rem .02667rem rgba(92, 186, 71, .72)",
                }}
                type="button"
                onClick={() => handleButtonClick("violet", "t")}
              >
                Violet
              </Button>
              <Button
                variant="contained"
                size="large"
                type="button"
                style={{
                  backgroundColor: "#fb4e4e",
                  padding: "8px 40px",
                  textTransform: "capitalize",
                  color: "#fff",
                  borderRadius: "0 1rem 0 1rem",
                  boxShadow: "0 0 .21333rem .02667rem rgba(92, 186, 71, .72)",
                }}
                onClick={() => handleButtonClick("#fb4e4e", "d")}
              >
                Red
              </Button>
            </Box>
            <Box
              style={{
                display: "flex",
                background: "#fbedf3",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 2,
                borderRadius: 15,
              }}
            >
              <Button
                type="button"
                onClick={() => handleButtonClick("#fb4e4e", "0")}
                style={{
                  backgroundImage: "url(./images/win/n0.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  width: "62px",
                  height: "62px",
                  padding: "0",
                  border: "none",
                }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
              <Button
                type="button"
                onClick={() => handleButtonClick("#5cba47", "1")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n1.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
              <Button
                type="button"
                onClick={() => handleButtonClick("#fb4e4e", "2")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n2.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
              <Button
                type="button"
                onClick={() => handleButtonClick("#5cba47", "3")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n3.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
              <Button
                type="button"
                onClick={() => handleButtonClick("#fb4e4e", "4")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n4.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
              <Button
                type="button"
                onClick={() => handleButtonClick("#5cba47", "5")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n5.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>

              <Button
                type="button"
                onClick={() => handleButtonClick("#fb4e4e", "6")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n6.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
              <Button
                type="button"
                onClick={() => handleButtonClick("#5cba47", "7")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n7.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
              <Button
                type="button"
                onClick={() => handleButtonClick("#fb4e4e", "8")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n8.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
              <Button
                type="button"
                onClick={() => handleButtonClick("#5cba47", "9")}
                className="numberButton"
                style={{ backgroundImage: "url(./images/win/n9.png)" }}
              >
                <span style={{ display: "none" }}>0</span>
              </Button>
            </Box>

            <Box sx={{ display: "flex", gap: 0.5 }}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  width: "60px",
                  marginRight: "10px",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#000",
                  border: "2px solid red",
                  borderRadius: 2,
                }}
              >
                Rendom
              </Button>
              <Button
                variant="outlined"
                style={{
                  width: "40px",
                  minWidth: "30px",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#000",
                  padding: "2px",
                  background: "rgba(92, 186, 71, 0.1)",
                  border: "1px solid green",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              >
                X1
              </Button>
              <Button
                variant="outlined"
                style={{
                  width: "40px",
                  minWidth: "30px",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#000",
                  padding: "2px",
                  background: "rgba(92, 186, 71, 0.1)",
                  border: "1px solid green",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              >
                X5
              </Button>
              <Button
                variant="outlined"
                style={{
                  width: "40px",
                  minWidth: "30px",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#000",
                  padding: "2px",
                  background: "rgba(92, 186, 71, 0.1)",
                  border: "1px solid green",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              >
                X10
              </Button>
              <Button
                variant="outlined"
                style={{
                  width: "40px",
                  minWidth: "30px",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#000",
                  padding: "2px",
                  background: "rgba(92, 186, 71, 0.1)",
                  border: "1px solid green",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              >
                X20
              </Button>
              <Button
                variant="outlined"
                style={{
                  width: "40px",
                  minWidth: "30px",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#000",
                  padding: "2px",
                  background: "rgba(92, 186, 71, 0.1)",
                  border: "1px solid green",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              >
                X50
              </Button>
              <Button
                variant="outlined"
                style={{
                  width: "40px",
                  minWidth: "30px",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#000",
                  padding: "2px",
                  background: "rgba(92, 186, 71, 0.1)",
                  border: "1px solid green",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              >
                X100
              </Button>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Button
                variant="contained"
                type="button"
                onClick={() => handleButtonClick("#ffc511", "Big")}
                sx={{
                  background: "#ffc511",
                  boxShadow: "0 0 .21333rem .02667rem rgba(255, 197, 17, .47)",
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  color: "#fff",
                  width: "50%",
                  "&:hover": {
                    background: "#ffc511", // Keep the same background on hover
                    boxShadow:
                      "0 0 .21333rem .02667rem rgba(255, 197, 17, .47)", // Keep the same box-shadow
                  },
                }}
              >
                Big
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={() => handleButtonClick("#5cba47", "Small")}
                sx={{
                  background: "#5cba47",
                  boxShadow: "0 0 .21333rem .02667rem rgba(92, 186, 71, .72)",
                  borderEndEndRadius: 20,
                  borderTopRightRadius: 20,
                  color: "#fff",
                  width: "50%",
                  "&:hover": {
                    background: "#5cba47", // Keep the same background on hover
                    boxShadow: "0 0 .21333rem .02667rem rgba(92, 186, 71, .72)", // Keep the same box-shadow
                  },
                }}
              >
                Small
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* </div> */}
    </>
  );
};
export default MainBox;
