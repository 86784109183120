import { Box, Button, Typography } from "@mui/material";
import FooterNav from "./FooterNav";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Marquee from "react-fast-marquee";
import SliderComponent from "./SliderComponent";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LuckyWinners from "./LuckyWinner";
import Cookies from "js-cookie";
import { unsetCookie } from "./Login";
import Models from "./Wallet/Component/Models";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../redux/slices/userInfo";
import { setOpen, toggleOpen } from "../redux/slices/alertOpen";
// import jwt from 'jsonwebtoken';

const HomePage = () => {
  const openalert = useSelector((state) => state.openAlert);
  const history = useNavigate();
  const [selectedTab, setSelectedTab] = useState("lottery");
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(setOpen(true)); // Sets state to true
  };
  const handleClose = () => dispatch(toggleOpen());
  const token = Cookies.get("auth");

  useEffect(() => {
    if (!token) {
      history("/login");
      return;
    }
    dispatch(fetchUserData({ extra: { history } }));
    handleOpen();
  }, []);

  const handleTabClick = (tabId) => {
    setSelectedTab(tabId);
  };

  const logOut = () => {
    unsetCookie();
  };

  return (
    <>
      <div>
        <Models handleClose={handleClose} open={openalert} />
        <Box sx={{ marginBottom: "5px" }}>
          <AppBar
            position="static"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2px 10px",
              height: "3.22667rem",
              boxShadow: "unset",
              backgroundColor: "#fff",
            }}
          >
            <img
              src="https://ossimg.91admin123admin.com/91club/other/h5setting_20230714005937kuk1.png"
              width="100px"
              alt=""
            />
            {/* <Box bgcolor="red"> */}

            <Box style={{ display: "flex" }}>
              <Link to="/login">
                <Box
                  style={{ borderColor: "#fff", color: "#f74747" }}
                  onClick={logOut}
                >
                  <MailOutlineIcon />
                </Box>
              </Link>
              <Link to="/login">
                <Box
                  style={{ borderColor: "#fff", color: "#f74747" }}
                  onClick={logOut}
                >
                  <FileDownloadIcon />
                </Box>
              </Link>
            </Box>
          </AppBar>
        </Box>
        {/*Slider   */}
        <SliderComponent />

        <Box
          margin="10px"
          display="flex"
          alignItems="center"
          p="6px"
          border="1px solid #ddd"
          borderRadius="32px"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_589_37747)">
              <path
                d="M15.9993 4V28C11.3327 28 7.86502 21.8927 7.86502 21.8927H3.99935C3.26297 21.8927 2.66602 21.2958 2.66602 20.5594V11.3405C2.66602 10.6041 3.26297 10.0072 3.99935 10.0072H7.86502C7.86502 10.0072 11.3327 4 15.9993 4Z"
                fill="url(#paint0_linear_589_37747)"
              />
              <path
                d="M21.334 10C21.7495 10.371 22.1261 10.7865 22.4567 11.2392C23.4265 12.5669 24.0007 14.2149 24.0007 16C24.0007 17.7697 23.4363 19.4045 22.4819 20.7262C22.1452 21.1923 21.7601 21.6195 21.334 22"
                stroke="url(#paint1_linear_589_37747)"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.8242 27.4571C26.7227 25.1302 29.3336 20.87 29.3336 16C29.3336 11.2057 26.8031 7.00234 23.005 4.65271"
                stroke="url(#paint2_linear_589_37747)"
                strokeWidth="1.8"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_589_37747"
                x1="9.33268"
                y1="4"
                x2="9.33268"
                y2="28"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF7C7C" />
                <stop offset="0.74876" stopColor="#F54545" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_589_37747"
                x1="22.6673"
                y1="10"
                x2="22.6673"
                y2="22"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF7C7C" />
                <stop offset="0.74876" stopColor="#F54545" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_589_37747"
                x1="26.0789"
                y1="4.65271"
                x2="26.0789"
                y2="27.4571"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF7C7C" />
                <stop offset="0.74876" stopColor="#F54545" />
              </linearGradient>
              <clipPath id="clip0_589_37747">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <Box ml={2} flex={1}>
            <Marquee speed={15}>
              <Typography variant="body1" fontWeight="500">
                We apologize there is no telegram contact for our customer
                service. If you need assistance, please go to the website and
                press the customer service menu to get the contact. For your
                balance and data account security, please beware and careful of
                anyone claiming to be 91club customer service. Thank you.
              </Typography>
            </Marquee>
          </Box>
          <Button
            variant="contained"
            sx={{
              ml: 2,

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexShrink: "0",
              gap: ".06667rem",
              width: "2rem",
              height: "1.66667rem",
              marginLeft: "auto",
              color: "#fff",
              border: "none",
              borderRadius: ".53333rem",
              background:
                "linear-gradient(360deg, #f54545 20.19%, #fe6868 100%)",
              boxShadow:
                "0 .02667rem .08rem #d0d0edbf, inset 0 .05333rem .05333rem #ff5454",
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_589_37755)">
                <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
                <path
                  d="M12 22C16.1173 22 19.4999 18.7371 19.4999 14.5491C19.4999 13.5209 19.4476 12.4187 18.8778 10.7058C18.3079 8.9929 18.1931 8.7718 17.5905 7.71395C17.333 9.8727 15.9555 10.7724 15.6055 11.0413C15.6055 10.7615 14.7722 7.66795 13.5088 5.81695C12.2685 4 10.5817 2.80796 9.59265 2C9.59265 3.53489 9.16095 5.81695 8.5427 6.9797C7.92445 8.14245 7.80835 8.1848 7.0361 9.0501C6.2639 9.9154 5.90945 10.1826 5.2637 11.2325C4.61798 12.2825 4.5 13.6809 4.5 14.7091C4.5 18.8971 7.88265 22 12 22Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_589_37755">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Detail
          </Button>
        </Box>

        <div style={{ display: "flex", margin: "10px" }}>
          <div
            role="tablist"
            style={{
              maxHeight: "502px",
              overflowY: "auto",
              width: "26%",
              scrollbarWidth: "none",
            }}
          >
            {[
              {
                id: "lottery",
                name: "Lottery",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010214yxxq.png",
              },
              {
                id: "flash",
                name: "Mini games",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010207qmfa.png",
              },
              {
                id: "popular",
                name: "Popular",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010159ynyu.png",
              },
              {
                id: "slot",
                name: "Slots",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010153317n.png",
              },
              {
                id: "fish",
                name: "Fishing",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010147x2cr.png",
              },
              {
                id: "video",
                name: "Casino",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010141v76m.png",
              },
              {
                id: "sport",
                name: "Sports",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010123ddo4.png",
              },
            ].map((tab) => (
              <div
                key={tab.id}
                role="tab"
                tabIndex="0"
                aria-selected={selectedTab === tab.id}
                onClick={() => handleTabClick(tab.id)}
              >
                <div
                  style={{
                    width: "72px",
                    height: "100%",
                    padding: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundSize: "100% 100%",
                    marginBottom: "1.17333rem",
                    backgroundImage: `url(https://91club.bet/assets/png/bgActive-805fae0e.png)`,
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundSize: "100% 100%",
                      backgroundImage: `url(${tab.imgSrc})`,
                    }}
                  />
                  <Typography style={{ fontSize: "14px", color: "#fff" }}>
                    {tab.name}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: "17px",
              marginRight: "4px",
            }}
          >
            {/* <div className=""> */}
            {[
              {
                href: "/win",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_202307140102511fow.png",
                title: "Win Go",
                description1: "Guess Number",
                description2: "Green/Red/Purple to win",
              },
              {
                href: "/#",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_20230714010246lyuc.png",
                title: "Trx Win",
                description1: "Guess Number",
                description2: "Green/Red/Purple to win",
              },
              {
                href: "/k3",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_20230714010227swu2.png",
                title: "K3",
                description1: "Guess Number",
                description2: "Big/Small/Odd/Even",
              },
              {
                href: "/5d",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_2023071401023322dy.png",
                title: "5D",
                description1: "Guess Number",
                description2: "Big/Small/Odd/Even",
              },
            ].map((game, index) => (
              <Box
                key={index}
                style={{
                  height: "72px",
                  padding: "12px",
                  display: "flex",
                  borderRadius: "1.53333rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  backgroundSize: "100% 100%",
                  marginBottom: "1.17333rem",
                  background:
                    "linear-gradient(125.43deg, #ff8e89 12.38%, #ffc3a2 87.13%)",
                  boxShadow:
                    "0 -.05333rem .13333rem #fff6f4 inset, 0 .10667rem .21333rem .05333rem #d0d0ed5c",
                  textDecoration: "none",
                }}
                onClick={() => history(game.href)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <div>
                    <Typography style={{ fontSize: "16px", color: "#fff" }}>
                      {game.title}
                    </Typography>

                    <Typography style={{ fontSize: "12px", color: "#fff" }}>
                      {game.description2}
                    </Typography>
                  </div>
                  <Typography style={{ fontSize: "14px", color: "#fff" }}>
                    {game.description1}
                  </Typography>
                </div>
                <img
                  style={{ width: "114px", height: "107%" }}
                  src={game.imgSrc}
                  alt={game.title}
                />
              </Box>
            ))}
            <Button
              variant="outlined"
              style={{
                width: "100%",
                borderRadius: "70px",
              }}
              onClick={() => (window.location.href = "/#/")}
            >
              <img
                style={{ width: "37px", marginRight: "8px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAIVBMVEUAAAD5Wlr3WFj6WVn6WFj5V1f6WVn5WVn5WVn4V1f5WVnpXZn/AAAACnRSTlMA72C/v1DP329wWe0twgAAAKNJREFUOMtjoA/gMFwFAkkgdrMUiLlMASyhtQoCGhgYmKDMFWCJLCivgIGBDcpcDpZYBQULGBi4YOwRLlEF5SQwMLBDmcvAErOgPGBYc0KZi8ASTI4g9kIjEFsFHB/CCgyDF7AaIpwbjHAuIvkEIJLPUrCEF5RnwMDAAmUuBktIQXkCDAyMUObCgY9BGkvg9Dn+sIpCJB9WbMnHFCn5BDDQBQAAbjlnhYlIhpEAAAAASUVORK5CYII="
                alt="View All"
              />
              <Typography variant="subtitle1">View All</Typography>
            </Button>
          </div>
        </div>
        <LuckyWinners />
        <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box>
      </div>
    </>
  );
};
export default HomePage;
