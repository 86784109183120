import { AppBar, Box, Button, Typography } from "@mui/material";
import Cookies from "js-cookie";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../../AxiosBaseApi";
import Period from "../Win/Period";
import MainBox from "../Win/MainBox";
import Card from "./Card";
import MainBox5d from "./MainBox5d";
import HistoryCard from "./HistoryCard";

const FiveD10 = () => {
  const navigate = useNavigate();
  const [typeid, setTypeid] = useState(10);
  const [pageno, setPageno] = useState(1);
  const [pageto, setPageto] = useState(10);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState(20222125156);
  const [showResultNow, setShowResultNow] = useState();
  const [seconds1, setSeconds1] = useState(0);
  const [seconds2, setSeconds2] = useState(0);
  const [minute, setMinute] = useState(0);
  const [clicked, setClicked] = useState(false); // Assuming there's some way to toggle 'clicked'
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (minute === 0 && seconds1 === 0 && seconds2 === 5) {
      setIsDisabled(true);
    }
    if (minute === 9 && seconds1 === 5 && seconds2 === 9) {
      setIsDisabled(false);
    }
  }, [seconds2]);
  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .post("api/webapi/5d/GetNoaverageEmerdList", {
          gameJoin: typeid,
          pageno: pageno,
          pageto: pageto,
        })

        .then((res) => {
          if (res.status === 200) {
            setData(res.data.data.gameslist);
            setShowResultNow(res.data.data.gameslist[0].result);
            setPeriod(res.data.period);
            setLoading(false);
          } else {
            alert(res.data.message);
            setLoading(false);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (seconds1 === 5) {
      getUserData();
    }
  }, [seconds1]);

  useEffect(() => {
    const countDownDate = new Date(
      "2030-07-16T23:59:59.9999999+01:00"
    ).getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const calculatedMinute = Math.ceil(minutes % 10);
      const calculatedSeconds1 = Math.floor((distance % (1000 * 60)) / 10000);
      const calculatedSeconds2 = Math.floor(
        ((distance % (1000 * 60)) / 1000) % 10
      );

      // Update React state
      setMinute(calculatedMinute);
      setSeconds1(calculatedSeconds1);
      setSeconds2(calculatedSeconds2);
    }, 100);

    // Audio logic and UI adjustments
    const audioCheckInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const second1Value = Math.floor((distance % (1000 * 60)) / 10000);
      const second2Value = Math.floor(((distance % (1000 * 60)) / 1000) % 10);

      // Replace playAudio1() and playAudio2() with your React audio functions
      if (second1Value === 0 && second2Value <= 5 && clicked) {
        playAudio1();
      }
      if (second1Value === 5 && second2Value === 9 && clicked) {
        playAudio2();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
      clearInterval(audioCheckInterval);
    };
  }, [clicked]);

  // Assuming playAudio1 and playAudio2 are audio functions
  const playAudio1 = () => {
    console.log("Playing audio 1");
  };

  const playAudio2 = () => {
    console.log("Playing audio 2");
  };
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#efeef4" }}>
        {/* Header */}
        <Box>
          <AppBar
            position="static"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2px 10px",
              height: "3.22667rem",
              boxShadow: "unset",
              backgroundImage: "linear-gradient(90deg, #cd0103, #f64841)",
            }}
          >
            <Link to="/home">
              <Box style={{ textDecoration: "none" }}>
                <ChevronLeftIcon
                  fontSize="medium"
                  style={{
                    // width: "60px",
                    color: "#fff",
                    border: "2px solid #fff",
                    borderRadius: 50,
                  }}
                />
              </Box>
            </Link>
            <img src="./images/h5setting.png" width="100px" alt="" />
            <Box style={{ display: "flex", gap: 4 }}>
              {/* <Link to="/login"> */}
              <Box>
                <img src="./images/audio.webp" width="30px" alt="" />
              </Box>
              {/* </Link> */}
              {/* <Link to="/login"> */}
              <Box>
                <img src="./images/volume.webp" width="30px" alt="" />
              </Box>
              {/* </Link> */}
            </Box>
          </AppBar>
        </Box>

        <Card link1={"/5d"} link2={"/5d/3"} link3={"/5d/5"} link4={"/5d/10"} />
        {/* <Period
          period={period}
          seconds1={seconds1}
          seconds2={seconds2}
          minutes={minute}
        /> */}
        <Box style={{ position: "relative" }}>
          <MainBox5d
            showResultNow={showResultNow}
            minutes={minute}
            seconds1={seconds1}
            seconds2={seconds2}
            typeid={typeid}
            period={period}
          />
          {isDisabled && (
            <Box
              sx={{
                position: "absolute",
                top: -65,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)", // Transparent overlay
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                gap: 5,
                // fontSize: '18px',
                zIndex: 1, // Ensure it overlays the content
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  background: "#f95959",
                  color: "#fff",
                  borderRadius: "20px",
                  fontWeight: 700,
                  fontSize: "158px",
                  textAlign: "center",
                  lineHeight: 0,
                  padding: "90px 20px",
                }}
              >
                0
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  background: "#f95959",
                  color: "#fff",
                  fontWeight: 700,
                  borderRadius: "20px",
                  fontSize: "158px",
                  textAlign: "center",
                  lineHeight: 0,
                  padding: "90px 20px",
                }}
              >
                {seconds2}
              </Typography>
            </Box>
          )}
        </Box>
        <HistoryCard
          data={data}
          loading={loading}
          typeid={typeid}
          pageno={pageno}
          pageto={pageto}
        />
      </div>
    </>
  );
};

export default FiveD10;
