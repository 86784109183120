import { Avatar, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Cookies from "js-cookie";
import axiosInstance from "../../AxiosBaseApi";

const Myprofile = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    user: {},
    totalRecharge: 0,
    totalWithdraw: 0,
  });

  const getUserData = async (token) => {
    try {
      const response = await axiosInstance
        .get("api/webapi/GetUserInfo", {
          headers: {
            Authorization: token, // Optional, in case you need to pass an auth token
          },
          withCredentials: true,
        })

        .then((res) => {
          if (res.status === 200) {
            setUserInfo({
              user: res.data.data,
              totalRecharge: res.data.totalRecharge,
              totalWithdraw: res.data.totalWithdraw,
            });
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    const token = Cookies.get("auth");
    getUserData(token);
  }, []);
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#f2f2f1" }}>
        <Box
          sx={{
            display: "flex",
            padding: 1,
            justifyContent: "space-between",
            alignItems: "center",

            background: "linear-gradient(90deg, #cd0103, #f64841)",
          }}
        >
          <Box onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                // width: "60px",
                color: "#fff",
                border: "1px solid #f2f3f5",
                borderRadius: 50,
              }}
            />
          </Box>
          <Typography variant="subtitle2" color="#fff">
            Profile
          </Typography>
          <Box> </Box>
        </Box>

        {/* <Box style={{ backgroundColor: "#f7f7f7", padding: 13 }}> */}

        <Box
          style={{
            padding: 15,
            backgroundColor: "#fff",
            borderRadius: ".5rem",
            marginBottom: "3px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
              borderBottom: "1px solid #f2f3f5",
            }}
          >
            <Typography variant="subtitle1">Avatar</Typography>
            <Avatar alt="Remy Sharp" src="./images/myimg.png" />
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 5px",
              borderBottom: "1px solid #f2f3f5",
            }}
          >
            <Typography variant="subtitle1">ID</Typography>
            <Typography variant="subtitle1">{userInfo.user.id_user}</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 5px",
              borderBottom: "1px solid #f2f3f5",
            }}
          >
            <Typography variant="subtitle1">Username</Typography>
            <Typography variant="subtitle1">
              {userInfo.user.name_user}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 5px",
              borderBottom: "1px solid #f2f3f5",
            }}
          >
            <Typography variant="subtitle1">Mobile Number</Typography>
            <Typography variant="subtitle1">
              {userInfo.user.phone_user}
            </Typography>
          </Box>
        </Box>
        {/* )
        })} */}

        {/* </Box> */}

        {/* <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box> */}
      </div>
    </>
  );
};

export default Myprofile;
