import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import axiosInstance from "../../AxiosBaseApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const commonStyles = {
  bgcolor: "background.paper",
  m: 1,
  border: 5,
  width: "5rem",
  height: "5rem",
};

const Redenvelopes = () => {
  const navigate = useNavigate();
  const [data, setData] = useState("");


  const handleChange = (e) => {
    setData(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance
        .post(
          "api/webapi/use/redenvelope",
          { code: data },
        
        )

        .then((res) => {
          if (res.status === 200) {
            alert(res.data.message);
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#f2f2f1" }}>
        <Box
          style={{
            height: "8vh",
            background: "#1989fa",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box onClick={() => navigate(-1)} style={{textDecoration: 'none'}}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                color: "#fff",
              }}
            />
          </Box>
          <Typography style={{ fontSize: "20px" }} color="#fff">
            Gift
          </Typography>
          <Box></Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "30vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background: "#1989fa",
          }}
        >
          <img width={350} alt="" src="./images/gift.png" />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            minHeight: 290,
            margin: 1.5,
            borderRadius: "10px",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              padding: "18px",
              backgroundColor: "#66b3ff",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <Typography style={{ fontSize: "16px" }} color="#fff">
              Hi
            </Typography>
            <Typography style={{ fontSize: "16px", marginTop: 5 }} color="#fff">
              We have a gift for you
            </Typography>
            <Typography
              style={{ fontSize: "16px", marginTop: 30 }}
              color="#fff"
            >
              Please enter the gift code below
            </Typography>

            <div style={{ margin: "10px" }}>
              <TextField
                fullWidth
                sx={{
                  //   width: "280px",
                  backgroundColor: "#fff",
                  borderRadius: "30px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Removes the border
                    },
                  },
                }}
                name="code"
                id="code"
                onChange={handleChange}
                size="small"
                placeholder="Please enter gift code"
              />
            </div>
            <div style={{ margin: "10px" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  color: "#0029ff",
                  borderRadius: "40px",
                  backgroundColor: "#00c5f1",
                }}
              >
                Recieve
              </Button>
            </div>
          </Box>
        </Box>

        {/* <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box> */}
      </div>
    </>
  );
};

export default Redenvelopes;
