import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: true,
};

const openSlice = createSlice({
  name: "openState",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    toggleOpen: (state) => {
      state.open = !state.open;
    },
  },
});

export const { setOpen, toggleOpen } = openSlice.actions;

export default openSlice.reducer;
