import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../AxiosBaseApi";

export const fetchUserData = createAsyncThunk(
  "fetchUserData",
  async ({ rejectWithValue, getState, extra }) => {
    const { navigate } = extra; // Destructure navigate from extra arguments
    try {
      const response = await axiosInstance.get("api/webapi/GetUserInfo");

      if (response.status === 200) {
        return {
          user: response.data.data,
          totalRecharge: response.data.totalRecharge,
          totalWithdraw: response.data.totalWithdraw,
        };
      } else if (response.status === 401) {
        navigate("/login"); // Redirect to login on 401
        return rejectWithValue("Unauthorized. Redirecting to login...");
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login"); // Redirect to login on catch block 401
        return rejectWithValue("Unauthorized. Redirecting to login...");
      }
      return rejectWithValue(error.message);
    }
  }
);

// export const fetchUserData = createAsyncThunk("fetchUserData", async ( thunkAPI) => {
//     try {
//       const response = await axiosInstance.get("api/webapi/GetUserInfo",

//     );

//       if (response.status === 200) {
//         return {
//           user: response.data.data,
//           totalRecharge: response.data.totalRecharge,
//           totalWithdraw: response.data.totalWithdraw,
//         };
//       } else {
//         return thunkAPI.rejectWithValue(response.data.message);
//       }
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

const initialState = {
  user: null,
  totalRecharge: 0,
  totalWithdraw: 0,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.totalRecharge = action.payload.totalRecharge;
        state.totalWithdraw = action.payload.totalWithdraw;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
