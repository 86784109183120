import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../AxiosBaseApi";

const useStyles = makeStyles({
  formContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  phoneInputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputField: {
    marginBottom: "20px",
  },
  icon: {
    marginRight: "8px",
  },
  actionButtons: {
    display: "flex",
    gap: "10px",
  },
  footer: {
    marginTop: "20px",
    textAlign: "center",
  },
});
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function unsetCookie() {
  setCookie("token", "", 0);
  setCookie("auth", "", 0);
}
const LoginForm = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountryCode] = useState("+91"); // Default country code
  const history = useNavigate();

  const [data, setData] = useState({
    username: null,
    password: null,
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const mamberID = localStorage.getItem("mamberId");
    if (mamberID) {
      history("/");
    }
  }, []);
  const handleNavigation = (path) => {
    history(path);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const datas = new FormData();
    datas.append("username", data.username);
    datas.append("pwd", data.password);

    try {
      const response = await axiosInstance
        .post("api/webapi/login", {
          username: data.username,
          pwd: data.password,
        })

        .then((res) => {
          if (res.status === 200 && res.data.status === true) {
            // alert(res.data.message);

            setCookie("token", res.data.token, 1);
            setCookie("auth", res.data.value, 1);
            setTimeout(() => {
              history("/home");
            }, 500);
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#f2f2f1" }}>
        <Box
          style={{
            height: "8vh",
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
            alignItems: "center",
            padding: "0 14px",
          }}
        >
          {/* <IconButton> */}
          <ChevronLeftIcon
            fontSize="medium"
            style={{
              // width: "60px",
              color: "#fff",
            }}
          />
          {/* </IconButton> */}
          <img
            width={100}
            src="https://ossimg.91admin123admin.com/91club/other/h5setting_20230714005938hfia.png"
            alt=""
          ></img>
          <Typography variant="subtitle2" style={{ color: "#fff" }}>
            EN
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            padding: 2,
            gap: 1,
            height: "14vh",
            flexDirection: "column",
            background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
          }}
        >
          <Typography
            color="#fff"
            style={{
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Login in
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: "12px", lineHeight: "10px" }}
              color="#fff"
            >
              Please log in with your phone number or email
            </Typography>
            <Typography style={{ fontSize: "12px" }} color="#fff">
              If you forget your password, please contact customer service
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: 10,
            // marginBottom: 40,
            display: "flex",
            borderBottom: "2px solid #f95959",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <AppShortcutIcon fontSize="large" style={{ color: "#f95959" }} />
          <Typography style={{ color: "#f95959" }}>
            Log in with phone
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit}>
          <div className={classes.formContainer}>
            {/* Phone Input */}

            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <img
                    className={classes.icon}
                    width={30}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAM1BMVEUAAAD/cXH/cXH/cXL/cHL/cHL/cHD/cXH/cHL/cXH/cXH/c3P/cXL/cHD/eXn/cnL/cXLCBnslAAAAEHRSTlMAmXO/YJ9ATo9/Vh7fMBOvdmI8dQAAAHdJREFUSMft0UkOgCAQRNEGZB70/qd1o9F0hMKVmPCXpN6GpqGLQVYKhR6yopFFeyyKAEUGAgKBAYmAnGCCCf4EXmcXkGFAbSD1KVD6aO0D+nrVXUDertMFkjmzaZRf6gIOAcdARiASy7f3nggLvudFpyq5TCO3A483HtOoE/1VAAAAAElFTkSuQmCC"
                    alt="Country code"
                  />
                  <Typography variant="subtitle2" style={{ color: "#768096" }}>
                    Phone number
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                  }}
                >
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Removes the border
                        },
                      },
                    }}
                  >
                    <Select
                      value={countryCode}
                      onChange={handleCountryCodeChange}
                    >
                      <MenuItem value="+91">+91</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{
                      width: "280px",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Removes the border
                        },
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                        WebkitTextFillColor: "#000", // Sets text color to black
                      },
                    }}
                    name="username"
                    id="username"
                    onChange={handleChange}
                    size="small"
                    placeholder="Please enter the phone number"
                  />
                </Box>
              </div>
            </div>
            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <img
                    className={classes.icon}
                    width={30}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAWlBMVEUAAAD/cXH/cHD/cnL/cXL/cXL/cXL/cXH/cnL/cnL/cXH/cHD/cXL/cXL/cnL/cnL/cXP/cnL/cHH/cXH/cnL/Zmb/cXP/cHH/cHD/cXH/cHD/cnL/dHT/cXJ2Y/zhAAAAHXRSTlMAmR8mj+/ffzlMExCvoYZfb0NAz2kKv7+Gc1ZyVs4lXHAAAAE4SURBVEjH3ZRbloMgEEQtBCUiiK8k6rD/bU7CzDEJCMJv6hPqnqKhm+I7Va6TogDUspYJ9p7iTYqf2JvF+qiqN/VH1k3UrwBs/N/T8O0ZMkeADriXH/XcgSlS7sPfOJkUKGMBXpF9LILi7q3NFHQOn2g6jCUBgAP9wcMAPABcDuvjwCULKMPAmgt02YCUPfHE5TEw0JGZgNgoB8dOruZEFfnwV8ZkEa1J0O0twCTpVUedBtQ74FXMqNbSq6vdgdHZoeIwuNoBFoh2CLYD/m30y8L9swaAwbYh8PN4/iRAFA2s5kIkAbZBAdu6iQkzrJrEhMHOnp2CzgHCt8Sn7jlLVQDIfge3CaTtZEGd5THS3beaXlm4l7RJkt4BkQaQzImTxUsid6YLIs/8rXA/Gt2GPiZWtZoUX6Rf0glhk/KUKW0AAAAASUVORK5CYII="
                    alt="Country code"
                  />
                  <Typography variant="subtitle2" style={{ color: "#768096" }}>
                    Password
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                  }}
                >
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    name="password"
                    type="password"
                    id="password"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Removes the border
                        },
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                        WebkitTextFillColor: "#000", // Sets text color to black
                      },
                    }}
                    size="small"
                    placeholder="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </div>
            </div>

            {/* Remember Password */}
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Remember password"
            />

            {/* Login and Register Buttons */}

            <Box
              style={{
                display: "flex",
                justifyContent: "center",

                alignItems: "center",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    background:
                      "linear-gradient(to bottom right, #FF9A5A, #EF4765)",
                    border: 0,
                    borderRadius: "100px",
                    color: "#FFFFFF",
                    cursor: "pointer",
                    display: "inline-block",
                    fontFamily:
                      '-apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: 2.5,
                    minWidth: "355%",
                    padding: "0 1rem",
                  }}
                >
                  Log in
                </Button>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleNavigation("/register")}
                  style={{
                    border: "1px solid #FF9A5A",
                    borderRadius: "100px",
                    color: "#FF9A5A",
                    cursor: "pointer",
                    display: "inline-block",
                    fontFamily:
                      '-apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: 2.5,
                    minWidth: "294%",
                    padding: "0 1rem",
                  }}
                >
                  Register
                </Button>
              </Box>
            </Box>

            {/* Footer Links */}
            <div
              className={classes.footer}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className={classes.icon}
                  width={25}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAWlBMVEUAAAD/cXH/cHD/cnL/cXL/cXL/cXL/cXH/cnL/cnL/cXH/cHD/cXL/cXL/cnL/cnL/cXP/cnL/cHH/cXH/cnL/Zmb/cXP/cHH/cHD/cXH/cHD/cnL/dHT/cXJ2Y/zhAAAAHXRSTlMAmR8mj+/ffzlMExCvoYZfb0NAz2kKv7+Gc1ZyVs4lXHAAAAE4SURBVEjH3ZRbloMgEEQtBCUiiK8k6rD/bU7CzDEJCMJv6hPqnqKhm+I7Va6TogDUspYJ9p7iTYqf2JvF+qiqN/VH1k3UrwBs/N/T8O0ZMkeADriXH/XcgSlS7sPfOJkUKGMBXpF9LILi7q3NFHQOn2g6jCUBgAP9wcMAPABcDuvjwCULKMPAmgt02YCUPfHE5TEw0JGZgNgoB8dOruZEFfnwV8ZkEa1J0O0twCTpVUedBtQ74FXMqNbSq6vdgdHZoeIwuNoBFoh2CLYD/m30y8L9swaAwbYh8PN4/iRAFA2s5kIkAbZBAdu6iQkzrJrEhMHOnp2CzgHCt8Sn7jlLVQDIfge3CaTtZEGd5THS3beaXlm4l7RJkt4BkQaQzImTxUsid6YLIs/8rXA/Gt2GPiZWtZoUX6Rf0glhk/KUKW0AAAAASUVORK5CYII="
                  alt="Country code"
                />
                <Button
                  size="small"
                  style={{ color: "#a29696" }}
                  onClick={() => (window.location.href = "/forgot/")}
                >
                  Forgot password
                </Button>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SupportAgentIcon
                  fontSize="medium"
                  style={{ color: "#f95959" }}
                />
                <Button
                  size="small"
                  style={{ color: "#a29696" }}
                  onClick={() => (window.location.href = "/keFuMenu/")}
                >
                  Customer Service
                </Button>
              </Box>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default LoginForm;
