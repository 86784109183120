import {
  Box,
  Button,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Cookies from "js-cookie";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import axiosInstance from "../../AxiosBaseApi";

const Recharge = () => {
  const history = useNavigate();
  const [loginStatus, setLoginStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState("lottery");
  const [userInfo, setUserInfo] = useState({
    user: {},
    totalRecharge: 0,
    totalWithdraw: 0,
  });
  const [amount, setAmount] = useState(300); // default value
  const quickAmounts = [1, 500, 1000, 10000, 100000, 1000000]; // quick money options

  const handleManualUPIPaymentRequest = async (amount) => {
    window.location.href = `/wallet/paynow/manual_upi?am=${amount}`;
  };

  const handleAmountClick = (value) => {
    setAmount(value);
  };

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .get("api/webapi/GetUserInfo")

        .then((res) => {
          if (res.status === 200) {
            setUserInfo({
              user: res.data.data,
              totalRecharge: res.data.totalRecharge,
              totalWithdraw: res.data.totalWithdraw,
            });
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    const token = Cookies.get("auth");
    const token1 = Cookies.get("token");
    if (!token) {
      setLoginStatus(false);
      history("/login");
    } else {
      setLoginStatus(true);
      getUserData();
    }
  }, [loginStatus]);

  const handleTabClick = (tabId) => {
    setSelectedTab(tabId);
  };

  return (
    <>
      <div style={{ width: "100%", background: "#F7F8FF" }}>
        <Box sx={{ marginBottom: "5px" }}>
          <AppBar
            position="static"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2px 10px",
              height: "3.22667rem",
              boxShadow: "unset",
              backgroundColor: "#fff",
            }}
          >
            <Box onClick={() => history(-1)} style={{ textDecoration: "none" }}>
              <ChevronLeftIcon
                fontSize="medium"
                style={{
                  // width: "60px",
                  color: "#000",
                  border: "1px solid #f2f3f5",
                  borderRadius: 50,
                }}
              />
            </Box>
            <Typography style={{ color: "#000", fontSize: "24px" }}>
              Deposit
            </Typography>

            <Box style={{ display: "flex" }}>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Typography
                  style={{
                    borderColor: "#fff",
                    color: "#000",
                    fontSize: "12px",
                  }}
                >
                  Deposit History
                </Typography>
              </Link>
            </Box>
          </AppBar>
        </Box>

        {/*Slider   */}
        <Grid
          container
          direction="column"
          sx={{ maxHeight: { xs: 190, sm: 250, md: 270, lg: 340 } }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            style={{
              width: "95%",
              marginTop: "5px",
              backgroundImage: "url(./images/bgimgrec.png)",
              minHeight: "150px",
              backgroundSize: "contain", // Makes sure the image scales without being distorted
              backgroundRepeat: "no-repeat", // Prevents image repetition
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 20,
            }}
          >
            <Box style={{ display: "flex", gap: 6, alignItems: "center" }}>
              <img
                // width="100%"
                height="20px"
                style={{ borderRadius: "10px" }}
                src="./images/balance.png"
              />
              <Typography style={{ fontSize: "16px", color: "#fff" }}>
                Balance
              </Typography>
            </Box>
            <Box style={{ display: "flex", gap: 6, alignItems: "center" }}>
              <Typography style={{ fontSize: "32px", color: "#fff" }}>
                ₹{userInfo.user.money_user}.00
              </Typography>
              <img width={20} height={15} src="./images/load.png" />
            </Box>
          </Grid>
        </Grid>

        <div style={{ display: "flex", margin: "10px" }}>
          <div
            role="tablist"
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {[
              {
                id: "lottery",
                name: "Innate UPI-QR",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/payNameIcon/payNameIcon2_20240821190505cy9d.png",
              },
              {
                id: "flash",
                name: "Expert PAytm-QR",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/payNameIcon/payNameIcon_20240822174126jeiy.png",
              },
              {
                id: "popular",
                name: "UPI-QR PAY",

                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/payNameIcon/payNameIcon_202408211133599phf.png",
              },
              {
                id: "slot",
                name: "USDT",
                imgSrc:
                  "https://ossimg.91admin123admin.com/91club/payNameIcon/payNameIcon_202308142205192wpa.jpg",
              },
            ].map((tab) => (
              <div
                key={tab.id}
                role="tab"
                tabIndex="0"
                aria-selected={selectedTab === tab.id}
                onClick={() => handleTabClick(tab.id)}
              >
                <div
                  style={{
                    minWidth: "7rem",
                    height: "6rem",
                    padding: "2px",
                    margin: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundSize: "100% 100%",
                    marginBottom: "1.17333rem",
                    borderRadius: 10,
                    backgroundColor:
                      selectedTab === tab.id ? "transparent" : "#fff", // Apply green color if active
                    backgroundImage:
                      selectedTab === tab.id
                        ? "url(https://91club.bet/assets/png/bgActive-805fae0e.png)" // No background image if active
                        : "none", // Keep image for inactive
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundSize: "100% 100%",
                      backgroundImage: `url(${tab.imgSrc})`,
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: selectedTab === tab.id ? "#fff" : "#000",
                    }}
                  >
                    {tab.name}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Box className="pay-box" p={2} bgcolor="#f5f5f5" borderRadius={2}>
          <Box mt={2}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              wrap="wrap"
            >
              {quickAmounts.map((value) => (
                <Grid item key={value}>
                  <Button
                    variant="contained"
                    // color={amount === value ? 'primary' : 'default'}
                    onClick={() => handleAmountClick(value)}
                    sx={{
                      padding: "10px 20px",
                      minWidth: "6rem",
                      backgroundColor: amount === value ? "#fc6c6c" : "#e0e0e0",
                    }}
                  >
                    <Typography variant="body1">
                      {value >= 1000 ? `${value / 1000}K` : value}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* INR Section */}
          <Box display="flex" alignItems="center" mt={3}>
            <img
              // width="100%"
              height="20px"
              style={{ borderRadius: "10px" }}
              src="./images/balance.png"
            />
            <Typography variant="body1" ml={1}>
              INR ₹
            </Typography>
          </Box>

          {/* Input Section */}
          <Box mt={2} display="flex" alignItems="center">
            <Typography variant="h6" component="div">
              ₹
            </Typography>
            <InputBase
              value={amount}
              onChange={handleInputChange}
              placeholder="Please enter the amount"
              inputProps={{ type: "tel", inputMode: "numeric" }}
              sx={{
                ml: 2,
                flex: 1,
                backgroundColor: "#fff",
                padding: "5px 10px",
                borderRadius: "4px",
              }}
            />
          </Box>

          {/* Deposit Button */}
          <Box mt={4} textAlign="center">
            <NavLink
              style={{ textDecoration: "none" }}
              to={`/wallet/paynow/manual_upi?am=${amount}`}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  borderRadius: "20px",
                  padding: "10px 40px",
                  backgroundColor: "#B6BCC8",
                }}
              >
                Deposit Fund
              </Button>
            </NavLink>
          </Box>

          {/* Notice Section */}
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary">
              * The Bank payment gateway offers an additional 5% bonus on First
              deposit.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              If you transfer the wrong amount as instructed, the funds will be
              lost, and our company will not be held responsible!
            </Typography>
            <Typography variant="body2" color="textSecondary">
              * Note: You must deposit the exact amount as instructed in the
              order. The system will automatically update the bonus funds.
            </Typography>
          </Box>
        </Box>

        {/* <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box> */}
      </div>
    </>
  );
};

export default Recharge;
