import { Box, Button, Grid, Typography } from "@mui/material";


const Period = ({period, seconds1, seconds2, minutes }) => {
  
  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          top: "-4rem",
        }}
      >
        <Grid sx={{ display: "flex", width: "100%" }} justifyContent="center">
          <Grid
            style={{
              width: "90%",
              backgroundImage:
              "url(./images/win/wingo.png)",
              gap:10,
              backgroundSize: "cover", // Make the background cover the button area
              backgroundPosition: "center", // Center the image
              backgroundRepeat: "no-repeat",
              borderRadius: 15,
              minHeight: "120px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box style={{ display: "flex", gap:3, flexDirection:"column" }}>
              <Button
                variant="outlined"
                size="small"
                style={{
                  textTransform: "capitalize",
                  height: "20px",
                  color: "#fff",
                  border: "1px solid #fff",
                  borderRadius: 30,
                }}
              >
                How to play
              </Button>
              <Typography variant="subtitle1" style={{ color: "#fff" }}>
                Win Go 1 Min
              </Typography>
              <Box style={{display:'flex', gap:3,  width:"100%"}}>
              <img
                width="30"
                src="./images/win/n9.png"
              ></img>
              <img
                width="30"
                src="./images/win/n2.png"
              ></img>
              <img
                width="30"
                src="./images/win/n8.png"
              ></img>
              <img
                width="30"
                src="./images/win/n5.png"
              ></img>
              <img
                width="30"
                src="./images/win/n0.png"
              ></img>

              </Box>
            </Box>
            <Box style={{ display: "flex", gap:3, flexDirection:"column" ,  alignItems: "flex-end", }}>
            <Typography variant="subtitle1" style={{ color: "#fff" }}>
               Time remaning
              </Typography>
              <Box style={{display:'flex', gap:4}}>
              <Typography variant="subtitle2" style={{
                background:'linear-gradient(135deg, transparent 10px, #fff 0) 0 0',
                color: "#f95959",
                fontWeight: 700,
               fontSize: '18px',
               textAlign: 'center',
                padding: '7px',}}>
                0
              </Typography>
              <Typography variant="subtitle2" style={{   background:'#fff',
                color: "#f95959",
                fontWeight: 700,
               fontSize: '18px',
               textAlign: 'center',
                padding: '7px',}}>
               {minutes}
              </Typography>
              <Typography variant="subtitle2" style={{   background:'#fff',
                color: "#f95959",
                fontWeight: 700,
               fontSize: '18px',
               textAlign: 'center',
                padding: '7px', }}>
               :
              </Typography>
              <Typography variant="subtitle2" style={{   background:'#fff',
                color: "#f95959",
                fontWeight: 700,
               fontSize: '18px',
               textAlign: 'center',
                padding: '7px', }}>
               {seconds1}
              </Typography>
              <Typography variant="subtitle2" style={{  background:'linear-gradient(-45deg, transparent 10px, #fff 0) 0 0',
                color: "#f95959",
                fontWeight: 700,
               fontSize: '18px',
               textAlign: 'center',
                padding: '7px', }}>
               {seconds2}
              </Typography>
              </Box>
              <Typography variant="body1" style={{ color: "#fff", fontWeight:600 }}>
                {period}
              </Typography>
              
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Period;
