import { Box, Typography } from "@mui/material";
import FooterNav from "../../FooterNav";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";

const DailyTasks = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}>
        <Box
          style={{
            height: "8vh",
            display: "flex",
            justifyContent: "space-between",
            // background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
            alignItems: "center",
            padding: "0 14px",
            borderBottom: "0.7px solid #ff9a8e",
          }}
        >
          <Box onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                // width: "60px",
                // color: "#fff",
                border: "1px solid #000",
                borderRadius: 50,
              }}
            />
          </Box>
          <Typography
            style={{
              fontSize: "14px",
            }}
          >
            Collection Records
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            padding: 2,
            gap: 1,
            height: "15vh",
            flexDirection: "column",
            backgroundImage: `url('./images/award_bg.png')`, // Replace with your image URL
            backgroundSize: "cover", // Make the background cover the button area
            backgroundPosition: "center", // Center the image
            backgroundRepeat: "no-repeat",
          }}
        >
          <Typography
            color="#fff"
            style={{
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Activity Award
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              style={{ fontSize: "12px", lineHeight: "10px" }}
              color="#fff"
            >
              Complete weekly/daily tasks to receive rich rewards
            </Typography>
            <Typography
              style={{ fontSize: "12px", lineHeight: 1 }}
              color="#fff"
            >
              Weekly rewards cannot be accumulated to the next week, and daily
              rewards cannot be accumulated to the next day.{" "}
            </Typography>
          </Box>
        </Box>
      </div>
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};
export default DailyTasks;
