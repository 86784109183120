import { Modal } from "@mui/material";
import { Button, Box, Typography } from "@mui/material";

const Models = ({ handleClose, open }) => {
  return (
    <>
      <div>
        <Modal
          // aria-labelledby="unstyled-modal-title"
          // aria-describedby="unstyled-modal-description"
          open={open.open}
          onClose={handleClose}
          // slots={{ backdrop: StyledBackdrop }}
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 320,
              backgroundColor: "#f5f5f5",
              // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "20px 0",
                gap: 1,
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
                borderTopLeftRadius: "15px", // Matching radius for top corners
                borderTopRightRadius: "15px", // Matching radius for top corners
              }}
            >
              <Typography style={{ fontSize: "14px" }} color="#fff">
                Extra first deposit bonus
              </Typography>
              <Typography
                style={{ fontSize: "12px", lineHeight: "10px" }}
                color="#fff"
              >
                Each account can only receive rewards once
              </Typography>
            </Box>

            <Box
              style={{
                backgroundColor: "#f7f7f7",
                padding: 12,
                height: "100%", // Ensure height is relative to the right content
                overflowY: "auto",
                maxHeight: "350px",
              }}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "4px", // Width of the scrollbar
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1", // Track color
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888", // Scrollbar thumb color
                  borderRadius: "10px", // Rounder corners
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555", // Thumb color on hover
                },
              }}
            >
              {[{ id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }].map(
                (alldata, ind) => {
                  return (
                    <Box
                      key={ind}
                      style={{
                        padding: 15,
                        backgroundColor: "#fff",
                        borderRadius: ".5rem",
                        marginBottom: "3px",
                      }}
                    >
                      <Box style={{ margin: 5 }}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="subtitle1" color="#888">
                            First deposit
                            <span style={{ color: "#FEAA57" }}>120000</span>
                          </Typography>{" "}
                          <Typography variant="subtitle1" color="#FEAA57">
                            5888
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            color="#888"
                            style={{ lineHeight: 1 }}
                          >
                            Deposit 120000 for the first time and you will
                            receive 5888 bonus
                          </Typography>{" "}
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="subtitle1" color="#888">
                            Time
                          </Typography>{" "}
                          <Button
                            variant="outlined"
                            sx={{
                              fontSize: "12px", // Adjust font size
                              minWidth: "50px", // Adjust width
                              height: "25px", // Adjust height
                            }}
                          >
                            Deposit
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                }
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                borderRadius: "15px", // Matching radius for top corners
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: 1,
                  alignItems: "center",
                  justifyContent: "space-around",

                  width: "100%",
                }}
              >
                <Typography style={{ fontSize: "12px", lineHeight: "10px" }}>
                  No more reminders today
                </Typography>
                <Button
                  style={{ fontSize: "12px" }}
                  variant="contained"
                  sx={{
                    padding: "5px 10px", // Adjust padding
                    fontSize: "12px", // Adjust font size
                    minWidth: "100px", // Adjust width
                    height: "25px", // Adjust height
                    color: "#fff",
                    borderRadius: 5,
                  }}
                >
                  Activity
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default Models;
