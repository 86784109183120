import { configureStore } from "@reduxjs/toolkit";
import counterSlice from "./slices/counter";
import openSlice from "./slices/alertOpen";
import userSlice from "./slices/userInfo";
import runMyBetApi from "./slices/runmybetapi";
import resultOpenSlice from "./slices/resultOpen";

export const store = configureStore({
  reducer: {
    counter: counterSlice,
    openAlert: openSlice,
    userdata: userSlice,
    runMyBet: runMyBetApi,
    resultOpen: resultOpenSlice,
  },
});
