import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import axiosInstance from "../../../AxiosBaseApi";

const HistoryCard = ({ data, loading, typeid, pageno, pageto, period }) => {
  // const [data, setData] = useState([]);
  const [historyPage, setHistoryPage] = useState(false);

  const [myBetData, setMyBetData] = useState([]);
  const [myBetPage, setMyBetPage] = useState(false);

  // const [trendPage, setTrendPage] = useState(false);
  const [loading1, setLoading1] = useState(true);

  const handleClick = async () => {
    // const token = Cookies.get("token");
    setMyBetPage(true);
    setHistoryPage(false);
    // setTrendPage(false);
    try {
      const response = await axiosInstance
        .post("api/webapi/k3/GetMyEmerdList", {
          gameJoin: typeid,
          pageno: pageno,
          pageto: pageto,
        })

        .then((res) => {
          if (res.status === 200) {
            setMyBetData(res.data.data.gameslist);
            setLoading1(false);
          } else {
            alert(res.data.message);
            setLoading1(false);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleClickHistory = () => {
    setMyBetPage(false);
    setHistoryPage(true);
    // setTrendPage(false);
  };
  // const handleClickTrend = () => {
  //   setMyBetPage(false);
  //   setHistoryPage(false);
  //   setTrendPage(true);
  // };

  const sumOfResult = data.reduce((sum, game) => {
    return sum + Number(game.result);
  }, 0);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          top: "-4rem",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
          justifyContent="center"
        >
          <Grid
            style={{
              width: "95%",
              background: "#fff",
              gap: 10,
              //   borderRadius: 15,
              minHeight: "300px",
              display: "flex",
              paddingTop: 15,
              flexDirection: "column",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={handleClickHistory}
                style={{
                  padding: "5px",
                  width: "120px",
                  textTransform: "capitalize",
                  border: "1px solid #3c5ef6",
                  color: "#000",
                  borderRadius: "10px",
                  boxShadow: ".02667rem .02667rem .13333rem .13333rem #eaeefc",
                }}
              >
                Game History
              </Button>
              <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={handleClick}
                style={{
                  padding: "5px",
                  width: "120px",
                  textTransform: "capitalize",
                  border: "1px solid #3c5ef6",
                  color: "#000",
                  borderRadius: "10px",
                  boxShadow: ".02667rem .02667rem .13333rem .13333rem #eaeefc",
                }}
              >
                My Bets
              </Button>
              {/* <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={handleClickTrend}
                style={{
                  padding: "5px",
                  width: "120px",
                  textTransform: "capitalize",
                  border: "1px solid #3c5ef6",
                  color: "#000",
                  borderRadius: "10px",
                  boxShadow: ".02667rem .02667rem .13333rem .13333rem #eaeefc",
                }}
              >
                Trend
              </Button> */}
            </Box>
            {/* {trendPage ? (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Trend
              </p>
            ) : ( */}
            {/* <> */}
            <Box>
              {myBetPage ? (
                <>
                  {loading1 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {myBetData.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell
                              align="center"
                              style={{ fontSize: "16px", display: "flex" }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {row.stage}
                                  {period === row.stage ? (
                                    ""
                                  ) : (
                                    <>
                                      {row.get === 0 ? (
                                        <>
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: "red",
                                              backgroundColor: "#f3f1f1",
                                              padding: ".10667rem 1.26667rem",
                                              marginLeft: ".26667rem",
                                              borderRadius: "1.53333rem",
                                            }}
                                          >
                                            Fail
                                          </Typography>{" "}
                                        </>
                                      ) : (
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            color: "green",
                                            backgroundColor: "#f3f1f1",
                                            padding: ".10667rem 1.26667rem",
                                            marginLeft: ".26667rem",
                                            borderRadius: "1.53333rem",
                                          }}
                                        >
                                          Success
                                        </Typography>
                                      )}{" "}
                                    </>
                                  )}
                                </Box>
                                <Typography fontSize={10}>
                                  {row.today}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "14px", marginRight: "20px" }}
                            >
                              {row.get === 0 ? (
                                <>
                                  <Typography
                                    variant="body2"
                                    style={{ color: "red" }}
                                  >
                                    <span>-</span>
                                    {row.price}
                                  </Typography>{" "}
                                </>
                              ) : (
                                <Typography style={{ color: "green" }}>
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      fontSize: "16px",
                                    }}
                                  >
                                    +
                                  </span>
                                  {row.get}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              ) : (
                <>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Table sx={{ width: "100%" }}>
                      <TableHead sx={{ maxWidth: "100%", padding: 0 }}>
                        <TableRow sx={{ background: "#fbedf3" }}>
                          <TableCell
                            align="center"
                            style={{ fontSize: "13px", padding: 0 }}
                          >
                            Draw Period
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: "13px", padding: 0 }}
                          >
                            Number
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: "13px", padding: 0 }}
                          >
                            Big/Small
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: "13px", padding: 0 }}
                          >
                            Odd/Even
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: "13px", padding: 0 }}
                          >
                            Result
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              align="center"
                              style={{ fontSize: "14px", padding: "14px" }}
                            >
                              {row.period}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: "12px", padding: "14px" }}
                            >
                              {row.result
                                .toString()
                                .split("")
                                .reduce((sum, digit) => {
                                  return sum + Number(digit);
                                }, 0)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ padding: "14px" }}
                            >
                              {(() => {
                                const sumOfDigits = row.result
                                  .toString()
                                  .split("")
                                  .reduce(
                                    (sum, digit) => sum + Number(digit),
                                    0
                                  );

                                return sumOfDigits > 10 ? (
                                  <Typography variant="subtitle1">
                                    Big
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle1">
                                    Small
                                  </Typography>
                                );
                              })()}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ padding: "14px" }}
                            >
                              {" "}
                              {(() => {
                                const sumOfDigits = row.result
                                  .toString()
                                  .split("")
                                  .reduce(
                                    (sum, digit) => sum + Number(digit),
                                    0
                                  );

                                return sumOfDigits % 2 === 0 ? (
                                  <Typography variant="subtitle1">
                                    Even
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle1">
                                    Odd
                                  </Typography>
                                );
                              })()}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ padding: "14px" }}
                            >
                              <Box style={{ display: "flex", gap: 1 }}>
                                <img
                                  src="./images/k3/4.49ad5147.png"
                                  width="20px"
                                  alt=""
                                />

                                <img
                                  src="./images/k3/5.135b3ae6.png"
                                  width="20px"
                                  alt=""
                                />

                                <img
                                  src="./images/k3/6.bbf99ab0.png"
                                  width="20px"
                                  alt=""
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </Box>
            {/* </>
            )} */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default HistoryCard;
