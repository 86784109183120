import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const resultOpenSlice = createSlice({
  name: "resultOpenState",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    toggleOpen: (state) => {
      state.open = !state.open;
    },
  },
});

export const { setOpen, toggleOpen } = resultOpenSlice.actions;

export default resultOpenSlice.reducer;
