import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Drawer } from "@mui/material";
import "../../../style.css";
import axiosInstance from "../../../AxiosBaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { toggleOpen } from "../../../redux/slices/runmybetapi";
import { useDispatch } from "react-redux";

const MainBox5d = ({
  seconds1,
  seconds2,
  typeid,
  period,
  showResultNow,
  minutes,
}) => {
  const [open, setOpen] = useState(false);
  const [drawerBackground, setDrawerBackground] = useState("#ff9500"); // Default background color
  const [drawerBackground2, setDrawerBackground2] = useState("#fe6868"); // Default background color
  const [text, setText] = useState("");
  const [activeButton, setActiveButton] = useState(0);
  const [buttonValue, setButtonValue] = useState(10);
  const [activeButton2, setActiveButton2] = useState(0);
  const [buttonValue2, setButtonValue2] = useState(1);
  const [activeButton33, setActiveButton33] = useState(0);
  const [buttonValue33, setButtonValue33] = useState(1);
  const [activeButton44, setActiveButton44] = useState(0);
  const [buttonValue44, setButtonValue44] = useState(1);
  const [activeButton55, setActiveButton55] = useState(0);
  const [buttonValue55, setButtonValue55] = useState("a");
  const dispatch = useDispatch();

  const buttons55 = [
    { label: "A", value: "a" },
    { label: "B", value: "b" },
    { label: "C", value: "c" },
    { label: "D", value: "d" },
    { label: "E", value: "e" },
    { label: "Total", value: "total" },
  ];

  const handleButtonClick55 = (index, value) => {
    setActiveButton55(index);
    setButtonValue55(value);
  };
  const buttons44 = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
  ];

  const handleButtonClick44 = (index, value) => {
    setActiveButton44(index);
    setButtonValue33(value);
    setOpen(true);
  };
  const buttons33 = [
    { label: "Big 2", value: "b" },
    { label: "Small 2", value: "s" },
    { label: "Odd 2", value: 1 },
    { label: "Even 2", value: "c" },
  ];

  const handleButtonClick33 = (index, value) => {
    setActiveButton33(index);
    setButtonValue33(value);
    setOpen(true);
  };

  const buttons2 = [
    { label: "X1", value: 10 },
    { label: "X5", value: 50 },
    { label: "X10", value: 100 },
    { label: "X20", value: 200 },
    { label: "X50", value: 500 },
    { label: "X100", value: 1000 },
  ];

  const handleButtonClick22 = (index, value) => {
    setActiveButton2(index);
    setButtonValue2(value);
  };

  const buttons = [
    { label: "10", value: 10 },
    { label: "100", value: 100 },
    { label: "1000", value: 1000 },
    { label: "10000", value: 10000 },
  ];

  const handleButtonClick2 = (index, value) => {
    setActiveButton(index);
    setButtonValue(value);
  };

  const toggleDrawer = (state) => (event) => {
    setOpen(state);
  };
  const token = Cookies.get("auth");
  const getUserData = async () => {
    try {
      const formData = new FormData();

      // Data ko FormData object mein append karna
      formData.append("game", typeid);
      formData.append("list_join", buttonValue33);
      formData.append("join", buttonValue55);
      formData.append("x", buttonValue2);
      formData.append("money", buttonValue);

      const response = await axiosInstance
        .post("api/webapi/action/5d/join", formData, {
          headers: {
            "Content-Type": "application/json", // FormData ke liye content type set karna
            Authorization: `Bearer ${token}`, // Agar token ki zaroorat hai toh
          },
        })

        .then((res) => {
          if (res.status === 200) {
            dispatch(toggleOpen());
            toast(res.data.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: 0,
              theme: "colored",
              // transition: Bounce,
            });
            setTimeout(() => {
              setOpen(false);
              setButtonValue(10);
              setActiveButton(0);
            }, 2000);
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleButtonClick = (color, text) => {
    setDrawerBackground(color);
    setText(text);
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}> */}

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          top: "-4rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Drawer
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                height: "80%", // Set height to 50%
                width: { xs: "100%", sm: "400px", md: "400px" },
                left: { sm: "-9px", md: "-14px" },
                margin: "0 auto", // Center horizontally
                borderRadius: "25px 25px 0 0", // Optional: add some border radius to make it look better
              },
            }}
          >
            <Box>
              <Grid
                style={{
                  width: "95%",
                  background: "#fff",
                  gap: 10,
                  borderRadius: 15,
                  minHeight: "290px",
                  display: "flex",
                  padding: 15,
                  flexDirection: "column",
                }}
              >
                {/* abcde box */}
                <Box
                  style={{
                    display: "flex",
                    borderBottom: "1px solid #d7d7df",
                  }}
                >
                  {buttons55.map((button, index) => (
                    <Box
                      key={index}
                      onClick={() => handleButtonClick55(index, button.value)}
                      sx={{
                        width: "2.3rem",
                        height: "2.3rem",
                        lineHeight: "2.3rem",
                        background: "#d7d7df",
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#fff",
                        borderRadius: "1.50667rem 1.50667rem 0 0",
                        position: "relative",
                        marginRight: ".5rem",
                        textAlign: "center",
                        backgroundColor:
                          activeButton55 === index
                            ? drawerBackground
                            : "#cacad2",
                        "&:hover": {
                          backgroundColor:
                            activeButton55 === index
                              ? drawerBackground
                              : "rgb(240, 240, 240)",
                        },
                      }}
                    >
                      {button.label}
                    </Box>
                  ))}
                </Box>
                {/* odd even button */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {buttons33.map((button, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      size="small"
                      onClick={() => handleButtonClick33(index, button.value)}
                      sx={{
                        width: "80px",
                        minWidth: "35px",
                        textTransform: "capitalize",
                        height: "34px",
                        color: "#fff",
                        padding: "1px",
                        background: "#eee",
                        display: "flex",
                        flexDirection: "column",
                        lineHeight: 1,
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: 700,
                        backgroundColor:
                          activeButton33 === index
                            ? drawerBackground
                            : "#cacad2",
                        "&:hover": {
                          backgroundColor:
                            activeButton33 === index
                              ? drawerBackground
                              : "rgb(240, 240, 240)",
                        },
                      }}
                    >
                      {button.label}
                    </Button>
                  ))}
                </Box>

                {/* number button */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  {buttons44.map((button, index) => (
                    <Box
                      onClick={() => handleButtonClick44(index, button.value)}
                      style={{
                        width: "17%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "45px",
                          minWidth: "35px",
                          textTransform: "capitalize",
                          height: "45px",
                          backgroundColor:
                            activeButton44 === index
                              ? drawerBackground
                              : "none",
                          "&:hover": {
                            backgroundColor:
                              activeButton44 === index
                                ? drawerBackground
                                : "none",
                          },
                          borderColor: "#d7d7df",
                          color: "#d7d7df",
                          padding: "8px",
                          borderRadius: "25px",
                          lineHeight: 1,
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {button.label}
                      </Button>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#838383" }}
                      >
                        9
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>

              <Box
                style={{
                  display: "flex",
                  margin: "10px",
                  justifyContent: "space-between",
                }}
              >
                <Typography align="center">Amount</Typography>

                <Box sx={{ display: "flex", gap: 1 }}>
                  {buttons.map((button, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      size="small"
                      onClick={() => handleButtonClick2(index, button.value)}
                      sx={{
                        padding: "1px 9px",
                        minWidth: 0,
                        borderRadius: "0px",
                        fontWeight: 700,
                        color: "#373535",
                        backgroundColor:
                          activeButton === index
                            ? drawerBackground2
                            : "rgb(240, 240, 240)",
                        "&:hover": {
                          backgroundColor:
                            activeButton === index
                              ? drawerBackground2
                              : "rgb(240, 240, 240)",
                        },
                      }}
                    >
                      {button.label}
                    </Button>
                  ))}
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  margin: "10px",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  display="flex"
                  align="center"
                  justifyContent="center"
                >
                  Quantity
                </Typography>
                <Box style={{ display: "flex", gap: 8 }}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "rgb(240, 240, 240)",
                      padding: "1px 12px",
                      minWidth: 0,
                      borderRadius: "0px",
                    }}
                  >
                    -
                  </Button>
                  <input type="number" style={{ width: "40px" }} />
                  <Button
                    variant="contained"
                    style={{
                      background: "rgb(240, 240, 240)",
                      padding: "1px 12px",
                      minWidth: 0,
                      borderRadius: "0px",
                    }}
                  >
                    +
                  </Button>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
                {buttons2.map((button, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    size="small"
                    onClick={() => handleButtonClick22(index, button.value)}
                    sx={{
                      padding: "1px 10px",
                      minWidth: 0,
                      borderRadius: "0px",
                      fontWeight: 600,
                      color: "#373535",
                      backgroundColor:
                        activeButton2 === index
                          ? drawerBackground2
                          : "rgb(240, 240, 240)",
                      "&:hover": {
                        backgroundColor:
                          activeButton2 === index
                            ? drawerBackground2
                            : "rgb(240, 240, 240)",
                      },
                    }}
                  >
                    {button.label}
                  </Button>
                ))}
              </Box>
              <Box
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                {" "}
                <Checkbox defaultChecked disabled />{" "}
                <Typography>Rule</Typography>
                <Typography style={{ color: "#f4453e" }}>
                  Pre-Selling
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  position: "absolute", // Position absolutely
                  bottom: 0, // Align at the bottom
                  left: 0, // Align to the left
                  right: 0, // Align to the right
                  padding: "0 10px", // Optional: Add padding to the container
                  backgroundColor: "#fff", // Optional: Set a background color
                  zIndex: 1, // Optional: Adjust z-index to keep it above other elements
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  type="button"
                  onClick={handleCancel}
                  style={{
                    background: "#25253c",
                    color: "#fff",
                    width: "30%",
                    height: "50px",
                    padding: "0",
                    fontSize: "12px",
                    minWidth: "0",
                    // overflow: "hidden",
                    borderRadius: 0,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={getUserData}
                  style={{
                    background: "linear-gradient(90deg, #f54545, #fe6868)",
                    color: "#fff",
                    width: "70%",
                    height: "50px",
                    padding: "0",
                    fontSize: "12px",
                    minWidth: "0",
                    // overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Total Amount {buttonValue2 * buttonValue}
                </Button>
                <ToastContainer />
              </Box>
            </Box>
          </Drawer>
        </div>

        <Grid
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
          justifyContent="center"
        >
          <Grid
            style={{
              width: "95%",
              background: "#fff",
              gap: 10,
              borderRadius: 15,
              minHeight: "290px",
              display: "flex",
              padding: 15,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 0.2,
              }}
            >
              <Box>
                <Typography variant="subtitle1" style={{ color: "#838383" }}>
                  Result
                </Typography>
                <Typography variant="subtitle1" style={{ color: "#838383" }}>
                  .
                </Typography>
              </Box>
              {showResultNow == undefined ? (
                <>
                  {[1, 2, 3, 4, 5].map((button, index) => (
                    <Box
                      key={index}
                      // onClick={() => handleButtonClick44(index, button)}
                      style={{
                        // width: "17%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        // key={index}
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "40px",
                          minWidth: "35px",
                          textTransform: "capitalize",
                          height: "40px",
                          // background: '#d7d7df',
                          borderColor: "#d7d7df",
                          color: "#d7d7df",
                          borderRadius: "25px",
                          padding: "8px",
                          lineHeight: 1,
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {button}
                      </Button>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#838383" }}
                      >
                        A
                      </Typography>
                    </Box>
                  ))}
                </>
              ) : (
                showResultNow &&
                showResultNow
                  .toString()
                  .split("")
                  .map((button, index) => (
                    <Box
                      key={index}
                      // onClick={() => handleButtonClick44(index, button)}
                      style={{
                        // width: "17%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        // key={index}
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "40px",
                          minWidth: "35px",
                          textTransform: "capitalize",
                          height: "40px",
                          // background: '#d7d7df',
                          borderColor: "#d7d7df",
                          color: "#d7d7df",
                          borderRadius: "25px",
                          padding: "8px",
                          lineHeight: 1,
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {button}
                      </Button>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#838383" }}
                      >
                        A
                      </Typography>
                    </Box>
                  ))
              )}
              <Box>
                <Typography>=</Typography>
                <Typography variant="subtitle1" style={{ color: "#838383" }}>
                  .
                </Typography>
              </Box>

              <Box>
                <Button
                  // key={index}
                  variant="contained"
                  size="small"
                  sx={{
                    width: "45px",
                    minWidth: "35px",
                    textTransform: "capitalize",
                    height: "45px",
                    background: "#fa574a",
                    // borderColor: "#d7d7df",
                    color: "#fff",
                    borderRadius: "25px",
                    padding: "8px",
                    lineHeight: 1,
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  {showResultNow &&
                    showResultNow.split("").reduce((sum, digit) => {
                      return sum + Number(digit);
                    }, 0)}
                </Button>
                <Typography>.</Typography>
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="subtitle1">Lottery Period </Typography>
                <Typography variant="body1" fontWeight={600}>
                  {period}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  // gap: 3,
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="subtitle1" style={{ color: "#000" }}>
                  Time remaning
                </Typography>
                <Box style={{ display: "flex", gap: 4 }}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    0
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    {minutes}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    :
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    {seconds1}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    {seconds2}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                minHeight: "100px",
                border: "13px solid #00b977",
                borderRadius: "10px",
              }}
            >
              <Box
                style={{
                  minHeight: "100px",
                  width: "100%",
                  borderRadius: "2px",
                  background: "linear-gradient(180deg, #003b26, #005226)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                {showResultNow == undefined ? (
                  <>
                    {[1, 2, 3, 4, 5].map((button, index) => (
                      <Box
                        key={index}
                        style={{
                          display: "flex",
                          width: "60px",
                          height: "90px",
                          borderRadius: ".5rem",
                          overflow: "hidden",
                          background: "#333",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          alignContent="center"
                          style={{
                            background: "#e1e1ec",
                            color: "#999999",
                            textAlign: "center",
                            width: "50px",
                            fontWeight: 800,
                            fontSize: "26px",
                            height: "50px",
                            borderRadius: "40px",
                          }}
                        >
                          {button}
                        </Typography>
                      </Box>
                    ))}
                  </>
                ) : (
                  showResultNow &&
                  showResultNow
                    .toString()
                    .split("")
                    .map((button, index) => (
                      <Box
                        key={index}
                        style={{
                          display: "flex",
                          width: "60px",
                          height: "90px",
                          borderRadius: ".5rem",
                          overflow: "hidden",
                          background: "#333",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          alignContent="center"
                          style={{
                            background: "#e1e1ec",
                            color: "#999999",
                            textAlign: "center",
                            width: "50px",
                            fontWeight: 800,
                            fontSize: "26px",
                            height: "50px",
                            borderRadius: "40px",
                          }}
                        >
                          {button}
                        </Typography>
                      </Box>
                    ))
                )}
              </Box>
            </Box>
            {/* abcde box */}
            <Box
              style={{
                display: "flex",
                borderBottom: "1px solid #d7d7df",
              }}
            >
              {buttons55.map((button, index) => (
                <Box
                  key={index}
                  onClick={() => handleButtonClick55(index, button.value)}
                  sx={{
                    width: "2.3rem",
                    height: "2.3rem",
                    lineHeight: "2.3rem",
                    background: "#d7d7df",
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "#fff",
                    borderRadius: "1.50667rem 1.50667rem 0 0",
                    position: "relative",
                    marginRight: ".5rem",
                    textAlign: "center",
                    backgroundColor:
                      activeButton55 === index ? drawerBackground : "#cacad2",
                    "&:hover": {
                      backgroundColor:
                        activeButton55 === index
                          ? drawerBackground
                          : "rgb(240, 240, 240)",
                    },
                  }}
                >
                  {button.label}
                </Box>
              ))}
            </Box>
            {/* odd even button */}
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
              {buttons33.map((button, index) => (
                <Button
                  key={index}
                  variant="contained"
                  size="small"
                  onClick={() => handleButtonClick33(index, button.value)}
                  sx={{
                    width: "80px",
                    minWidth: "35px",
                    textTransform: "capitalize",
                    height: "34px",
                    color: "#fff",
                    padding: "1px",
                    background: "#eee",
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: 1,
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 700,
                    backgroundColor:
                      activeButton33 === index ? drawerBackground : "#cacad2",
                    "&:hover": {
                      backgroundColor:
                        activeButton33 === index
                          ? drawerBackground
                          : "rgb(240, 240, 240)",
                    },
                  }}
                >
                  {button.label}
                </Button>
              ))}
            </Box>

            {/* number button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {buttons44.map((button, index) => (
                <Box
                  onClick={() => handleButtonClick44(index, button.value)}
                  style={{
                    width: "17%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    key={index}
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "45px",
                      minWidth: "35px",
                      textTransform: "capitalize",
                      height: "45px",
                      // background: '#d7d7df',
                      borderColor: "#d7d7df",
                      color: "#d7d7df",
                      borderRadius: "25px",
                      padding: "8px",
                      lineHeight: 1,
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    {button.label}
                  </Button>
                  <Typography variant="subtitle1" style={{ color: "#838383" }}>
                    9
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default MainBox5d;
