import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CardSecond = ({ link1, link2, link3, link4 }) => {
  const [selectedGrid, setSelectedGrid] = useState(null); // State to track selected grid
  const navigate = useNavigate();

  const handleGridClick = (index, path) => {
    setSelectedGrid(index); // Set the clicked grid index
    setTimeout(() => {
      navigate(path); // Navigate to the clicked path after state change
    }, 100); // Small delay of 100ms
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          top: "-4.18667rem",
          display: "flex",
          flexWrap: "wrap",
          minHeight: 100,
          margin: 1.5,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            borderRadius: "10px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              paddingTop: "15px",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            {[
              {
                label: "1 min",
                imgSrc: "./images/icon_clock-gerrn.webp",
                path: link1,
              },
              {
                label: "3 min",
                imgSrc: "./images/icon_clock-gerrn.webp",
                path: link2,
              },
              {
                label: "5 min",
                imgSrc: "./images/icon_clock-gerrn.webp",
                path: link3,
              },
              {
                label: "10 min",
                imgSrc: "./images/icon_clock-gerrn.webp",
                path: link4,
              },
            ].map((item, index) => (
              <Grid
                item
                xs={3}
                key={index}
                onClick={() => handleGridClick(index, item.path)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    flexDirection: "column",

                    transition: "background-color 0.3s ease", // Smooth transition for background change
                  }}
                >
                  <Typography
                    sx={{
                      width: "1rem",
                      height: "1rem",
                      borderRadius: "1.26667rem",
                      background:
                        selectedGrid === index ? "#f54545" : "#6abe57",
                      // background: "#6abe57",
                      color: "#fff",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      "&::before, &::after": {
                        content: '""',
                        position: "absolute",
                        top: "50%",
                        width: "38px",
                        height: "3px",
                        backgroundColor: "#9898a8",
                      },
                      "&::before": {
                        left: "-38px",
                      },
                      "&::after": {
                        right: "-38px",
                      },
                    }}
                  >
                    ?
                  </Typography>
                  <img
                    width={30}
                    src={
                      selectedGrid === index
                        ? "./images/icon_clock-red.webp"
                        : item.imgSrc
                    }
                    alt=""
                  />
                  <Typography
                    style={{
                      color: selectedGrid === index ? "#f54545" : "#6abe57",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CardSecond;
