import { Box, Typography } from "@mui/material";
import FooterNav from "../../FooterNav";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";

const SuperJackpot = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}>
        <Box
          style={{
            height: "8vh",
            display: "flex",
            justifyContent: "space-between",
            width: "60%",
            // background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
            alignItems: "center",
            padding: "0 14px",
            borderBottom: "0.7px solid #ff9a8e",
          }}
        >
          <Box onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                // width: "60px",
                // color: "#fff",
                border: "1px solid #000",
                borderRadius: 50,
              }}
            />
          </Box>
          <Typography
            style={{
              fontSize: "16px",
            }}
          >
            Super Jackpot
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            // alignItems: "center",
            padding: 2,
            height: "25vh",
            gap: 2,
            flexDirection: "column",
            backgroundSize: "cover", // Make the background cover the button area
            backgroundPosition: "center", // Center the image
            backgroundRepeat: "no-repeat",
            backgroundImage:
              "url(./images/superJackpot-53463ffb.png), linear-gradient(94deg, #f99937 2.72%, #ff6922 43.54%, #ff8039 98.54%)",
          }}
        >
          <Typography
            color="#fff"
            style={{
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Super Jackpot
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              style={{ fontSize: "12px", lineHeight: "15px", width: "60%" }}
              color="#fff"
            >
              When you get the Super Jackpot in 【Slots】Can get 1 additional
              bonus
            </Typography>
            <Typography
              style={{ fontSize: "12px", width: "60%", lineHeight: 1 }}
              color="#fff"
            >
              The reward is valid for 1 day, and you will not be able to claim
              it after it expires!
            </Typography>
          </Box>
        </Box>
      </div>
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};

export default SuperJackpot;
