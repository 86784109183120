import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { IconButton, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FooterNav from "../FooterNav";
import Models from "../Wallet/Component/Models";
import axiosInstance from "../../AxiosBaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Promotion = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [valueToCopy, setValueToCopy] = useState("");
  const [valueToLink, setValueToLink] = useState("");

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(valueToLink)
      .then(() => {
        toast("Copy Successful", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
          theme: "colored",
          // transition: Bounce,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(valueToCopy)
      .then(() => {
        toast("Copy Successful", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
          theme: "colored",
          // transition: Bounce,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  useEffect(() => {
    handleOpen();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .post("api/webapi/promotion", {})

        .then((res) => {
          if (res.status === 200) {
            setData(res.data.invite);
            setValueToCopy(res.data.info[0].code);
            setValueToLink(
              `${window.location.origin}/#/register?r_code=${res.data.info[0].code}`
            );
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getMyTeamData = async () => {
    try {
      const response = await axiosInstance
        .get("api/webapi/myTeam")
        .then((res) => {
          if (res.status === 200) {
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getUserData();
    setTimeout(() => {
      getMyTeamData();
    }, 500);
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#f2f2f1" }}>
        <Models handleClose={handleClose} open={open} />

        <Box
          style={{
            height: "8vh",
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">Agency</Typography>
        </Box>
        <Box
          style={{
            height: "40vh",
            background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: 2,
              height: "15vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
            }}
          >
            <Typography
              color="#fff"
              style={{
                // marginTop: "20px",
                fontSize: "32px",
                fontWeight: 600,
                lineHeight: "40px",
              }}
            >
              0
            </Typography>

            <Typography
              variant="body2"
              color="#fff"
              style={{
                border: "1px solid",
                background: "#ff8d8d",
                borderRadius: "15px",
                padding: "0 15px",
              }}
            >
              Total commission
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "12px" }} color="#fff">
                Upgrade the level to increase commission income
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            top: "-9.18667rem",
            display: "flex",
            flexWrap: "wrap",
            minHeight: 160,
            margin: 1.5,
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              // padding: "10px",
              backgroundColor: "#fff",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <Grid
              style={{
                width: "100%",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                borderBottom: "1px solid #ddd",
                display: "flex",
                padding: "10px",
                backgroundColor: "#f6f6f6",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Box
                  onClick={() =>
                    (window.location.href = "/wallet/rechargerecord")
                  }
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    lineHeight: 1,
                  }}
                >
                  <img
                    style={{ width: "25px" }}
                    src="http://91club.bet/assets/png/server-b7c71127.png"
                    alt="Gifts"
                  />
                  <Typography
                    variant="body2"
                    style={{ color: "#666", lineHeight: 0.9 }}
                  >
                    Direct Subordinate
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "50%",
                  borderLeft: "1px solid #bdbdbd",
                }}
              >
                <Box
                  onClick={() =>
                    (window.location.href = "/wallet/rechargerecord")
                  }
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    // flexDirection: "column",
                    // padding: "10px",
                  }}
                >
                  <img
                    style={{ width: "25px" }}
                    src="http://91club.bet/assets/png/server-b7c71127.png"
                    alt="Gifts"
                  />
                  <Typography
                    variant="body2"
                    style={{ color: "#666", lineHeight: 0.9 }}
                  >
                    Team Subordinate
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Box style={{ padding: "10px", paddingTop: 0 }}>
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  // margin: "10px 0",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <Box
                    onClick={() =>
                      (window.location.href = "/wallet/rechargerecord")
                    }
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: "10px",
                      lineHeight: 1,
                    }}
                  >
                    <Typography variant="subtitle1">
                      {data != undefined ? data.f1 : 0}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        lineHeight: 0.5,
                      }}
                    >
                      number of register
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    borderLeft: "1px solid #bdbdbd",
                  }}
                >
                  <Box
                    onClick={() =>
                      (window.location.href = "/wallet/rechargerecord")
                    }
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: "10px",
                    }}
                  >
                    <Typography variant="subtitle1">
                      {data != undefined ? data.total_f : 0}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        lineHeight: 0.5,
                      }}
                    >
                      number of register
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  // margin: "10px 0",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <Box
                    onClick={() =>
                      (window.location.href = "/wallet/rechargerecord")
                    }
                    sx={{
                      marginTop: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: "10px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#ff8616" }}
                    >
                      1
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        lineHeight: 0.5,
                      }}
                    >
                      Deposit amount
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    borderLeft: "1px solid #bdbdbd",
                  }}
                >
                  <Box
                    onClick={() =>
                      (window.location.href = "/wallet/rechargerecord")
                    }
                    sx={{
                      marginTop: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: "10px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#ff8616" }}
                    >
                      0
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        padding: "0 10px",
                        lineHeight: 1,
                      }}
                    >
                      Deposit amount
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  // margin: "10px 0",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <Box
                    onClick={() =>
                      (window.location.href = "/wallet/rechargerecord")
                    }
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: "10px",
                      lineHeight: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#49ce9b" }}
                    >
                      0
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        lineHeight: 0.5,
                      }}
                    >
                      Deposit number
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    borderLeft: "1px solid #bdbdbd",
                  }}
                >
                  <Box
                    onClick={() =>
                      (window.location.href = "/wallet/rechargerecord")
                    }
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: "10px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#49ce9b" }}
                    >
                      0
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        lineHeight: 0.5,
                      }}
                    >
                      Deposit number
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  // margin: "10px 0",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <Box
                    onClick={() =>
                      (window.location.href = "/wallet/rechargerecord")
                    }
                    sx={{
                      marginTop: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: "10px",
                    }}
                  >
                    <Typography variant="subtitle1">1</Typography>
                    <Typography
                      style={{ fontSize: "12px", color: "#666", lineHeight: 1 }}
                    >
                      Number of people making first deposit
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    borderLeft: "1px solid #bdbdbd",
                  }}
                >
                  <Box
                    onClick={() =>
                      (window.location.href = "/wallet/rechargerecord")
                    }
                    sx={{
                      marginTop: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: "10px",
                    }}
                  >
                    <Typography variant="subtitle1">0</Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        padding: "0 10px",
                        lineHeight: 1,
                      }}
                    >
                      Number of people making first deposit
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>

        {/*  Refral link */}
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: "-8rem",
            alignItems: "center",
            flexDirection: "column",
            gap: 40,
          }}
        >
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              type="button"
              onClick={handleCopyLink}
              style={{
                background:
                  "linear-gradient(to bottom right, #FF9A5A, #EF4765)",
                border: 0,
                borderRadius: "100px",
                color: "#FFFFFF",
                cursor: "pointer",
                display: "inline-block",
                fontFamily:
                  '-apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: 2.5,
                outline: "transparent",
                padding: "0 1rem",
                textAlign: "center",
                transition: "box-shadow .2s ease-in-out",
                userSelect: "none",
                webkitUserSelect: "none",
                touchAction: "manipulation",
                whiteSpace: "nowrap",
              }}
            >
              Copy Referral Link
            </Button>
          </Box>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              type="button"
              onClick={handleCopy}
              style={{
                background:
                  "linear-gradient(to bottom right, #FF9A5A, #EF4765)",
                border: 0,
                borderRadius: "100px",
                color: "#FFFFFF",
                cursor: "pointer",
                display: "inline-block",
                fontFamily:
                  '-apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: 2.5,
                outline: "transparent",
                padding: "0 1rem",
                textAlign: "center",
                transition: "box-shadow .2s ease-in-out",
                userSelect: "none",
                webkitUserSelect: "none",
                touchAction: "manipulation",
                whiteSpace: "nowrap",
              }}
            >
              Copy Referral Code
            </Button>
          </Box>
          <ToastContainer />
        </Box>
        {/*  Subordinate */}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            position: "relative",
            top: "-6.18667rem",
          }}
        >
          <Box
            sx={{
              width: "90%",
              margin: "0 20px",
              // position: "relative",
              // top: "-3rem",
              borderRadius: "12px",
              backgroundColor: "#fff",
            }}
          >
            <Box
              onClick={() => handleNavigation("/redenvelopes")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Box
                style={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <img
                  style={{ width: "35px" }}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAbFBMVEX/WmYAAAD/WWj/T2T/W2X/W2f/WWb/WWf/WGf/WGb/WGb/WWb/WWb/WWf/WGX/WGf/WWb/XGX/VGr/WWf/WGf/WWf/WWb/Wmr/WWf/WWf/WWf/WWf/Wmf/XGb/WWj/Wmf/VYD/WWf/WmX/WWcSNvN4AAAAI3RSTlNmAIwMeXlwYLyW44Ev2U5A7yYQ7LCfVx+/OPXfsxnGqAbPR17xxycAAAHhSURBVEjHzdfbkuIgFAVQJkTQkPvNRG27p/f//+PEdAEnhKpAz4v7jVBL2Z6irLA//5F3xHk/tJsHaiqD8R1QdC2Bug3EFYCBPpgAhGIBQNIHNVCHHrsHkNOfAEAfit0vKgGIY8xfmQE8OMkFQMlfIdiVpWSvfAL4ZCQZAMHWVNyPm4Wu+QsgoRhAZhaND3NtWQfcqE0APM1Kcg+u9K4CUBC71hjscvbgUW8OAB7ErjWUXco9/jCbZ7dy5tTY45nijjmY1hDXHR7N5ulZXDZYFUVKlpcdbllwzpWL83CcSRePwVbAxZwF5wLl4CaiMhIHlxGVMWzxF4uojH6Lm5jKmLb4O6Yy7lsszaFOnohtZYrppVA3+PKglZdYTK9yAX9SWhloKR6PsKCVgavB9Cqr7ujYGZbkBM/MJvVEOJVRETxGTXmJtJiz8JxdnEfgbMXK4jJE0cpIDOZ2SyWepLvKGAw2l+Lkn1OXupXRG/xtP9afs1sZk8b2KicH2FTGXeMrPZQvRepWRq1xFT3lJRrL6CkvaX/wR4QV0LkuOOrUdBz5Dx5/UxnlilsWkcJgueL8V5UhonFisVpxE1uZHvtLhuMO7qjy6EHR+zyHNr4ZS/7omvJYiuSpZT3Jt327eWv8D31pUMh1MCbLAAAAAElFTkSuQmCC"
                />
                <Typography style={{ fontSize: "16px" }}>
                  Subordinate data
                </Typography>
              </Box>
              <Box>
                <IconButton>
                  <ArrowForwardIosIcon
                    style={{
                      color: "var(--Lottery4dTextColor-1)",
                      width: "20px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",
              margin: "0 20px",
              // position: "relative",
              // top: "-3rem",
              borderRadius: "12px",
              backgroundColor: "#fff",
            }}
          >
            <Box
              onClick={() => handleNavigation("/redenvelopes")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Box
                style={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <img
                  style={{ width: "35px" }}
                  src="http://91club.bet/assets/png/commission-1a0e795f.png"
                  alt="Gifts"
                />
                <Typography style={{ fontSize: "16px" }}>
                  Commission details
                </Typography>
              </Box>
              <Box>
                <IconButton>
                  <ArrowForwardIosIcon
                    style={{
                      color: "var(--Lottery4dTextColor-1)",
                      width: "20px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",
              margin: "0 20px",
              // position: "relative",
              // top: "-3rem",
              borderRadius: "12px",
              backgroundColor: "#fff",
            }}
          >
            <Box
              onClick={() => handleNavigation("/redenvelopes")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Box
                style={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <img
                  style={{ width: "35px" }}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAVFBMVEX6WloAAAD5WVn6Wlr6WVn7Wlr4Wlr4UFD6WVn7W1v5XFz/W1v/VVX7Wlr6WVn6WVn7W1v6WVn4WVn6Wlr5WVn5WVn5WVn6WVn6W1v5WVn6WVn5WVln1XYhAAAAG3RSTlNmAOwz2U8mDLJfLBsGebxZRsVw9ePGqZtwz4weICORAAABBElEQVRIx+3XyY4CIRAG4Gr2rTd11Jl+//ecGGNASoFqL230P1GHLxBSCQV0L+SLO+2m0QKIJYsAsHJyuoC9hEse4mukfoY5QA0DcISjLeCoMVbQhsFhrMdWbAPCCloxcIRlO5Y5DtCOIWTYU7DPsKJglWFOwXzjeL9fjw/n/rAWizPATqzD83U9r8HDrTB0bGJlaPjIjmlp2ImAcb5487gXLOZnR8PzksbQ8O8dHl44tujf5bY/BxuGUuowldrTkgZ3mCo9sX+ZyTvMFx/3gSXBHRbwWFEPHivo160QDrbVjhrhzhE2jph4cF4ZXOsW407LGpW+PKxL+9jZcXJ6o3+Md8H/Fdgw/IbWcWIAAAAASUVORK5CYII="
                  alt="Gifts"
                />
                <Typography style={{ fontSize: "16px" }}>
                  Invitation rules
                </Typography>
              </Box>
              <Box>
                <IconButton>
                  <ArrowForwardIosIcon
                    style={{
                      color: "var(--Lottery4dTextColor-1)",
                      width: "20px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",
              margin: "0 20px",
              borderRadius: "12px",
              backgroundColor: "#fff",
            }}
          >
            <Box
              onClick={() => handleNavigation("/redenvelopes")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Box
                style={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <img
                  style={{ width: "35px" }}
                  src="http://91club.bet/assets/png/server-b7c71127.png"
                  alt="Gifts"
                />
                <Typography style={{ fontSize: "16px" }}>
                  Agent line customer service
                </Typography>
              </Box>
              <Box>
                <IconButton>
                  <ArrowForwardIosIcon
                    style={{
                      color: "var(--Lottery4dTextColor-1)",
                      width: "20px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",
              margin: "0 20px",
              // position: "relative",
              // top: "-3rem",
              borderRadius: "12px",
              backgroundColor: "#fff",
            }}
          >
            <Box
              onClick={() => handleNavigation("/redenvelopes")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Box
                style={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <img
                  style={{ width: "35px" }}
                  src="http://91club.bet/assets/png/rebateRatio-7a0e4b1b.png"
                />
                <Typography style={{ fontSize: "16px" }}>
                  Rebate ratio
                </Typography>
              </Box>
              <Box>
                <IconButton>
                  <ArrowForwardIosIcon
                    style={{
                      color: "var(--Lottery4dTextColor-1)",
                      width: "20px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            minHeight: 180,
            width: "90%",
            backgroundColor: "#fff",
            margin: " 20px",
            position: "relative",
            top: "-6rem",
            borderRadius: "5px",
          }}
        >
          <Box>
            <Box m={1} mb={1} style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: "25px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABHVBMVEUAAAD8jKX9q779nbL8iqH/+Pv/j5/+wM7/j5f+kaf/jaH7jaX/jp3/v8P+6O3/6e7/3uX/jJj+usX+jqb8jaX9hZv/vcT/i6j9i5z8iqT//P3/9ff/f4z/bn3/jZr+mKj+iZn7iqj/v8//ZHH/vsP/9ff/d4P/fYf+8fT/v8X/sLX+c4H/sLf+dYT/wsb/wMX/mqD/lJ3/j5n/dYf/eYX/oan9hp//w8j/w8b/pq7/e4P/t7//t7//////ZHH/c3//ipT/vcP/pq7/anb/Y3D/bHn/f4r/Xmz/aHX/eoX/YG7/XWr/Wmj/fYj/ZXL/e4b/n6j/trz/sbf/qbD/kZr/jJb/eIP/cX3/rLP/hY//Z3T/oqr/l6D/lZ7/k5ydcTwhAAAAPXRSTlMAZnlwQOyfjJ8yXyYK38bFsp+BWFB5YCwfF/Xiw72yhIFGEPXv4+Pf2c/Pxr+zr5+fn5+fgH9wb29fX0AgTxUa5QAAAbFJREFUSMftlNd6gjAYhkG0bq1719G99y5iCxEEGe7Z3v9llCdiBUILPfDM9yzJ9z7hJ8mPbVgnoUw1EcARAslqMGSVz8CwNYkMmvfjf+I354O4DTmTkLQTkqaCkYDNFll7oRzIOi4ZstM4fFkJbtMq4fUSpqmt5mh0ZxRc2y4tHiFVotoork5DAdCU2yDsk3kcih4S4lls4iGPNEEaFQ2CmoGJCKkRhbpqakIdNLMWAkH+QBgEmqHlgIXgXQleRKj8U2j57D4JFZwXLdIMZxQKixWcWP5WFxzlyQIqaAcXxyGuxcER2kg7OJnjuVbM+dWIDSUoOL98FKMTgvZCEfCsWoPzB3QwFVhO9jl+ohXAt1lavlkKOTvhtC6ognjt9M2d19l2mweTK12jSfwefygxal6QqMa9vnMEE+UUGk6lbku7rKDmeSB+PmN63npfTQpMpboehuFZVhAElgHDwczUAc+UgUgBToKKwZKm9EhuKCeYkdfurD8Rh5QFQ3HSV7ppzES8M1bm/UEDYdCfK71OHENIhzvdce8DoTfudvaeMAtq6Yvj8DtCOHz5WMM2rJFvSI53OT9GTq0AAAAASUVORK5CYII="
              />
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Promotion Data
              </Typography>
            </Box>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                margin: "10px 0",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Box
                  onClick={() =>
                    (window.location.href = "/wallet/rechargerecord")
                  }
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    // padding: "10px",
                  }}
                >
                  <Typography variant="subtitle1">0</Typography>
                  <Typography style={{ fontSize: "12px", color: "#666" }}>
                    This week
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "50%",
                  borderLeft: "1px solid #bdbdbd",
                }}
              >
                <Box
                  onClick={() =>
                    (window.location.href = "/wallet/rechargerecord")
                  }
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    // padding: "10px",
                  }}
                >
                  <Typography variant="subtitle1">0.00</Typography>
                  <Typography
                    style={{ fontSize: "12px", color: "#666", lineHeight: 1 }}
                  >
                    Total commission
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                margin: "10px 0",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Box
                  onClick={() =>
                    (window.location.href = "/wallet/rechargerecord")
                  }
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    // padding: "10px",
                  }}
                >
                  <Typography variant="subtitle1">1</Typography>
                  <Typography style={{ fontSize: "12px", color: "#666" }}>
                    direct subordinate
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "50%",
                  borderLeft: "1px solid #bdbdbd",
                }}
              >
                <Box
                  onClick={() =>
                    (window.location.href = "/wallet/rechargerecord")
                  }
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    // padding: "10px",
                  }}
                >
                  <Typography variant="subtitle1">0</Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#666",
                      padding: "0 10px",
                    }}
                  >
                    Total number of subordinate in the team
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>

        <Box>
          <FooterNav />
        </Box>
      </div>
    </>
  );
};
export default Promotion;
