import { AppBar, Box,} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../../AxiosBaseApi";
import Card from "../Win/Card";
import MainBoxK3 from "./MainBoxK3";
import HistoryCard from "./HistoryCard";

const K3 = () => {
  const navigate = useNavigate();
  const [typeid, setTypeid] = useState(1);
  const [pageno, setPageno] = useState(1);
  const [pageto, setPageto] = useState(10);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState(20222125156);
  const [seconds1, setSeconds1] = useState(0);
  const [seconds2, setSeconds2] = useState(0);
  const [minute, setMinute] = useState(0);
  const [clicked, setClicked] = useState(false); // Assuming there's some way to toggle 'clicked'
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (seconds1 === 0 && seconds2 === 5) {
      setIsDisabled(true);
    }
    if (seconds1 === 5 && seconds2 === 9) {
      setIsDisabled(false);
    }
  }, [seconds2]);
  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .post(
          "api/webapi/k3/GetNoaverageEmerdList",
          {
            gameJoin: typeid,
            pageno: pageno,
            pageto: pageto,
          },
      
        )

        .then((res) => {
          if (res.status === 200) {
            setData(res.data.data.gameslist);

            setPeriod(res.data.period);
            setLoading(false);
          } else {
            alert(res.data.message);
            setLoading(false);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  
  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (seconds1 === 5) {
      getUserData();
    }
  }, [seconds1]);

  useEffect(() => {
    const countDownDate = new Date(
      "2030-07-16T23:59:59.9999999+01:00"
    ).getTime();
    
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const calculatedMinute = Math.ceil(minutes % 10);
      const calculatedSeconds1 = Math.floor((distance % (1000 * 60)) / 10000);
      const calculatedSeconds2 = Math.floor(((distance % (1000 * 60)) / 1000) % 10);

      // Update React state
      setMinute(calculatedMinute);
      setSeconds1(calculatedSeconds1);
      setSeconds2(calculatedSeconds2);
    }, 100);

    

    // Audio logic and UI adjustments
    const audioCheckInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const second1Value = Math.floor((distance % (1000 * 60)) / 10000);
      const second2Value = Math.floor(((distance % (1000 * 60)) / 1000) % 10);

      // Replace playAudio1() and playAudio2() with your React audio functions
      if (second1Value === 0 && second2Value <= 5 && clicked) {
        playAudio1();
      }
      if (second1Value === 5 && second2Value === 9 && clicked) {
        playAudio2();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
      clearInterval(audioCheckInterval);
    };
  }, [clicked]);

  // Assuming playAudio1 and playAudio2 are audio functions
  const playAudio1 = () => {
    console.log("Playing audio 1");
  };

  const playAudio2 = () => {
    console.log("Playing audio 2");
  };
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#efeef4" }}>
        {/* Header */}
        <Box>
          <AppBar
            position="static"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2px 10px",
              height: "3.22667rem",
              boxShadow: "unset",
              backgroundImage: "linear-gradient(90deg, #cd0103, #f64841)",
            }}
          >
          <Link to="/home">
            <Box style={{ textDecoration: "none" }}>
              <ChevronLeftIcon
                fontSize="medium"
                style={{
                  // width: "60px",
                  color: "#fff",
                  border: "2px solid #fff",
                  borderRadius: 50,
                }}
              />
            </Box>
            </Link>
            <img src="./images/h5setting.png" width="100px" alt="" />
            <Box style={{ display: "flex", gap: 4 }}>
              <Box>
                <img src="./images/audio.webp" width="30px" alt="" />
              </Box>
              <Box>
                <img src="./images/volume.webp" width="30px" alt="" />
              </Box>
            </Box>
          </AppBar>
        </Box>

        <Card />
       
        <Box style={{ position: "relative" }}>
          <MainBoxK3 seconds1={seconds1} seconds2={seconds2} typeid={typeid} />
          
        </Box>
        <HistoryCard
          data={data}
          loading={loading}
          typeid={typeid}
          pageno={pageno}
          pageto={pageto}
        />
      </div>
    </>
  );
};

export default K3;
