import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputBase,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Cookies from "js-cookie";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import axiosInstance from "../../AxiosBaseApi";

const Withdrawal = () => {
  const history = useNavigate();
  const [loginStatus, setLoginStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState("lottery");
  const [userInfo, setUserInfo] = useState({
    user: {},
    totalRecharge: 0,
    totalWithdraw: 0,
  });
  const [amount, setAmount] = useState(); // default value

  const handleManualUPIPaymentRequest = async (amount) => {
    window.location.href = `/wallet/paynow/manual_upi?am=${amount}`;
  };

  const handleAmountClick = (value) => {
    setAmount(value);
  };

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .get("api/webapi/GetUserInfo")

        .then((res) => {
          if (res.status === 200) {
            setUserInfo({
              user: res.data.data,
              totalRecharge: res.data.totalRecharge,
              totalWithdraw: res.data.totalWithdraw,
            });
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    const token = Cookies.get("auth");

    if (!token) {
      setLoginStatus(false);
      history("/login");
    } else {
      setLoginStatus(true);
      getUserData();
    }
  }, [loginStatus]);

  const handleTabClick = (tabId) => {
    setSelectedTab(tabId);
  };

  return (
    <>
      <div style={{ width: "100%", background: "#F7F8FF" }}>
        <Box sx={{ marginBottom: "5px" }}>
          <AppBar
            position="static"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2px 10px",
              height: "3.22667rem",
              boxShadow: "unset",
              backgroundColor: "#fff",
            }}
          >
            <Box onClick={() => history(-1)} style={{ textDecoration: "none" }}>
              <ChevronLeftIcon
                fontSize="medium"
                style={{
                  // width: "60px",
                  color: "#000",
                  border: "1px solid #f2f3f5",
                  borderRadius: 50,
                }}
              />
            </Box>
            <Typography style={{ color: "#000", fontSize: "24px" }}>
              Withdrawal
            </Typography>

            <Box style={{ display: "flex" }}>
              <Link
                to="/wallet/withdrawalrecord"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  style={{
                    borderColor: "#fff",
                    color: "#000",
                    fontSize: "12px",
                  }}
                >
                  Withdrawal History
                </Typography>
              </Link>
            </Box>
          </AppBar>
        </Box>

        {/*Slider   */}
        <Grid
          container
          direction="column"
          sx={{ maxHeight: { xs: 190, sm: 250, md: 270, lg: 340 } }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            style={{
              width: "95%",
              marginTop: "5px",
              backgroundImage: "url(./images/bgimgrec.png)",
              minHeight: "150px",
              backgroundSize: "contain", // Makes sure the image scales without being distorted
              backgroundRepeat: "no-repeat", // Prevents image repetition
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 20,
            }}
          >
            <Box style={{ display: "flex", gap: 6, alignItems: "center" }}>
              <img
                // width="100%"
                height="20px"
                style={{ borderRadius: "10px" }}
                src="./images/balance.png"
              />
              <Typography style={{ fontSize: "16px", color: "#fff" }}>
                Balance
              </Typography>
            </Box>
            <Box style={{ display: "flex", gap: 6, alignItems: "center" }}>
              <Typography style={{ fontSize: "32px", color: "#fff" }}>
                ₹{userInfo.user.money_user}.00
              </Typography>
              <img width={20} height={15} src="./images/load.png" />
            </Box>
          </Grid>
        </Grid>

        <div style={{ display: "flex", margin: "10px" }}>
          <div
            role="tablist"
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {[
              {
                id: "lottery",
                name: "Innate UPI-QR",
                imgSrc: "./images/wall.png",
              },
              {
                id: "flash",
                name: "Expert PAytm-QR",
                imgSrc: "./images/wallt.png",
              },
            ].map((tab) => (
              <div
                key={tab.id}
                role="tab"
                tabIndex="0"
                aria-selected={selectedTab === tab.id}
                onClick={() => handleTabClick(tab.id)}
              >
                <div
                  style={{
                    minWidth: "7rem",
                    height: "6rem",
                    padding: "2px",
                    margin: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundSize: "100% 100%",
                    marginBottom: "1.17333rem",
                    borderRadius: 10,
                    backgroundColor:
                      selectedTab === tab.id ? "transparent" : "#fff", // Apply green color if active
                    backgroundImage:
                      selectedTab === tab.id
                        ? "url(https://91club.bet/assets/png/bgActive-805fae0e.png)" // No background image if active
                        : "none", // Keep image for inactive
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundSize: "100% 100%",
                      backgroundImage: `url(${tab.imgSrc})`,
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: selectedTab === tab.id ? "#fff" : "#000",
                    }}
                  >
                    {tab.name}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Box p={2} bgcolor="#f5f5f5" borderRadius={2}>
          <NavLink
            style={{ textDecoration: "none" }}
            to={`/wallet/withdraw/addbankcard`}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                padding: "10px 20px",
                minHeight: 130,
                backgroundColor: "#fff",
                display: "block",
                "&:hover": {
                  background: "#fff", // Keep the same gradient on hover
                },
              }}
            >
              <img
                height="50px"
                style={{ borderRadius: "10px" }}
                src="./images/walladd.png"
              />
              <Typography variant="body1">Add a bank account number</Typography>
            </Button>
          </NavLink>

          {/* </Box> */}

          <Typography
            style={{ fontSize: "12px", padding: 16, color: "#FF4A4A" }}
          >
            Need to add beneficiary information to be able to withdraw money
          </Typography>

          {/* Input Section */}

          <Box sx={{ backgroundColor: "#fff", padding: 2, borderRadius: 2 }}>
            <Box display="flex" alignItems="center">
              <InputBase
                value={amount}
                onChange={handleInputChange}
                placeholder="Please enter the amount"
                inputProps={{ type: "tel", inputMode: "numeric" }}
                startAdornment={
                  <InputAdornment
                    position="start"
                    size="large"
                    style={{ color: "red" }}
                  >
                    ₹
                  </InputAdornment>
                }
                sx={{
                  flex: 1,
                  backgroundColor: "#E1E1E1",
                  padding: "5px 10px",
                  borderRadius: "24px",
                  color: "#FF4A4A",
                }}
              />
            </Box>
            {/* Withdrawable balance section */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={1}
            >
              <Typography style={{ fontSize: "12px" }}>
                Withdrawable balance{" "}
                <span variant="body1" style={{ color: "yellow" }}>
                  ₹0.00
                </span>
              </Typography>

              <Box display="flex" alignItems="center">
                <Button variant="outlined" sx={{ ml: 2, p: 0 }} size="small">
                  All
                </Button>
              </Box>
            </Box>

            {/* Withdrawal amount received section */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ fontSize: "12px" }}>
                Withdrawal amount received
              </Typography>
              <Typography variant="body1" sx={{ color: "yellow" }}>
                ₹0.00
              </Typography>
            </Box>
            <Box mt={2} textAlign="center">
              <NavLink
                style={{ textDecoration: "none" }}
                to={`/wallet/paynow/manual_upi?am=${amount}`}
              >
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    color: "#fff",
                    borderRadius: "20px",
                    padding: "5px 40px",
                    backgroundColor:
                      "linear-gradient(180deg, #f95959 0%, #ff9a8e 100%)",
                    "&:hover": {
                      background:
                        "linear-gradient(180deg, #f95959 0%, #ff9a8e 100%)", // Keep the same gradient on hover
                      color: "#fff", // Ensure the text color remains white on hover
                    },
                  }}
                >
                  Withdrawal
                </Button>
              </NavLink>
            </Box>
            <Box
              mt={2}
              p={2}
              sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
            >
              <Typography style={{ fontSize: "13px" }} color="#768096">
                * Need to bet <span style={{ color: "red" }}>₹28.00</span> to be
                able to withdraw
              </Typography>
              <Typography style={{ fontSize: "13px" }} color="#768096">
                * Withdraw time{" "}
                <span style={{ color: "red" }}>00:05-23:55</span>
              </Typography>
              <Typography style={{ fontSize: "13px" }} color="#768096">
                * Inday Remaining Withdrawal Times{" "}
                <span style={{ color: "red" }}>3</span>
              </Typography>
              <Typography style={{ fontSize: "13px" }} color="#768096">
                * Withdrawal amount range{" "}
                <span style={{ color: "red" }}>₹110.00-₹200,000.00</span>
              </Typography>
              <Typography style={{ fontSize: "13px" }} mt={2} color="#768096">
                * Please confirm your beneficial account information before
                withdrawing. If your information is incorrect, our company will
                not be liable for the amount of loss.
              </Typography>
              <Typography style={{ fontSize: "13px" }} color="#768096">
                * If your beneficial information is incorrect, please contact
                customer service.
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Withdrawal;
