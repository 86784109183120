import { Avatar, Box, Typography } from "@mui/material";
import CardBox from "./CardBox";
import CardSecond from "./CardSecond";

const Card = ({ link1, link2, link3, link4 }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          // alignItems: "center",
          padding: 2,
          height: "250px",
          borderRadius: "0 0 3rem 3rem",
          background: "url(./images/bg_top_red.webp) no-repeat 0 0",
          backgroundImage: "linear-gradient(90deg, #f54545, #fe6868)",
        }}
      >
        <CardBox />
      </Box>
      <CardSecond link1={link1} link2={link2} link3={link3} link4={link4} />
    </>
  );
};
export default Card;
