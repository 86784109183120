import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, useNavigate } from "react-router-dom";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import axiosInstance from "../../AxiosBaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles({
  formContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  phoneInputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  //   inputField: {
  //     marginBottom: "20px",
  //   },
  icon: {
    marginRight: "8px",
  },
  actionButtons: {
    display: "flex",
    gap: "10px",
  },
  footer: {
    marginTop: "20px",
    textAlign: "center",
  },
});
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function unsetCookie() {
  setCookie("token", "", 0);
  setCookie("auth", "", 0);
}
const AddBankCard = () => {
  const classes = useStyles();
  const [data, setData] = useState({
    name_bank: "",
    name_user: "",
    stk: "",
    tp: "",
    email: "",
    sdt: "",
    tinh: "",
    chi_nhanh: "",
  });
  const [countryCode, setCountryCode] = useState("+91"); // Default country code
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const history = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const datas = new FormData();
    datas.append("name_bank", data.name_bank);
    datas.append("name_user", data.name_user);
    datas.append("stk", data.stk);
    datas.append("tp", data.tp);
    datas.append("email", data.email);
    datas.append("sdt", data.sdt);
    datas.append("tinh", data.tinh);
    datas.append("chi_nhanh", data.chi_nhanh);

    try {
      const response = await axiosInstance
        .post("api/webapi/addBank", {
          name_bank: data.name_bank,
          name_user: data.name_user,
          stk: data.stk,
          tp: data.tp,
          email: data.email,
          sdt: data.sdt,
          tinh: data.tinh,
          chi_nhanh: data.chi_nhanh,
        })

        .then((res) => {
          if (res.status === 200) {
            toast(res.data.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: 0,
              theme: "colored",
              // transition: Bounce,
            });
            // setTimeout(() => {
            //   setOpen(false);
            //   setButtonValue(10);
            //   setActiveButton(0);
            // }, 2000);
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}>
        <Box
          style={{
            height: "8vh",
            display: "flex",
            // justifyContent: "center",
            background: "#fff",
            alignItems: "center",
            padding: "0 14px",
          }}
        >
          <Link to="/wallet/withdrawal" style={{ textDecoration: "none" }}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                color: "#000",
              }}
            />
          </Link>
          <Typography
            variant="subtitle2"
            style={{ color: "#000", marginLeft: 50 }}
          >
            Add a Bank account number
          </Typography>
        </Box>
        <Box
          style={{
            backgroundColor: "#fff",
            borderRadius: "50px",
            gap: 2,
            display: "flex",
            justifyContent: "center",
            padding: "5px",
          }}
          m={1}
        >
          <img
            style={{ padding: "5px" }}
            width={30}
            src="./images/not.png"
            alt="not"
          />
          <Typography style={{ color: "#000", fontSize: "13px" }}>
            To ensure the safety of your funds, please bind your bank account
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit}>
          <div className={classes.formContainer}>
            {/* Phone Input */}

            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <AccountBalanceIcon
                    style={{ marginRight: 5, color: "#FF6C6B " }}
                  />
                  <Typography variant="subtitle1" style={{ color: "#768096" }}>
                    Bank Name
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  onChange={handleChange}
                  name="name_bank"
                  type="text"
                  id="name_bank"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Removes the border
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                      WebkitTextFillColor: "#000", // Sets text color to black
                    },
                  }}
                  size="small"
                  placeholder="Enter Bank name"
                />
              </div>
            </div>

            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <PersonIcon style={{ marginRight: 5, color: "#FF6C6B " }} />
                  <Typography variant="subtitle1" style={{ color: "#768096" }}>
                    Full recipient's name
                  </Typography>
                </Box>
                {/* <Box
                  style={{
               
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                  }}
                > */}

                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Removes the border
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                      WebkitTextFillColor: "#000", // Sets text color to black
                    },
                  }}
                  name="name_user"
                  id="name_user"
                  onChange={handleChange}
                  size="small"
                  placeholder="Please enter recipient's name"
                />
                {/* </Box> */}
              </div>
            </div>
            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <PaymentIcon style={{ marginRight: 5, color: "#FF6C6B " }} />
                  <Typography variant="subtitle1" style={{ color: "#768096" }}>
                    Bank account number
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  onChange={handleChange}
                  name="stk"
                  type="text"
                  id="stk"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Removes the border
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                      WebkitTextFillColor: "#000", // Sets text color to black
                    },
                  }}
                  size="small"
                  placeholder="Enter Bank account number"
                />
              </div>
            </div>

            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <img
                    className={classes.icon}
                    width={25}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAM1BMVEUAAAD/cXH/cXH/cXL/cHL/cHL/cHD/cXH/cHL/cXH/cXH/c3P/cXL/cHD/eXn/cnL/cXLCBnslAAAAEHRSTlMAmXO/YJ9ATo9/Vh7fMBOvdmI8dQAAAHdJREFUSMft0UkOgCAQRNEGZB70/qd1o9F0hMKVmPCXpN6GpqGLQVYKhR6yopFFeyyKAEUGAgKBAYmAnGCCCf4EXmcXkGFAbSD1KVD6aO0D+nrVXUDertMFkjmzaZRf6gIOAcdARiASy7f3nggLvudFpyq5TCO3A483HtOoE/1VAAAAAElFTkSuQmCC"
                    alt="Country code"
                  />
                  <Typography variant="subtitle1" style={{ color: "#768096" }}>
                    City
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Removes the border
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                      WebkitTextFillColor: "#000", // Sets text color to black
                    },
                  }}
                  name="tp"
                  id="tp"
                  onChange={handleChange}
                  size="small"
                  placeholder="Please enter City"
                />
              </div>
            </div>

            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <img
                    className={classes.icon}
                    width={25}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAM1BMVEUAAAD/cXH/cXH/cXL/cHL/cHL/cHD/cXH/cHL/cXH/cXH/c3P/cXL/cHD/eXn/cnL/cXLCBnslAAAAEHRSTlMAmXO/YJ9ATo9/Vh7fMBOvdmI8dQAAAHdJREFUSMft0UkOgCAQRNEGZB70/qd1o9F0hMKVmPCXpN6GpqGLQVYKhR6yopFFeyyKAEUGAgKBAYmAnGCCCf4EXmcXkGFAbSD1KVD6aO0D+nrVXUDertMFkjmzaZRf6gIOAcdARiASy7f3nggLvudFpyq5TCO3A483HtOoE/1VAAAAAElFTkSuQmCC"
                    alt="Country code"
                  />
                  <Typography variant="subtitle1" style={{ color: "#768096" }}>
                    Ifsc Code
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Removes the border
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                      WebkitTextFillColor: "#000", // Sets text color to black
                    },
                  }}
                  name="email"
                  id="email"
                  onChange={handleChange}
                  size="small"
                  placeholder="Please enter City"
                />
              </div>
            </div>
            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <KeyIcon style={{ marginRight: 5, color: "#FF6C6B " }} />
                  <Typography variant="subtitle1" style={{ color: "#768096" }}>
                    UPI Id
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  onChange={handleChange}
                  name="sdt"
                  type="text"
                  id="sdt"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Removes the border
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                      WebkitTextFillColor: "#000", // Sets text color to black
                    },
                  }}
                  size="small"
                  placeholder="Enter UPI ID"
                />
              </div>
            </div>
            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <KeyIcon style={{ marginRight: 5, color: "#FF6C6B " }} />
                  <Typography variant="subtitle1" style={{ color: "#768096" }}>
                    Phone Number
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  onChange={handleChange}
                  name="tinh"
                  type="text"
                  id="tinh"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Removes the border
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                      WebkitTextFillColor: "#000", // Sets text color to black
                    },
                  }}
                  size="small"
                  placeholder="Enter Phone number"
                />
              </div>
            </div>
            <div className={classes.phoneInputContainer}>
              <div className={classes.inputField}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <KeyIcon style={{ marginRight: 5, color: "#FF6C6B " }} />
                  <Typography variant="subtitle1" style={{ color: "#768096" }}>
                    Bank Branch Address
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  onChange={handleChange}
                  name="chi_nhanh"
                  type="text"
                  id="chi_nhanh"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Removes the border
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Forces white background on autofill
                      WebkitTextFillColor: "#000", // Sets text color to black
                    },
                  }}
                  size="small"
                  placeholder="Enter bank branch address"
                />
              </div>
            </div>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",

                alignItems: "center",
                flexDirection: "column",
                gap: 10,
                marginTop: 50,
              }}
            >
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    background:
                      "linear-gradient(90deg, #CCCEDC 15.38%, #CDCFDD 98.73%)",
                    border: 0,
                    borderRadius: "100px",
                    color: "#FFFFFF",
                    cursor: "pointer",
                    display: "inline-block",
                    fontFamily:
                      '-apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: 2.5,
                    minWidth: "450%",
                    padding: "0 1rem",
                  }}
                >
                  Save
                </Button>
                <ToastContainer />
              </Box>
            </Box>
          </div>
        </Box>
      </div>
    </>
  );
};

export default AddBankCard;
