import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Box, Paper, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TornadoIcon from "@mui/icons-material/Tornado";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const FooterNav = () => {
  const [value, setValue] = useState("/");
  const navigate = useNavigate();

  const handleNavigation = (newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const getColor = (actionValue) => {
    return value === actionValue ? "#f95959" : "rgb(255, 205, 203)";
  };

  return (
    <Paper
      style={{
        position: "fixed",
        width: "25rem",
        bottom: 0,
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => handleNavigation(newValue)}
        style={{
          display: "flex",
          background: "url(./images/tttt.png) no-repeat center center / cover",
        }}
      >
        <BottomNavigationAction
          value="/"
          style={{ borderRadius: "6px", color: getColor("/") }}
          label="Home"
          icon={<HomeIcon sx={{ fontSize: 25 }} />}
          sx={{ "& .MuiBottomNavigationAction-label": { fontSize: 14 } }}
        />

        <BottomNavigationAction
          value="/activity"
          style={{ borderRadius: "6px", color: getColor("/activity") }}
          label="Activity"
          icon={<EventAvailableIcon sx={{ fontSize: 25 }} />}
          sx={{ "& .MuiBottomNavigationAction-label": { fontSize: 14 } }}
        />
        <Link to="/promotion" style={{ textDecoration: "none" }}>
          <Box
            style={{
              background:
                "linear-gradient(180deg, #ff867a 0%, #f74747 100%), linear-gradient(0deg, #fff, #fff)",
              position: "absolute",
              left: "42.6%",
              bottom: "1.53333rem",
              width: "3.46667rem",
              height: "3.46667rem",
              borderRadius: "50%",
              boxShadow: "0 -.08rem .16rem #d0d0ed80",
              border: "2px solid #fff",
            }}
          ></Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BottomNavigationAction
              value="/promotion"
              style={{
                borderRadius: "100%",
                top: "-9px",
                zIndex: 2,
                color: "#fff",
              }}
              icon={<TornadoIcon />}
            />
            <Typography
              style={{ color: "rgb(255, 205, 203)", marginTop: "10px" }}
            >
              Promotion
            </Typography>
          </Box>
        </Link>

        <BottomNavigationAction
          value="/wallet"
          style={{ borderRadius: "6px", color: getColor("/wallet") }}
          label="Wallet"
          icon={<AccountBalanceWalletIcon sx={{ fontSize: 25 }} />}
          sx={{ "& .MuiBottomNavigationAction-label": { fontSize: 14 } }}
        />

        <BottomNavigationAction
          value="/mian"
          style={{ borderRadius: "6px", color: getColor("/mian") }}
          label="Account"
          icon={<PersonIcon sx={{ fontSize: 25 }} />}
          sx={{ "& .MuiBottomNavigationAction-label": { fontSize: 14 } }}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default FooterNav;
