import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: true,
};

const runMyBetApi = createSlice({
  name: "runMyBet",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    toggleOpen: (state) => {
      state.open = !state.open;
    },
  },
});

export const { setOpen, toggleOpen } = runMyBetApi.actions;

export default runMyBetApi.reducer;
