import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Avatar } from "@mui/material";
import { IconButton, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FooterNav from "../FooterNav";
import { unsetCookie } from "../Login";
import Models from "../Wallet/Component/Models";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../redux/slices/userInfo";
import { setOpen, toggleOpen } from "../../redux/slices/alertOpen";
import Cookies from "js-cookie";

const My = () => {
  const userInfo = useSelector((state) => state.userdata);
  const openalert = useSelector((state) => state.openAlert);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(setOpen(true)); // Sets state to true
  };

  const handleClose = () => dispatch(toggleOpen());

  useEffect(() => {
    const token = Cookies.get("auth");
    if (!token) {
      navigate("/login");
      return;
    }
    dispatch(fetchUserData({ extra: { navigate } }));
    handleOpen();
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const logOut = () => {
    unsetCookie();
    navigate("/login");
  };
  return (
    <>
      <Models handleClose={handleClose} open={openalert} />
      <Box
        sx={{
          display: "flex",
          // alignItems: "center",
          padding: 2,
          height: "25vh",
          borderRadius: "0 0 2rem 2rem",
          background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
        }}
      >
        <Avatar
          alt="Avatar"
          src="./images/imgmy.png"
          sx={{ width: 80, height: 80, marginRight: 2 }}
        />
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" color="#fff">
              {userInfo.user !== null && userInfo.user.name_user}
            </Typography>
            <img
              src="./images/vip.png"
              alt="UID Icon"
              style={{ marginLeft: 4, width: 50, height: 30 }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: "10px" }} color="#fff">
              UID: {userInfo.user !== null && userInfo.user.id_user}
            </Typography>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAABlZWVnZ2dlZWVmZmZgYGBlZWVkZGRgYGBmZmZoaGhmZmZmZmZlZWVkZGRmZmZwcHBmZmYn3H9lAAAAEXRSTlMAYJ/v3xC/QCCAIK+QcFDPEFwncHAAAADVSURBVFjD7dY7EsMgDEVRia//ydv/ZhOlyYxHChq7SaHTAhdsGiio6sEjvZJpanDIs7k+w+dhHKLASz8Dy1BLA6tsk9UjbAASDVX50E6KBIBJoc2LwGnesSVRft0iT2ZgKvDIux6Q9U67HljhlasWmGQo8cie5QhaoMufo7FZ9tEC/BkY4whEIAIRiMB/BhqAfinQWCS8LZcCXyvdC5TlVkDWXwuUJLaDxNVbEBE46crb23ocbubbu6xpoMF8hc7wKqSqD/jkhQxzhkNbyPTsPHI8KWheb7c1z9Bcc9kAAAAASUVORK5CYII="
              alt="UID Icon"
              style={{ marginLeft: 4, width: 20, height: 20 }}
            />
          </Box>
          <Typography variant="subtitle1" color="#fff">
            Last login: 2024-05-11 17:33:36
          </Typography>
        </Box>
      </Box>

      <Box
        // elevation={1}
        sx={{
          position: "relative",
          top: "-5.18667rem",
          display: "flex",
          flexWrap: "wrap",
          minHeight: 160,
          margin: 1.5,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#fff",
            width: "100%",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #ddd", paddingBottom: "11px" }}>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2">Total balance</Typography>
                {/* You can replace the image icon with MUI Icon component */}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" sx={{ color: "#000" }}>
                  {userInfo.user !== null && userInfo.user.money_user}.00
                </Typography>
                <IconButton onClick={() => handleNavigation("/mian")}>
                  <img
                    width={30}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAOVBMVEUAAACjo6OgoKCjo6OhoaGjo6Ofn5+np6ejo6Ojo6OlpaWioqKkpKSjo6Ojo6Ojo6Ojo6OkpKSjo6PuE3LfAAAAEnRSTlMAf0C/H98QIM/vMGCfj29Qr1+b1SuLAAAA4UlEQVQ4y+3Sy3aEIBBF0eJR8hCx+/7/x8amdCVCZYUMetZnpm4BBfr05hbblX+lEX2pBJ1uUDKTK1ojUGgyA2z/sHmcbVetG4YNDHBQaEXqxjykbj3gbzceSIdUbUcN8ARyQlz+oBUwHqC7dTF8000ehISNXpQsflh5T6gBrHw8L0JpBR6nlDmE7ucOZ7CnRsVe9AVio4GvAexCjcqFPaG8Vw6a27RXQrvEAg7sh189VNHa7/sFWP24AEY5F0qri3VcVwo0GYNnbU7g56xlgIvp0mfyBWNMer4W11Xp03v7Att9D7xKb3uAAAAAAElFTkSuQmCC"
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Grid
            container
            fullWidth
            sx={{
              display: "flex",
              paddingTop: "15px",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <Grid item xs={3} onClick={() => handleNavigation("/wallet")}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <img
                  data-v-7d799898=""
                  width={30}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAeFBMVEUAAAD/oKD/oKD/oaH/n5//n5//oKD/oaH/n5//n5//oKD/n5//oKD/n5/+i4v/n5/+iIj/n5//oaH/n5//n5//oaH/n5/5WVn9hYX6a2v7c3P////8i4v7eXn6YmL5XV3+lpb+j4/+9fX+6ur+m5v8trb7f3/6Zmaz6OagAAAAFnRSTlMA359fIBBvP++vjzC/f9/Pv49/YFBPchRNZwAAANhJREFUSMft1bkSgjAUheEEw467XoQQdvX931CLFBdDSFJYMMNfn2+YYQlkyz7/mnjaLqmyv0WwmMem+wBMUR/vWQTGzhgkYBG+RGYDdgjQtYCxEiXq5AVsCYyiySc9AGiqB20tdxgAxUDdqwBCHSjzebDTgCp3AfgCtSWQu6rnTzsgNwPn/OUC+i+orIB8ZsD5kFuBQo6EqP90W+HtCrrG7dXAAoPI1wJoixkQLH5AnShwhywOydoOgZ/urkclQyAEczHB7cEUZcRJUOUfxLwj1RYHPtly6gOS6nMolZZu1QAAAABJRU5ErkJggg=="
                  alt=""
                />
                <Typography>Wallet</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleNavigation("/wallet/recharge")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <img
                  data-v-7d799898=""
                  width={30}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAjVBMVEUAAAD3eAD3dwD4dgD5dwD2dgD4dgD0bwD4eQD4dwD4dwD4dwD7dwD5dwD2dgD5dgD4dwDtcQD5dgD2eAD5dwD4dwD4dwD4dwD4dwD4eAD3eAD4dgD/dAD3dQDqbgD5dwD3dgD4dwD5eQD5dwD5dwD4dwD5dwD6dgD4dwD4dgD6eQD7dgD4eAD2dAD4dwAMGSgcAAAALnRSTlMAZsyZxU2mEkxrQN9yn3OMcg19OfPqv6+UWTImHxoI0YZULfiE2HlfuI9fQnM4FNyjywAAAtFJREFUaN7s1u9ymkAUBfDdPYBDKAERkdZ/WFF0THn/x2sTSy5JENiF64eMvxc4c+7smVnx8PDw8O1N7chJlEqcQP6airuZykTV4Oda3IVdxRLsJoKdVA32sf9b8HpWDbzyn20hGEnVJC7feP5KMLFVk235zn8SHNaJajIva7yCoXbQUpjE/or30PS0vhj5jS9Uo33ZwF/dvTBFMxeOyxu8grXwobztPGEsPC9beCuGwrQl3mRH52mR7cBrb/4ojS3VnYW5pR3ipFmYPAlDsxCArzS3RBLD2AyvFi1b4qi8DnFlaW+JqEj7gV1Q0d8SmQO23ksOUUn0C5M9AFej9CRDxTfZEnEA5BODXNMtUTAla+XCMtsSBVOyRu6wLVEw8o3oFoIYPi2C/0LRyUaNY7glmlPl0nlo1J0GFvZQ6fwYRCCmWyIW0PPYM9QtzLZEFiBL0SZDnTWwcIyacHDhQ9nXAehZ+QfG2xJdurvyBF+elnXynU+sHraHuLo02fS89Mlyghdp7JimKT649Lt0cHTlUMeg361BqOswL7XWZ3HDEpU0kmS81uuu787ZlSNy30vPRLMdrnJXjivC1U40c/GGzjx2ctH6qHPJIMer57bgQLIIuoJTV7Io0o7gSDIJ24MzySZqDXYlGzdtCc4ko+PfdukYx2EQCsLwmwI50ESsKyAgmWJX8v0PuE2aFOPYjqeJ+C7wa6TZCHsIeR6+Q+pOw7+Q+qFhDynPwh5inoT/ILaS8AKxhYQdXn1P+DbCI/w0wiM8wiO8OxxAqMMzxAIJd4jNJFwg1kl4gtiDhBvEJiMStIwJkLoZ0yE1GxMhVY1yEErGZQgF4xqEJiPIr8Wf1t+r2qYMkWDbWoJEivbGBIlib3UIZNshi7tcFne5mnChtdtuMeAyLtoR0eESqdhRMayfr612yiMsOG11vdl5rZYc3HJkfEouzCXaMAzDt/kHbN7wdhAVLO0AAAAASUVORK5CYII="
                  alt=""
                />
                <Typography>Deposit</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleNavigation("/wallet/withdrawal")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <img
                  data-v-7d799898=""
                  width={30}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
                  alt=""
                />
                <Typography>Withdraw</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <img
                  data-v-7d799898=""
                  width={30}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
                  alt=""
                />
                <Typography>VIP</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid
        style={{
          width: "90%",
          position: "relative",
          top: "-4.18667rem",
          marginLeft: "20px",
          marginRight: "20px",
          display: "flex",
          justifyContent: "space-between",
          gap: 8,
        }}
      >
        <Box
          onClick={() => handleNavigation("/wallet/rechargerecord")}
          sx={{
            cursor: "pointer",
            display: "flex",
            minHeight: 50,
            width: "100%",
            backgroundColor: "#fff",
            // flexDirection: 'column',
            alignItems: "center",
            padding: "10px",
            borderRadius: "6px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            // transition: "transform 0.3s",
            // "&:hover": {
            //   transform: "scale(1.05)",
            // },
          }}
        >
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAMAAAC8EZcfAAAAbFBMVEX6WloAAAD5WFj7WFj6WVn/W1v7Wlr6Wlr7W1v6Wlr6XFz6Wlr5WFj8W1v6WVn6WVn5WVn/VVX7W1v+XV36WVn4WFj6W1v5WVn5WVn7Wlr6W1v8XFz7WVn9XV3/SUn6WVn6Wlr/XFz4WVn5WVlMptElAAAAI3RSTlNmAJ9AxQxNWXkzJRn1X9eOsgZGE+u8cOzigl9ZOSwHqZ8ycDPAW1MAAAL6SURBVHja7dzbbuMgFIXh5WBjAz47SZNOjvX7v+NI7QVSpwkQbGBm9n8bX3zCiVEks5ElHgEJ6BgBk4uABHSMgMnlBiwmeRbsM2ycAvtMCKmGTLcgcFAn/g7dZnZqA907P6mFgYPUOC+gbpTLAYe71nkDdSUrfIGatyhQxwp/4FnzlgeilJ7AYQTWBAJs8AEWJdYGoiwMQINvXaBZCINvZaBZCINvbaAWugM5QgHBXwEKhAPi7A4sEBLYFM5AFhQI7gpUCAuEcgSOoYHcDVggNBCTE5CFB96dgGV4YOMCVAgPhHIAihjAuwOQxwByByBiAJvBGjhFAWKyBso4QGkNFHGAJ2vgGAfIrIE8DpCnDiytgWXqQMQBgoAEJCABCbg2sO73+1udLnB7BIDqLVXgtgK+hGkCv3xfwhSBh0p/WB3SA7Z7QPdxSA2Y41t5WsAd/qhPCdjjh/p0gD1+rE8FeMWDrkkA6xsedksAWB/xpGMdDdjp7fdxelPpggNx0NubWbhFeGDVznNb2V4XAQjs97YXAhGALhHw5whIQAIaIiABCWiIgAQkoKG/Gdjt8mfturjAqp1NHaqYwMNsro0I7GabunjAnRVwFw+YWwFzWkHP72AVD4jewtcjIhDXNwOvvsbe6rrNs7o9QHsxQEACEtAQAQlIQEMEJCABn9a19fxqdV55AUvv/x1m4nHlN9E3s2f1x7rv8rezb1cPIIOx2bsc3xutgScYq1cACmugDHGLbx5HhiYY63x928rj0NXQRHnMLHvwD7d2XvZBzR2AAhESDkCFCCkzUNcgeGX2AJjKPWZOwAnBK2yAOo7AjdlDYBo/E/UQmMYSMvdRGg1CVtgCdWcE7J49ASZwk/lrA3F+IUDmgTjxhYaRQtGFpqFMsYTa5zUYjGHlxovnaDW56iI2Z//hdAXDWjXikvkANVGvYkieBpqT49I6Li8Lz8BUJ94shTsprTMCXbooKQTzSJzlVGSf/Z9jTs0RMLkISEDHCJhcBPzngb8BncLoIXGJNNsAAAAASUVORK5CYII="
            alt="Deposit Icon"
            style={{ width: "40px" }}
          />
          <Box>
            <Typography variant="subtitle1">Deposit</Typography>
            <Typography style={{ fontSize: "12px" }}>
              My deposit history
            </Typography>
          </Box>
        </Box>

        <Box
          onClick={() => handleNavigation("/wallet/withdrawalrecord")}
          sx={{
            cursor: "pointer",
            display: "flex",
            minHeight: 50,
            backgroundColor: "#fff",
            width: "100%",
            // flexDirection: 'column',
            alignItems: "center",
            padding: "10px",
            borderRadius: "6px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            // transition: "transform 0.3s",
            // "&:hover": {
            //   transform: "scale(1.05)",
            // },
          }}
        >
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAbFBMVEUAAAD/iRv/ihz/hhr/iBr/iRv/iB3/iRv/ihv/iRz/hR3/iRz/iRr/hRT/iR3/ihv/iRr/hxn/iRv/iRv/gCr/iR3/iRz/hxv/iRz/iRz/iBz/ihz/ixv/hRH/iRz/ih3/iBv/iRv/kiT/iR3fckNCAAAAI3RSTlMAZp8mH1/gb1iPEH9NDPU+MBmxeQbvz0Dsxb6oORLZv5aCBztI/qIAAAHtSURBVFjD7djJkoIwEIDhJISwyiqiuIxOv/87ToFV0wQZk9A5zMH/ZFn6VbNEAfbp02+yDMvKn5Z0MNXkPrg0AUxJ+ngKtELPHop0D0W6RxfRM4t0D0W6hyLd08s9e9BJuqc3ePKw0rMHiWcPlAM4gE2ppbaX+VlYdC7rvYVW5dyhONgZuJC7Vr0Z8xrzDcV/DhnwjQXrnuTcq7gLt4Ph2lbHnFBu3IH0jY5pYLz0ak6sXoAlFbwYtpi6zXtObq8vOjp4pR8Tvdqw7IhnYvBPwOgufrvQwfYGs0Iy+CjAK9gW4Bc8gV8wAs9g4hsUvsGHBRjdT8cCQB1EawajwgRGzewjJzPZGMDHEbQa44jqLShg2WASW/UGjOC1EzclbsUfoOxgpfP2ny8FaxXtVjBcUgqmbi6gZNjXgpNMHmAs2vafInXvMF6MV8578Ypgrnkind582g4gw5Lnl/tejB6bKmHsaO/lDBsdUONgGWT6nY89WC3BZnrZs6lKgSu4W4IDw8oOnhXWXv+67rKV01I5D4gHAMXIsJzNV4dpNxczwO6WXvzNtBJAce4dN95L4VJpkgFmNc4ejog5D3j5Zi+lhzWwteHy2vqZgDBiYV7hdCaxiwJD9c78HBP7koycFMj1zEtpnwkhkjBlnxz6AfNNngUB9TYwAAAAAElFTkSuQmCC"
            alt="Withdraw Icon"
            style={{ width: "40px" }}
          />
          <Box style={{ lineHeight: 1 }}>
            <Typography variant="subtitle1">Withdraw</Typography>
            <Typography style={{ fontSize: "12px" }}>
              My Withdraw history
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Box
        sx={{
          minHeight: 60,
          width: "90%",
          margin: "0 20px",
          position: "relative",
          top: "-3rem",
          borderRadius: "5px",
          backgroundColor: "#fff",
        }}
      >
        {/* Gifts Section */}
        <Box
          onClick={() => handleNavigation("/redenvelopes")}
          style={{
            display: "flex",
            borderBottom: "1px solid #ddd",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "25px" }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAYFBMVEUAAAD6Wlr4WFj/YWH6WVn7Wlr5WFj5Wlr5WFj/WFj8Wlr5WVn6WVn5WFj4V1f/V1f5WVn5WFj7WVn7WVn+XFz7Xl75WVn/VVX5WFj4WFj7W1v+W1v5WVn7Wlr5Wlr5WVmJKV1iAAAAH3RSTlMAZsIMWT+2fX8RX29NH40m359ROSwmGQatmUYyz17vxXPQ3wAAAcRJREFUaN7t2m1vgjAQwPEe0AkUUB4ERF2//7ecJG6XLdJKrheMu//LhuTnRRFenJIkSXrXYn2FZq88dQVcdRzS3V9hTitnGubyLKAL97THRTmUi7LLRTmYi7LXRZnu+mV0Uaa7fhldlOmuS0Y3sNwDuGR0A8txAi4Z3UfyQICP8Di97GIdAdbgkL3XcMCg/Ze0BLgEh+xxoVSECofscYu1VpaOBxu0Q5SXfrayLKUetzxYpi6ZE0Y3eJHLzS1j2gFfLCHCyKVlLVuEG8tasQgby5pZhFPLWiqwwPf+A7zZfdxY1vTr/VeryDIWbfU8zpXaZOT6Nd+5VFlbjj5TdBdpE4V+rx4Nsq5iWGgXufqAhRKllMACCyywwAILLLDAAgss8NxpdzuoI2zkg80Hjndj5yo8qvhgqH7GNXDP1N8fxTDCcPo1Lg6NLhMM5jTW1d+zqq4rAzywP4EFFlhggQNUPAsPW8EqMDw9DScEhbSJMhEU0tJPGxbuCUtNpAa1zf3UEFZ8mH9b2DkkHK+Ah5wAkfbJOu6B+b/ls1rXkPC4/uKJyfV3JA9d9OtEpAuCmre9ItR3umiSdWLSTO0xU5IkSe/WF7W+N+28iEaIAAAAAElFTkSuQmCC"
              alt="Gifts"
            />
            <Typography>Gifts</Typography>
          </Box>
          <Box>
            <IconButton>
              <ArrowForwardIosIcon
                style={{ color: "var(--Lottery4dTextColor-1)" }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          onClick={() => handleNavigation("/redenvelopes")}
          style={{
            display: "flex",
            borderBottom: "1px solid #ddd",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              width={25}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
              alt=""
            />
            <Typography>Language</Typography>
          </Box>
          <Box>
            <IconButton>
              <ArrowForwardIosIcon
                style={{ color: "var(--Lottery4dTextColor-1)" }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          minHeight: 180,
          width: "90%",
          backgroundColor: "#fff",
          margin: " 20px",
          position: "relative",
          top: "-3rem",
          borderRadius: "5px",
        }}
      >
        {/* <Box className="serviceCenter-wrap"> */}
        <Box>
          <Typography variant="body2" m={1} mb={3}>
            {" "}
            Service Center
          </Typography>
          <Box
            style={{
              marginLeft: "25px",
              marginRight: "25px",
              display: "flex",
              flexWrap: "wrap",
              gap: 5,
              justifyContent: "space-between",
            }}
          >
            {/* Settings */}
            <Box
              onClick={() => handleNavigation("/myprofile")}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "column",
                minWidth: "80px",
              }}
            >
              <img
                data-v-159bf81f=""
                style={{ width: "25px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAY1BMVEUAAAD6Wlr/W1v/UVH6Wlr7W1v6Wlr6WVn8W1v/XV38Xl77W1v6Wlr/Xl78WVn6WVn6Wlr6WVn5V1f7W1v5WFj5WFj7Wlr5WVn/XFz5WVn7W1v5WFj5WVn5WVn8Wlr4WFj5WVmWy2Q/AAAAIHRSTlMAZgwGTFkzU18sJj8ZEzjsqIwmRsGagnAy4nn1snlS48+T4koAAAKxSURBVGje7drbjpswEAbgGR8AcwwhISHpbv3+T9nVqpKbBGx2xogS8d0a6ZcPcQZj2O12u907kaKShHauXOGX9Eho5yhT/EsLGCFce8xoodDJqpEHNDqdgDjkFR9o+fKExgdFlGiT4ZManiT4RJsYk/vqabAFOpGmWhY4Jn0cbI1OnPGWKY6rvQPt1j9VgVOu7qEapyggynFaKtwvbZIBGo0+3bEsc4U+GZBUyFYChUES/lgnyFZsK9ggWwIU5VqLCzJkyiSsM8k10Ehil/mbdcLtMJVEFgFkChkU0NW8kabLkcGsFXwEOsPbL+m6lRaXQBZOsceiltm4Lre27S/oc+W+qb1qT439NpzOvj6LH6caX+zhbv/ReKOPFcwiTae1Rp9+sE+aD/TJdNodRfDVMORgRxzQIUy4VBj0y476hUGphCkp/rS/zoGRnGDQh53UY1BN36caO2nAMEn9KzpbJ94CU6QOO3cM+iSW0Wfr1WIQjMGgk/U6LRY8WK9mqeCLDbgsFNzbgNtCwa0NaN8t+GYD+ndbXNhYr99b20Ay7uo6E4MVBt2ZG9cntQ44MDtck488BlaHUZBLn96zXzIKTbVwsff/lbcAiV6qoM8S8BJ5l+oMfW4vG9jQ+zO17oyEWSrvof/5Ifru664ylYz5mno+DfZbc2p9seUSL+Z9294uy5x0dcjSAZXI8Nm7n2XyDti2eKS42iFqggw1bG9xCWSRjClmuQKVRpZM0pfWOl3WyJTJbX3UNMiWbOvD9Za/mJttXceAFH2UKcu8QB+9xJWbckYteoz/51TMWYNp/GtVxZxvvVpEv0im5wyMksCRa3wlwlWwLsGJFV0Et5osgQhEEZo7qfFBLcHhRs+/EKoqcOKNt64AfMm6hLjyFL+oRAbaIT5ZVTLYvtvtdrs38gceAYlmarNG9wAAAABJRU5ErkJggg=="
              />
              <span style={{ fontSize: "12px" }}>Settings</span>
            </Box>

            {/* Feedback */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "column",
                minWidth: "80px",
              }}
            >
              <img
                data-v-159bf81f=""
                style={{ width: "25px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAYFBMVEUAAAD6Wlr5WFj7Wlr5WVn6WVn7W1v4WFj/VFT5WVn5WVn5WVn6WVn6WVn7W1v7WVn7W1v7WVn6Wlr/XFz5WFj7W1v/XV39XFz6WVn/Zmb5WFj5WVn5WVn6Wlr5WVn5WVnIvMgnAAAAH3RSTlMAZp9M2u0/vg2zcBnFjHlfWTkzMpZGLCYfCvVTz6h5gltZtQAAAP1JREFUSMft1d2OgjAQhuFOa1so8iciuO4u93+XcqBIMmP9Ih6o4T1/MikzCWrtFTlvh1nW97gtB1aJ2t5ybBN8MC8AMGvayks4PZldEqeGxrYS3tKYifAdURSPNQ8seQl7YlqypCW8p0sHyR7pWpBWRVPSux1NFZbZgqaMPPim9Wbebxpo1pHhjuD4q1scO4YNjtsl2Kz4K3CuWf8o9gPvJ4DYDkIaxKk0uQAx7TUrfMCqPhTPLywXMH5hfxzjF7bhGL+wlFn8wvI3X5Vb8rs54LhjOMFxolgGfzIvwwcL1ZhtlJiDP/WTs2t1t66K0ypTsXp3IrmyqjO1tqgzPZ0xAYt3JnkAAAAASUVORK5CYII="
              />
              <span style={{ fontSize: "12px" }}>Feedback</span>
            </Box>

            {/* Notification */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "column",
                minWidth: "80px",
              }}
            >
              <img
                data-v-159bf81f=""
                style={{ width: "25px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAYFBMVEUAAAD6Wlr5WFj7Wlr5WVn6WVn7W1v4WFj/VFT5WVn5WVn5WVn6WVn6WVn7W1v7WVn7W1v7WVn6Wlr/XFz5WFj7W1v/XV39XFz6WVn/Zmb5WFj5WVn5WVn6Wlr5WVn5WVnIvMgnAAAAH3RSTlMAZp9M2u0/vg2zcBnFjHlfWTkzMpZGLCYfCvVTz6h5gltZtQAAAP1JREFUSMft1d2OgjAQhuFOa1so8iciuO4u93+XcqBIMmP9Ih6o4T1/MikzCWrtFTlvh1nW97gtB1aJ2t5ybBN8MC8AMGvayks4PZldEqeGxrYS3tKYifAdURSPNQ8seQl7YlqypCW8p0sHyR7pWpBWRVPSux1NFZbZgqaMPPim9Wbebxpo1pHhjuD4q1scO4YNjtsl2Kz4K3CuWf8o9gPvJ4DYDkIaxKk0uQAx7TUrfMCqPhTPLywXMH5hfxzjF7bhGL+wlFn8wvI3X5Vb8rs54LhjOMFxolgGfzIvwwcL1ZhtlJiDP/WTs2t1t66K0ypTsXp3IrmyqjO1tqgzPZ0xAYt3JnkAAAAASUVORK5CYII="
              />
              <span style={{ fontSize: "12px" }}>Notification</span>
            </Box>
            {/* </Box>
          <Box
            style={{
              marginLeft: "9px",
              marginRight: "32px",
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
            }}
          > */}
            {/* Customer Service */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "column",
                minWidth: "80px",
              }}
              onClick={() => handleNavigation("/keFuMenu")}
            >
              <img
                data-v-159bf81f=""
                style={{ width: "25px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAP1BMVEUAAAD6Wlr6Wlr/VFT7Wlr6WVn4WFj5WVn6WFj6W1v5WVn6WVn5Wlr8WFj5XV3/UlL9XFz6WFj5WVn4WFj5WVkVSZV8AAAAFHRSTlMAZhkOS0Ag379Yf5/vOCYGLo+vb8qn634AAAEoSURBVEjH7dRbDoMgEEBRhqegqG3d/1qrTclIBnm1n95P8ThGguzuf6nBmAE66fbJqC4aGnUT1SPSRi7chA55M8WMq7CvQCmHAlWGGMKLn5hW2DeBNNMoknjcqnomB2+VpUbrWqxTk6f2yZirsw5FpCtmT6/a80B7CIJq+aBZPj1cUnVBFi99eCMwWcrDrSFhYU9dcwOBStizZ31cyfDJCaSf5AnDN+TapWkoxpSP8e4IpASn+GN4Ov2lCqCMkWNIyxg50iym+bDoARoxboeCHgzrsTRDH7bHku3EIBhb4AfMuzG/8QWWrVieMG/FMzul2rBnUb4Fq4XFcVmLJUdFOMWUZjnFlFJuCS5TbLYRFoTW8gXPM9Iyx9/QSmiZSyvX8CS707t/9AZGVTXAbo1QkAAAAABJRU5ErkJggg=="
              />
              <span style={{ fontSize: "12px" }}>24/7 Customer</span>
              <span style={{ fontSize: "12px" }}>Service</span>
            </Box>

            {/* Beginner's Guide */}
            <Box
              onClick={() => handleNavigation("/newtutorial")}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "column",
                minWidth: "80px",
              }}
            >
              <img
                data-v-159bf81f=""
                style={{ width: "25px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAb1BMVEUAAAD6Wlr7W1v6WVn6Wlr7Wlr/Vlb5WVn6WVn6WFj3WFj6Wlr8XFz4WVn6WVn5WVn/Xl74WVn6WVn5WFj4V1f1WFj/TEz5WVn5WVn7WVn/XFz6WVn6WVn5WVn7Wlr4V1f4WFj7Wlr7W1v/Wlr5WVm+biE6AAAAJHRSTlMAZnnFP1kO306PIO8mcF+8E+y/fy4aBp9TORn1z8+yr5aCRh+2VEayAAABCElEQVRIx+3Wx3KDMBSF4SuhTjfFJT3R+z9j4qBINgGbq43tGf4lh29g0AZYu0UiM4d+wW2tYmZ8TZFj/LOt5mGbb6U9tj3jPSc+zg5TkunU+qSA0N5B76tJGWogtCPjVBZWtbHjJITIRNzxQtqJ5nDgAoC5p2KwSz1bG42JXfEl/JEMvcfg2i1POR5TP2k8Jq9uSaI+WE5/e7CjIvSvCFz6rUTj3IYoGtd+q/GvTctkqKQPdVQrXvEsLrA4hZDC4jcIdVhs4KQMhws4S7BdsQynL9rA/yqzVxdxutHqC+brDePTWGom4HpV8F42DBbXsROcaob+keUDlo2AiH64lQXE1jFYu5e+AXBomYy+4tfiAAAAAElFTkSuQmCC"
              />
              <span style={{ fontSize: "12px" }}>Beginner's Guide</span>
            </Box>

            {/* About Us */}
            <Box
              onClick={() => handleNavigation("/about")}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "column",
                minWidth: "80px",
              }}
            >
              <img
                data-v-159bf81f=""
                style={{ width: "35px" }}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAP1BMVEUAAAD6Wlr6Wlr/VFT7Wlr6WVn4WFj5WVn6WFj6W1v5WVn6WVn5Wlr8WFj5XV3/UlL9XFz6WFj5WVn4WFj5WVkVSZV8AAAAFHRSTlMAZhkOS0Ag379Yf5/vOCYGLo+vb8qn634AAAEoSURBVEjH7dRbDoMgEEBRhqegqG3d/1qrTclIBnm1n95P8ThGguzuf6nBmAE66fbJqC4aGnUT1SPSRi7chA55M8WMq7CvQCmHAlWGGMKLn5hW2DeBNNMoknjcqnomB2+VpUbrWqxTk6f2yZirsw5FpCtmT6/a80B7CIJq+aBZPj1cUnVBFi99eCMwWcrDrSFhYU9dcwOBStizZ31cyfDJCaSf5AnDN+TapWkoxpSP8e4IpASn+GN4Ov2lCqCMkWNIyxg50iym+bDoARoxboeCHgzrsTRDH7bHku3EIBhb4AfMuzG/8QWWrVieMG/FMzul2rBnUb4Fq4XFcVmLJUdFOMWUZjnFlFJuCS5TbLYRFoTW8gXPM9Iyx9/QSmiZSyvX8CS707t/9AZGVTXAbo1QkAAAAABJRU5ErkJggg=="
              />
              <span style={{ fontSize: "12px" }}>About Us</span>
            </Box>
          </Box>
        </Box>
      </Box>

      <div style={{ margin: "20px", position: "relative", top: "-2rem" }}>
        <Button
          onClick={logOut}
          fullWidth
          variant="outlined"
          style={{
            color: "rgb(142 134 134)",
            borderRadius: "40px",
            borderColor: "rgb(142 134 134)",
          }}
        >
          <img
            width={25}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAQlBMVEUAAABlZWVmZmZlZWVmZmZmZmZmZmZkZGRmZmZlZWVra2tmZmZmZmZnZ2dmZmZgYGBmZmZmZmZlZWViYmJlZWVmZmYJxgEqAAAAFXRSTlMA799/v18fEI9vHz+fL68gT88wL2Dza9ruAAACiklEQVRo3u1YS2KrMAxE8hdMEmjr+1/1vbhpZxEcG4l0xSzB1li/EWY4ceKv4Yjd8E5wzjS8E/k/hgE4CU6CBkZi1yZwxKPI/Oeac6Y2Aeec2QuOn+/gNgGVhWan+WDLNuoJEZeldpcTt+9Nc+hJsp9z3hmmz+I2pZ4qghN02WffTv1l6lcw9NqP+/rA9DPcCFXRSQAG35tfs7+TTWemV9jvJwCDHYD6KiPToojcVZHua65SsSvdmVozN/MkJQhle2gFKMnlOjVk6YYgCudBfF2rMwIkJAh0l7CXDjjdRHP3Ff6VA9qRyYhyxQExAVygUH3FmqGPCTdW22TUEKDUbTVCk54g1Ow4MIsJEIn6cz3BiJM+JefjCAKfNy8RH3gsIWgfdYFOawmum/0UoYNyApiKm7TLMQRfm8GwmARyAqRz3RSpSUuAMuLNrUFLgF4mwVbNKjxslzi/lcAxOSEBIQcKQE8FVQSIqmiFgGiRIKdPAqIFZK0uIHoYmKoIiBIWsvY0D+Rojy46oIxwVMFMBiQzGc/1sJV6DFnfy/i+qjGbY4rUVmNHegKudmwgZTPj63qqfw3wEQ7MVRXUV6pDiitXHAqqEoIDW/CkVbwIBwT3ZEB2T8ZVXRUgbh/BikUCAVL8MWkE2HSdwojtr81lnguD0D53TBRPIgaToRENXEiQh4i/jr0Mq99Rnxb2uxi4xLNbWRfaYx+ZznOXE+Gakd9uhscm0zZvqCy1QVAVJU6+aV6o8p4fFHM1tik+zNuLbHhwfnDE9Hz2FH9ek3hIeUP5BzaO6VLyOE3LGO3vGzKaEeVHzhXAvBLuWrdu03AEwjJv+MHzVxiOg1/MbFcqltnO4+KHEydO9OIfEq1JSVrjUXcAAAAASUVORK5CYII="
          />
          Log out
        </Button>
      </div>

      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};
export default My;
