import { Box, Typography } from "@mui/material";
import FooterNav from "../../FooterNav";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";

const Laundry = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}>
        <Box
          style={{
            height: "8vh",
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
            // background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
            alignItems: "center",
            padding: "0 14px",
            borderBottom: "0.7px solid #ff9a8e",
          }}
        >
          <Box onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                // width: "60px",
                // color: "#fff",
                border: "1px solid #000",
                borderRadius: 50,
              }}
            />
          </Box>
          <Typography
            style={{
              fontSize: "16px",
            }}
          >
            Rebet
          </Typography>
        </Box>
      </div>
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};

export default Laundry;
