import { Box, Button, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";

const DailySignIn = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}>
        <Box
          style={{
            height: "8vh",
            display: "flex",
            justifyContent: "space-between",
            width: "60%",
            // background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
            alignItems: "center",
            padding: "0 14px",
            borderBottom: "0.7px solid #ff9a8e",
          }}
        >
          <Box onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                // width: "60px",
                // color: "#fff",
                border: "1px solid #000",
                borderRadius: 50,
              }}
            />
          </Box>
          <Typography
            style={{
              fontSize: "16px",
            }}
          >
            Attendance
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            // alignItems: "center",
            padding: 2,
            height: "30vh",
            gap: 2,
            flexDirection: "column",
            backgroundSize: "cover", // Make the background cover the button area
            backgroundPosition: "center", // Center the image
            backgroundRepeat: "no-repeat",
            backgroundImage:
              "url(./images/headerBg-c5504bca.png), linear-gradient(94deg, #F54545 2.72%, #F54545 43.54%, #F54545 98.54%)",
          }}
        >
          <Typography
            color="#fff"
            style={{
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Attendance bonus
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              style={{ fontSize: "12px", lineHeight: "15px", width: "60%" }}
              color="#fff"
            >
              Get rewards based on consecutive login days
            </Typography>
            <Typography
              variant="body2"
              style={{ width: "60%", lineHeight: 1 }}
              color="#fff"
            >
              Accumulated
            </Typography>
            <Typography
              variant="body2"
              style={{ width: "60%", lineHeight: 1 }}
              color="#fff"
            >
              0.00
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                color: "#fff",
                borderRadius: "20px",
                background: "linear-gradient(180deg, #FFBD40 0%, #FF7F3D 100%)",
              }}
            >
              Game Rules
            </Button>

            <Button
              size="small"
              variant="contained"
              style={{
                color: "#fff",
                lineHeight: 0,
                borderRadius: "20px",
                background: "linear-gradient(180deg, #FFBD40 0%, #FF7F3D 100%)",
              }}
            >
              Attendance history{" "}
            </Button>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            // width: "100%",
            margin: "8px",
            backgroundColor: "#F7F8FF",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {[
            {
              id: "flash",
              name: "Mini games",
              imgSrc:
                "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010207qmfa.png",
            },
            {
              id: "popular",
              name: "Popular",
              imgSrc:
                "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010159ynyu.png",
            },
            {
              id: "slot",
              name: "Slots",
              imgSrc:
                "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010153317n.png",
            },
            {
              id: "fish",
              name: "Fishing",
              imgSrc:
                "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010147x2cr.png",
            },
            {
              id: "video",
              name: "Casino",
              imgSrc:
                "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010141v76m.png",
            },
            {
              id: "sport",
              name: "Sports",
              imgSrc:
                "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20230714010123ddo4.png",
            },
          ].map((tab) => (
            <div
              key={tab.id}
              role="tab"
              tabIndex="0"
              aria-selected={tab.id}
              // onClick={() => handleTabClick(tab.id)}
              style={{
                width: "85px",
                height: "100%",
                padding: "15px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <Typography style={{ fontSize: "14px", color: "#000" }}>
                100
              </Typography>
              <img width={35} src="./images/coin.png" />
              <Typography style={{ fontSize: "14px", color: "#000" }}>
                1 Day
              </Typography>
            </div>
          ))}
        </Box>
        <div
          style={{
            width: "80%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            margin: "0 20px",
            padding: "0 20px",
            borderRadius: "15px",
            backgroundColor: "#fff",
          }}
        >
          <img width={150} src="./images/day7Bg-ada1d750.png" />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography style={{ fontSize: "16px", color: "#000" }}>
              7,000.00
            </Typography>
            <Typography style={{ fontSize: "16px", color: "#000" }}>
              7 Day
            </Typography>
          </Box>
        </div>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "30px 0",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "80%",
              borderRadius: 30,
              backgroundColor: "#ff9a8e",
              color: "#fff",
            }}
          >
            Attendance
          </Button>
        </Box>
      </div>
      {/* <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box> */}
    </>
  );
};

export default DailySignIn;
