import React from "react";
import { Box, Typography, Avatar, Grid, Paper } from "@mui/material";

const LuckyWinners = () => {
  const winners = [
    {
      id: 1,
      imgSrc: "https://91club.bet/assets/png/1-a6662edb.png",
      winAmount: `₹${Math.floor(Math.random() * 999) + 100}27.00`,
    },
    {
      id: 2,
      imgSrc: "https://91club.bet/assets/png/1-a6662edb.png",
      winAmount: `₹${Math.floor(Math.random() * 999) + 100}27.00`,
    },
    {
      id: 3,
      imgSrc: "https://91club.bet/assets/png/1-a6662edb.png",
      winAmount: `₹${Math.floor(Math.random() * 999) + 10}48.00`,
    },
    {
      id: 4,
      imgSrc: "https://91club.bet/assets/png/1-a6662edb.png",
      winAmount: `₹${Math.floor(Math.random() * 999) + 10}88.00`,
    },
    {
      id: 5,
      imgSrc: "https://91club.bet/assets/png/1-a6662edb.png",
      winAmount: `₹${Math.floor(Math.random() * 999) + 100}19.00`,
    },
    {
      id: 6,
      imgSrc: "https://91club.bet/assets/png/2-58c8a9bc.png",
      winAmount: `₹${Math.floor(Math.random() * 999) + 10}60.00`,
    },
  ];

  return (
    <div style={{ margin: "10px" }}>
      <Typography variant="h4" gutterBottom>
        Winning Information
      </Typography>
      <Box sx={{ position: "relative" }}>
        <Grid container>
          {winners.map((winner, index) => (
            <Paper
              item
              key={winner.id}
              sx={{
                width: "100%",
                height: "55px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                marginBottom: "10px",

                borderRadius: "8px",
                // gap:'10px'
              }}
            >
              <Avatar
                src={winner.imgSrc}
                alt="Winner Avatar"
                sx={{ width: 30, height: 30 }}
              />
              <Typography variant="subtitle1">
                Mem***{Math.floor(Math.random() * 999)}
              </Typography>
              <Box>
                <img
                  src="https://91club.bet/assets/png/WinGo-35d04760.png"
                  style={{ width: "67px", height: "100%" }}
                  alt="Win Type"
                />
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Receive {winner.winAmount}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Winning amount
                </Typography>
              </Box>
            </Paper>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default LuckyWinners;
