import { Box, Button, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import FooterNav from "../../FooterNav";
import Cookies from "js-cookie";
import { unsetCookie } from "../../Login";
import axiosInstance from "../../../AxiosBaseApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const InvitationBonus = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    user: {},
    totalRecharge: 0,
    totalWithdraw: 0,
  });
  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleOpen();
  }, []);

  useEffect(() => {
    // Simulating a data fetch
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const getUserData = async () => {
    try {
      const response = await axiosInstance
        .get("api/webapi/GetUserInfo")

        .then((res) => {
          if (res.status === 200) {
            setUserInfo({
              user: res.data.data,
              totalRecharge: res.data.totalRecharge,
              totalWithdraw: res.data.totalWithdraw,
            });
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    const token = Cookies.get("auth");
    if (!token) {
      setLoginStatus(false);
      navigate("/login");
    } else {
      setLoginStatus(true);
    }
  }, [loginStatus]);

  const logOut = () => {
    unsetCookie();
    setLoginStatus(false);
  };
  return (
    <>
      <Box
        style={{
          height: "8vh",
          display: "flex",
          justifyContent: "space-between",
          width: "60%",
          // background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
          alignItems: "center",
          padding: "0 14px",
          borderBottom: "0.7px solid #ff9a8e",
        }}
      >
        <Box onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
          <ChevronLeftIcon
            fontSize="medium"
            style={{
              // width: "60px",
              // color: "#fff",
              border: "1px solid #000",
              borderRadius: 50,
            }}
          />
        </Box>
        <Typography
          style={{
            fontSize: "16px",
          }}
        >
          Invitation Bonus
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          // alignItems: "center",
          padding: 2,
          height: "25vh",
          gap: 2,
          flexDirection: "column",
          backgroundSize: "cover", // Make the background cover the button area
          backgroundPosition: "center", // Center the image
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(./images/invitation.png), linear-gradient(94deg, #f99937 2.72%, #ff6922 43.54%, #ff8039 98.54%)",
        }}
      >
        <Typography
          color="#fff"
          style={{
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          Invite friends and deposit
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            style={{ fontSize: "12px", lineHeight: "10px" }}
            color="#fff"
          >
            Both parties can receive rewards
          </Typography>
          <Typography style={{ fontSize: "12px", lineHeight: 1 }} color="#fff">
            Invite friends to register and recharge to receive rewards
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 1,
            gap: 1,
          }}
        >
          <Typography
            style={{ fontSize: "16px", lineHeight: "10px" }}
            color="#fff"
          >
            Activity date
          </Typography>
          <Typography style={{ fontSize: "12px", lineHeight: 1 }} color="#fff">
            2000-01-01 -2099-01-01
          </Typography>
        </Box>
      </Box>

      <Box
        // elevation={1}
        sx={{
          position: "relative",
          top: "-2.18667rem",
          display: "flex",
          flexWrap: "wrap",
          minHeight: 60,
          margin: 1.5,
          marginBottom: 0,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#fff",
            width: "100%",
            borderRadius: "10px",
            display: "flex",
            // paddingTop: "15px",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              flexDirection: "column",
            }}
          >
            <img
              data-v-7d799898=""
              width={50}
              src="./images/inviterule-c8d78199.svg"
              alt=""
            />
            <Typography sx={{ fontSize: "14px" }}>
              Invitation reward rule
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              flexDirection: "column",
            }}
          >
            <img
              data-v-7d799898=""
              width={50}
              src="./images/inviterecord-610ce16f.svg"
              alt=""
            />
            <Typography sx={{ fontSize: "14px" }}>Invitation record</Typography>
          </Box>
        </Box>
      </Box>

      <Box style={{ backgroundColor: "#f7f7f7", padding: 13 }}>
        {[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }].map(
          (data, ind) => {
            return (
              <>
                <Box
                  key={ind}
                  style={{
                    // padding: 15,
                    backgroundColor: "#fff",
                    borderRadius: ".5rem",
                    marginBottom: "3px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #f2f3f5",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "green",
                        paddingLeft: 10,
                        width: "30%",
                        borderRadius: "0.21333rem 0px 1rem",
                      }}
                    >
                      <Typography variant="subtitle2" color="#fff">
                        Bonus
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1" color="orange" pr={1}>
                      250.00
                    </Typography>
                  </Box>

                  <Box style={{ margin: 15, marginTop: 15 }}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#F6F6F6",
                      }}
                    >
                      <Typography variant="subtitle1" color="#888">
                        Number of invites
                      </Typography>{" "}
                      <Typography variant="body2" color="#888" pr={10}>
                        1
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        marginTop: "2px",
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#F6F6F6",
                      }}
                    >
                      <Typography variant="subtitle1" color="#888">
                        Recharge per people
                      </Typography>{" "}
                      <Typography variant="body2" color="orange" pr={10}>
                        300
                      </Typography>
                    </Box>
                    <Divider
                      sx={{
                        marginY: 2,
                        borderStyle: "dashed",
                        borderColor: "grey.400", // Adjust the color if needed
                      }}
                    />
                    <Box display="flex" justifyContent="space-between">
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2" color="orange">
                          0/1
                        </Typography>{" "}
                        <Typography variant="subtitle1" color="#888">
                          Number Of invitees
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2" color="orange">
                          0/1
                        </Typography>{" "}
                        <Typography variant="subtitle1" color="#888">
                          Deposit number
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        marginTop: "25px",
                        marginBottom: "10px",
                        backgroundColor: "#CDCFDD",
                        borderRadius: "30px",
                      }}
                    >
                      Unfinished
                    </Button>
                  </Box>
                </Box>
              </>
            );
          }
        )}
      </Box>

      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};

export default InvitationBonus;
