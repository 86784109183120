import { Box, Typography } from "@mui/material";
import FooterNav from "../FooterNav";
import { useNavigate } from "react-router-dom";
import React from "react";
import Recordlist from "./Component/Recordlist";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Withdrawalrecord = () => {
  let navigate = useNavigate();
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#f2f2f1" }}>
        <Box
          sx={{
            display: "flex",
            padding: 1,
            justifyContent: "space-between",
            alignItems: "center",
            backgroundImage: "linear-gradient(90deg, #cd0103, #f64841)",
          }}
        >
          <Box onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
            <ChevronLeftIcon
              fontSize="medium"
              style={{
                // width: "60px",
                color: "#fff",
                border: "1px solid #f2f3f5",
                borderRadius: 50,
              }}
            />
          </Box>
          <Typography variant="subtitle2" color="#fff">
            Withdrawal Record
          </Typography>
          <Box> </Box>
        </Box>
        <Recordlist
          path="api/webapi/withdraw/list"
          text="Withdrawal"
          bgcolor="#4781ff"
        />
        <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box>
      </div>
    </>
  );
};

export default Withdrawalrecord;
