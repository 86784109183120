import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import FooterNav from "../FooterNav";
import Models from "../Wallet/Component/Models";

var items = [
  {
    url: "https://ossimg.91admin123admin.com/91club/banner/Banner_202403271526439mnj.jpg",
    text: "91CLUB Partner Rewards",
  },
  {
    url: "https://ossimg.91admin123admin.com/91club/banner/Banner_20240517135632un43.jpg",
    text: "91CLUB Safe",
  },
  {
    url: "https://ossimg.91admin123admin.com/91club/banner/Banner_20240315141319qi8s.png",
    text: "91CLUB Daily Lucky Spin",
  },
];

const useStyles = makeStyles({
  formContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  phoneInputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputField: {
    marginBottom: "20px",
  },
  icon: {
    marginRight: "8px",
  },
  actionButtons: {
    display: "flex",
    gap: "10px",
  },
  footer: {
    marginTop: "20px",
    textAlign: "center",
  },
});

const Activity = () => {
  const history = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNavigation = (path) => {
    history(path);
  };

  useEffect(() => {
    handleOpen();
  }, []);
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}>
        <Models handleClose={handleClose} open={open} />

        <Box
          style={{
            height: "8vh",
            display: "flex",
            justifyContent: "center",
            background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
            alignItems: "center",
            padding: "0 14px",
            borderBottom: "0.7px solid #ff9a8e",
          }}
        >
          <img
            width={100}
            src="https://ossimg.91admin123admin.com/91club/other/h5setting_20230714005938hfia.png"
            alt=""
          ></img>
        </Box>

        <Box
          sx={{
            display: "flex",
            padding: 2,
            gap: 1,
            height: "10vh",
            flexDirection: "column",
            background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)",
          }}
        >
          <Typography
            color="#fff"
            style={{
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Activity
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: "12px", lineHeight: "10px" }}
              color="#fff"
            >
              Please remember to follow the event page
            </Typography>
            <Typography style={{ fontSize: "12px" }} color="#fff">
              We will launch user feedback activities from time to time
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            // minHeight: 160,
            //   margin:.5,
            borderRadius: "10px",
          }}
        >
          <Grid
            container
            fullWidth
            sx={{
              display: "flex",
              paddingTop: "15px",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <Grid
              item
              xs={3}
              onClick={() => handleNavigation("/activity/DailyTasks")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <img
                  data-v-7d799898=""
                  width={45}
                  src="./images/activity/activityReward.png"
                  alt=""
                />
                <Typography style={{ fontSize: "12px", color: "#768096" }}>
                  Activity Award
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleNavigation("/activity/InvitationBonus")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <img
                  data-v-7d799898=""
                  width={45}
                  src="./images/activity/invitationBonus.png"
                  alt=""
                />
                <Typography style={{ fontSize: "12px", color: "#768096" }}>
                  Invitation Bonus
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3} onClick={() => handleNavigation("/main/Laundry")}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <img
                  data-v-7d799898=""
                  width={45}
                  src="./images/activity/BettingRebate.png"
                  alt=""
                />
                <Typography style={{ fontSize: "12px", color: "#768096" }}>
                  Betting Rebate
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleNavigation("/activity/SuperJackpot")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <img
                  data-v-7d799898=""
                  width={45}
                  src="./images/activity/superJackpot.png"
                  alt=""
                />
                <Typography style={{ fontSize: "12px", color: "#768096" }}>
                  Super Jackpot
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ gap: 1, display: "flex" }} m={1}>
          {/* First Content Block */}
          <Box
            style={{ backgroundColor: "#fff", width: "50%" }}
            onClick={() => handleNavigation("/redenvelopes")}
          >
            <Box>
              <img
                src="/images/activity/signInBanner.png"
                alt="Sign In Banner"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
            <Box p={1}>
              <Typography variant="h6">Gifts</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                Enter the redemption code to receive gift rewards
              </Typography>
            </Box>
          </Box>

          {/* Second Content Block */}
          <Box
            style={{ backgroundColor: "#fff", width: "50%" }}
            onClick={() => handleNavigation("/activity/DailySignIn")}
          >
            <Box item>
              <img
                src="/images/activity/giftRedeem.png"
                alt="Gift Redeem"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
            <Box p={1}>
              <Typography variant="h6" className="content-para">
                Attendance bonus
              </Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                The more consecutive days you sign in, the higher the reward
                will be.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid>
          {items.map((item, ind) => {
            return (
              <>
                <Grid
                  key={ind}
                  container
                  direction="column"
                  m={1}
                  // alignItems="center"
                  style={{
                    marginTop: "5px",
                    background: "#fff",
                    width: "95%",
                    borderRadius: "15px 15px 0 0",
                  }}
                >
                  <img
                    width="100%"
                    // height='165px'
                    style={{ borderRadius: "15px 15px 0 0", height: "125px" }}
                    src={item.url}
                  />

                  <Typography pl={1} pt={0.5}>
                    {item.text}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </Grid>
      </div>
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};

export default Activity;
