import { Modal } from "@mui/material";
import { Box, Typography } from "@mui/material";

const ResultModel = ({ handleClose, open, period, myBetData }) => {
  return (
    <>
      <div>
        <Modal
          // aria-labelledby="unstyled-modal-title"
          // aria-describedby="unstyled-modal-description"
          open={open.open}
          onClose={handleClose}
          // slots={{ backdrop: StyledBackdrop }}
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 320,
              backgroundImage: "url(/images/missningBg-9deda6ac.png)",
              backgroundSize: "cover", // Ensures the image covers the entire div
              backgroundPosition: "center", // Centers the image
              backgroundRepeat: "no-repeat", // Prevents repeating
              height: "70%", // Full viewport height
              width: "20rem",
            }}
          >
            <Box
              style={{
                display: "flex",
                height: "80%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap:20
              }}
            >
              {myBetData > 1 && myBetData[0].status == 1 ? (
                <Typography
                  style={{ fontSize: "34px", color: "#fff", fontWeight: 700 }}
                >
                  {" "}
                  Win{" "}
                </Typography>
              ) : (
                <Typography
                  style={{ fontSize: "34px", color: "#fff", fontWeight: 700 }}
                >
                  {" "}
                  Try Again{" "}
                </Typography>
              )}
               <Typography
                  style={{ fontSize: "16px", color: "#fff",  }}
                >
                  Result:{myBetData.length > 1 && myBetData[0].result}
                </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                marginTop: "-90px",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ fontSize: "34px", color: "#fff", fontWeight: 700 }}
              >
                Loss:{myBetData.length > 1 && myBetData[0].money}
              </Typography>
              <Typography
                style={{ fontSize: "16px", color: "#000", fontWeight: 700 }}
              >
                Period:{period - 1}
              </Typography>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ResultModel;
