import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Drawer } from "@mui/material";
import "../../../style.css";
import axiosInstance from "../../../AxiosBaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainBoxK3 = ({ seconds1, seconds2, typeid }) => {
  const [open, setOpen] = useState(false);
  const [drawerBackground, setDrawerBackground] = useState("#bdbdbd"); // Default background color
  const [text, setText] = useState("");
  const [activeButton, setActiveButton] = useState(0);
  const [buttonValue, setButtonValue] = useState(10);
  const [activeButton2, setActiveButton2] = useState(0);
  const [buttonValue2, setButtonValue2] = useState(1);
  const [activeButton33, setActiveButton33] = useState(0);
  const [buttonValue33, setButtonValue33] = useState(1);
  const buttons33 = [
    { label: "3", value: 3, odds: 207.36 },
    { label: "4", value: 4, odds: 69.12 },
    { label: "5", value: 5, odds: 34.56 },
    { label: "6", value: 6, odds: 20.74 },
    { label: "7", value: 7, odds: 13.83 },
    { label: "8", value: 8, odds: 9.88 },
    { label: "9", value: 9, odds: 8.3 },
    { label: "10", value: 10, odds: 7.68 },
    { label: "11", value: 11, odds: 7.68 },
    { label: "12", value: 12, odds: 8.3 },
    { label: "13", value: 13, odds: 9.88 },
    { label: "14", value: 14, odds: 13.83 },
    { label: "15", value: 15, odds: 20.74 },
    { label: "16", value: 16, odds: 34.56 },
    { label: "17", value: 17, odds: 69.12 },
    { label: "18", value: 18, odds: 207.36 },

    { label: "Big", value: "Big", odds: 1.92 },
    { label: "Small", value: "Small", odds: 1.92 },
    { label: "Odd", value: "Odd", odds: 1.92 },
    { label: "Even", value: "Even", odds: 1.92 },
  ];

  const handleButtonClick33 = (index, value) => {
    setActiveButton2(index);
    setButtonValue2(value);
  };

  const buttons2 = [
    { label: "X1", value: 1 },
    { label: "X5", value: 5 },
    { label: "X10", value: 10 },
    { label: "X20", value: 20 },
    { label: "X50", value: 50 },
    { label: "X100", value: 100 },
  ];

  const handleButtonClick22 = (index, value) => {
    setActiveButton2(index);
    setButtonValue2(value);
  };

  const buttons = [
    { label: "10", value: 10 },
    { label: "100", value: 100 },
    { label: "1000", value: 1000 },
    { label: "10000", value: 10000 },
  ];

  const handleButtonClick2 = (index, value) => {
    setActiveButton(index);
    setButtonValue(value);
  };

  const toggleDrawer = (state) => (event) => {
    setOpen(state);
  };

  const getUserData = async (token) => {
    try {
      const response = await axiosInstance
        .post("api/webapi/action/join", {
          typeid: typeid,
          join: text,
          x: buttonValue2,
          money: buttonValue,
        })

        .then((res) => {
          if (res.status === 200) {
            toast(res.data.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: 0,
              theme: "colored",
              // transition: Bounce,
            });
            setTimeout(() => {
              setOpen(false);
              setButtonValue(10);
              setActiveButton(0);
            }, 2000);
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleButtonClick = (color, text) => {
    setDrawerBackground(color);
    setText(text);
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <div style={{ width: "100%", backgroundColor: "#F7F8FF" }}> */}

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          top: "-4rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Drawer
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                height: "57%", // Set height to 50%
                width: { xs: "100%", sm: "400px", md: "400px" },
                left: { sm: "-9px", md: "-14px" },
                margin: "0 auto", // Center horizontally
                borderRadius: "25px 25px 0 0", // Optional: add some border radius to make it look better
              },
            }}
          >
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: ".8rem",
                  background: drawerBackground,
                }}
              >
                <Typography variant="body1" style={{ color: "#fff" }}>
                  1 Minute
                </Typography>
                <Box
                  style={{
                    background: "#fff",
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px ",
                    borderRadius: "10px",
                  }}
                >
                  {" "}
                  <Typography>Choose {text}</Typography>{" "}
                </Box>
                <Box
                  style={{
                    minHeight: "6vh",
                    display: "flex",
                    minWidth: "100%",
                  }}
                >
                  <Box
                    style={{
                      minHeight: "6vh",
                      minWidth: "50%",
                      background:
                        "linear-gradient(10deg, #fff 50%, transparent 0)",
                    }}
                  ></Box>
                  <Box
                    style={{
                      minHeight: "6vh",
                      minWidth: "50%",
                      background:
                        "linear-gradient(-10deg, #fff 50%, transparent 0)",
                    }}
                  ></Box>
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  margin: "10px",
                  justifyContent: "space-between",
                }}
              >
                <Typography align="center">Amount</Typography>

                <Box sx={{ display: "flex", gap: 1 }}>
                  {buttons.map((button, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      size="small"
                      onClick={() => handleButtonClick2(index, button.value)}
                      sx={{
                        padding: "1px 9px",
                        minWidth: 0,
                        borderRadius: "0px",
                        fontWeight: 700,
                        color: "#373535",
                        backgroundColor:
                          activeButton === index
                            ? drawerBackground
                            : "rgb(240, 240, 240)",
                        "&:hover": {
                          backgroundColor:
                            activeButton === index
                              ? drawerBackground
                              : "rgb(240, 240, 240)",
                        },
                      }}
                    >
                      {button.label}
                    </Button>
                  ))}
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  margin: "10px",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  display="flex"
                  align="center"
                  justifyContent="center"
                >
                  Quantity
                </Typography>
                <Box style={{ display: "flex", gap: 8 }}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "rgb(240, 240, 240)",
                      padding: "1px 12px",
                      minWidth: 0,
                      borderRadius: "0px",
                    }}
                  >
                    -
                  </Button>
                  <input type="number" style={{ width: "40px" }} />
                  <Button
                    variant="contained"
                    style={{
                      background: "rgb(240, 240, 240)",
                      padding: "1px 12px",
                      minWidth: 0,
                      borderRadius: "0px",
                    }}
                  >
                    +
                  </Button>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
                {buttons2.map((button, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    size="small"
                    onClick={() => handleButtonClick22(index, button.value)}
                    sx={{
                      padding: "1px 10px",
                      minWidth: 0,
                      borderRadius: "0px",
                      fontWeight: 600,
                      color: "#373535",
                      backgroundColor:
                        activeButton2 === index
                          ? drawerBackground
                          : "rgb(240, 240, 240)",
                      "&:hover": {
                        backgroundColor:
                          activeButton2 === index
                            ? drawerBackground
                            : "rgb(240, 240, 240)",
                      },
                    }}
                  >
                    {button.label}
                  </Button>
                ))}
              </Box>

              <Box
                style={{
                  display: "flex",
                  position: "absolute", // Position absolutely
                  bottom: 0, // Align at the bottom
                  left: 0, // Align to the left
                  right: 0, // Align to the right
                  padding: "0 10px", // Optional: Add padding to the container
                  backgroundColor: "#fff", // Optional: Set a background color
                  zIndex: 1, // Optional: Adjust z-index to keep it above other elements
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  type="button"
                  onClick={handleCancel}
                  style={{
                    background: "#25253c",
                    color: "#fff",
                    width: "30%",
                    height: "50px",
                    padding: "0",
                    fontSize: "12px",
                    minWidth: "0",
                    // overflow: "hidden",
                    borderRadius: 0,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={getUserData}
                  style={{
                    background: drawerBackground,
                    color: "#fff",
                    width: "70%",
                    height: "50px",
                    padding: "0",
                    fontSize: "12px",
                    minWidth: "0",
                    // overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Total Amount {buttonValue2 * buttonValue}
                </Button>
                <ToastContainer />
              </Box>
            </Box>
          </Drawer>
        </div>

        <Grid
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
          justifyContent="center"
        >
          <Grid
            style={{
              width: "95%",
              background: "#fff",
              gap: 10,
              borderRadius: 15,
              minHeight: "290px",
              display: "flex",
              padding: 15,
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="subtitle1">Lottery Period </Typography>
                <Typography variant="body1" fontWeight={600}>
                  987789978987{" "}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  // gap: 3,
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="subtitle1" style={{ color: "#000" }}>
                  Time remaning
                </Typography>
                <Box style={{ display: "flex", gap: 4 }}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    0
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    2
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    :
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    1
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      background: "#eee",
                      color: "#00b977",
                      fontWeight: 800,
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "4px",
                    }}
                  >
                    3
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                minHeight: "100px",
                border: "13px solid #00b977",
                borderRadius: "10px",
              }}
            >
              <Box
                style={{
                  minHeight: "100px",
                  width: "100%",
                  borderRadius: "2px",
                  background: "linear-gradient(180deg, #003b26, #005226)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    width: "90px",
                    height: "90px",
                    borderRadius: ".5rem",
                    overflow: "hidden",
                    background: "#333",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="./images/k3/bg2.png" width="60px" alt="" />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    width: "90px",
                    height: "90px",
                    borderRadius: ".5rem",
                    overflow: "hidden",
                    background: "#333",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="./images/k3/bg6.png" width="60px" alt="" />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    width: "90px",
                    height: "90px",
                    borderRadius: ".5rem",
                    overflow: "hidden",
                    background: "#333",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="./images/k3/bg3.png" width="60px" alt="" />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {buttons33.map((button, index) => (
                <Button
                  key={index}
                  variant="contained"
                  size="small"
                  onClick={() => handleButtonClick2(index, button.value)}
                  sx={{
                    width: "80px",
                    minWidth: "35px",
                    textTransform: "capitalize",
                    height: "54px",
                    color: "#000",
                    padding: "2px",
                    background: "#eee",
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: 1,
                    cursor: "pointer",
                    fontSize: "14px",
                    backgroundColor:
                      activeButton === index ? drawerBackground : "#eee",
                    "&:hover": {
                      backgroundColor:
                        activeButton === index
                          ? drawerBackground
                          : "rgb(240, 240, 240)",
                    },
                  }}
                >
                  {button.label}
                  <Typography fontSize="10px">Odds{button.odds}</Typography>
                </Button>
              ))}
            </Box>

            <Box sx={{ display: "flex", gap: 0.5 }}>
              <Button
                variant="contained"
                size="small"
                // onClick={() => handleButtonClick2(index, button.value)}
                sx={{
                  width: "90px",
                  minWidth: "35px",
                  textTransform: "capitalize",
                  height: "34px",
                  color: "#000",
                  padding: "2px",
                  background: "#eee",
                  cursor: "pointer",
                  fontSize: "14px",
                  backgroundColor: "#eee",
                }}
              >
                Sum
              </Button>
              <Button
                variant="contained"
                size="small"
                // onClick={() => handleButtonClick2(index, button.value)}
                sx={{
                  width: "90px",
                  minWidth: "35px",
                  textTransform: "capitalize",
                  height: "34px",
                  color: "#000",
                  padding: "2px",
                  background: "#eee",
                  cursor: "pointer",
                  fontSize: "14px",
                  backgroundColor: "#eee",
                }}
              >
                2 Matching
              </Button>
              <Button
                variant="contained"
                size="small"
                // onClick={() => handleButtonClick2(index, button.value)}
                sx={{
                  width: "90px",
                  minWidth: "35px",
                  textTransform: "capitalize",
                  height: "34px",
                  color: "#000",
                  padding: "2px",
                  background: "#eee",
                  cursor: "pointer",
                  fontSize: "14px",
                  backgroundColor: "#eee",
                }}
              >
                3 Matching
              </Button>
              <Button
                variant="contained"
                size="small"
                // onClick={() => handleButtonClick2(index, button.value)}
                sx={{
                  width: "90px",
                  minWidth: "35px",
                  textTransform: "capitalize",
                  height: "34px",
                  color: "#000",
                  padding: "2px",
                  background: "#eee",
                  cursor: "pointer",
                  fontSize: "14px",
                  backgroundColor: "#eee",
                }}
              >
                Different
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* </div> */}
    </>
  );
};
export default MainBoxK3;
