import { Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";

var items = [
  {
    url: "https://ossimg.91admin123admin.com/91club/banner/Banner_202403271526439mnj.jpg",
  },
  {
    url: "https://ossimg.91admin123admin.com/91club/banner/Banner_20240517135632un43.jpg",
  },
  {
    url: "https://ossimg.91admin123admin.com/91club/banner/Banner_20240315141319qi8s.png",
  },
];
const SliderComponent = () => {
  return (
    <Carousel autoPlay indicators={false}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

export default SliderComponent;

function Item(props) {
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxHeight: { xs: 190, sm: 250, md: 270, lg: 340 } }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          style={{
            width: "95%",
            marginTop: "5px",
            height: { xs: 190, sm: 260, md: 290, lg: 353 },
          }}
        >
          <img
            width="100%"
            height="165px"
            style={{ borderRadius: "10px" }}
            src={props.item.url}
            alt="slider"
          />
        </Grid>
      </Grid>
    </>
  );
}
